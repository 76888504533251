import React, {useEffect, useState, useContext} from "react";

import {DocumentPreviewModel, DocumentPreviewSize} from "@renta-apps/athenaeum-react-common";
import {Box, Button, ButtonSize, ButtonType, Icon, LoadingDecorator, Text, SimpleTable, SimpleTableRowData} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {getFileModel} from "@/services/FileService";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";

import styles from "../DeviceDetails.module.scss";
import LogDocumentEvent from "@/models/server/LogDocumentEvent";
import {logDocumentEvent} from "@/services/ConstructionSiteService";
import {DocumentPreviewModal} from "@renta-apps/athenaeum-react-components";
import FileInfoModel from "@/models/server/FileInfoModel";
import {getDeviceDocumentsAsync} from "@/services/FleetService";

interface IDeviceDetailsDocumentsProps {
}

const DeviceDetailsDocuments: React.FC<IDeviceDetailsDocumentsProps> = (props: IDeviceDetailsDocumentsProps) => {

    const context = useContext(DeviceDetailsContext);
    const _productModelDocumentPreviewModalRef: React.RefObject<DocumentPreviewModal> = React.createRef();

    const [deviceDocuments, setDeviceDocuments] = useState<FileInfoModel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const downloadDocument = async (rowData: SimpleTableRowData) => {

        const model = rowData as FileInfoModel;

        const request: LogDocumentEvent = {
            fileId: model.documentId?.toString() ?? model.url ?? "Unknown",
            fileName: model.name,
            rentaId: context.rentaId,
            rentalObjectNumber: context.rentaGroup,
        };

        logDocumentEvent(request);

        const documentPreviewModal: DocumentPreviewModal = _productModelDocumentPreviewModalRef.current!;

        const fileModel = await getFileModel(model);

        const previewModel = new DocumentPreviewModel();

        previewModel.title = model.name;
        previewModel.subtitle = ' '; //Need to be whitespace because of how CL is done
        previewModel.document = fileModel;
        previewModel.size = DocumentPreviewSize.Large;

        await documentPreviewModal.openAsync(previewModel);
    };

    const tableHeaders = [
        {key: "name", label: Localizer.genericFileName}, // "Filename"
        {
            key: "type", label: Localizer.genericFileType, render: (rowData: SimpleTableRowData) => (
                rowData.type?.split("/")[1].toUpperCase()
            )
        },
        {
            key: "download", label: "", render: (rowData: SimpleTableRowData) => (
                <Button size={ButtonSize.Small} type={ButtonType.Icon} className={`${styles.downloadButton} downloadButton`} onClick={() => downloadDocument(rowData)}>
                    <Icon name={"fa-download"}/>
                </Button>
            )
        },
    ];

    const loadDeviceDocuments = async (rentaId: string): Promise<void> => {
        try {
            setIsLoading(true);
            const response = await getDeviceDocumentsAsync(rentaId);
            if (response) {
                setDeviceDocuments(response);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.error('Error loading device documents:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (context.rentaId) {
            loadDeviceDocuments(context.rentaId);
        }
    }, []);

    return (
        <div className={`${styles.deviceDetailsDocuments} ${styles.moduleWrapper}`}>
            <Text tag="h3" transform="uppercase" className={styles.moduleTitle}>
                {Localizer.deviceDetailsDocumentsTitle}
            </Text>

            <Box className={styles.moduleContainer}>
                <LoadingDecorator className={styles.moduleEmptyContainer} hideConentOnLoading={true} isLoading={isLoading}>
                    <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"deviceDocuments"}>
                        {deviceDocuments?.length ? (
                            <SimpleTable headers={tableHeaders}
                                         data={deviceDocuments}
                                         rowGridClassName={styles.rowGrid}
                                         noDataText={Localizer.genericNoData}
                            />
                        ) : (
                            <Box p={16}>
                                <Text tag="span" weight="bold">{Localizer.deviceDetailsDocumentsNoDocuments}</Text>
                            </Box>
                        )}
                    </Box>
                </LoadingDecorator>
            </Box>
            <DocumentPreviewModal
                ref={_productModelDocumentPreviewModalRef}
            />
        </div>
    );
};

export default DeviceDetailsDocuments;