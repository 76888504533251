import queryString from "query-string";
import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import {ProductGroupName} from "@/models/server/ProductGroupName";
import ProductAvailabilityRequest from "@/models/server/ProductAvailabilityRequest";

export const searchProductGroupNames = async (searchTerm: string, searchLimit: number = 50): Promise<ProductGroupName[]> => {
    const query = queryString.stringify({searchTerm, searchLimit});
    return ApiProvider.getAsync<ProductGroupName[]>(`/api/Product/SearchProductGroupNames?${query}`, null);
};

export const getProductDepotAvailability = async (request: ProductAvailabilityRequest): Promise<boolean> => {
    return ApiProvider.postAsync<boolean>("/api/Product/ProductDepotAvailability", request, this);
};