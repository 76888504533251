import Localizer from "@/localization/Localizer";
import { pageData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters tests', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("cs.user@domain.fi", "ConstructionSite1");
        });
    });

    it('should display product search view control', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedList.json' });

        cy.intercept('POST', 'api/ConstructionSites/GetConstructionSiteDetails', { fixture: 'constructionSiteDetails.json' });

        cy.visit('frontpage');

        pageData().topMenu.frontpage().trigger('click');

        pageData().frontPage.widgets.fleetMonitoring()
            .trigger('click');

        DeviceGridHelper.getViewControlFilter(0)
            .find('label')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsName);

        DeviceGridHelper.getViewControlFilter(0)
            .find('button')
            .find('svg')
            .should('exist');
    });

    it('should set input fields with the product value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.deviceNames?.[0] === 'volvo') {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.visit('/fleet-monitoring?sortBy=AlertsCount&sortOrder=Asc&deviceNames=["test"]');

        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.getViewControlFilter(0)
                .find('input')
                .should('have.attr', 'placeholder', Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.checkTags(["test"]);

            DeviceGridHelper.deleteTag(0);

            DeviceGridHelper.getViewControlFilter(0)
                .find('input')
                .type('volvo');

            pageData().fleetMonitoring.submitButton()
                .click();

            cy.wait('@postRequestFiltered').then(({ request }) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('deviceNames');
                expect(request.body.deviceNames).to.have.length(1);
                expect(request.body.deviceNames[0] === 'volvo');
            });
        });
    });
});