import {SortDirection} from "@renta-apps/athenaeum-toolkit";
import Localizer from "@/localization/Localizer";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import {pageData} from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";
import {Method} from "cypress/types/net-stubbing";

describe('Desktop - Fleet Monitoring - Devices filters tests', () => {
    before(() => {
        cy.clearAllLocalStorage();
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should display view controls', () => {
        const {routes: {getDevicesPagedList}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', {fixture: 'constructionSiteInfos.json'});

        cy.visit('frontpage');

        pageData().topMenu.frontpage().trigger('click');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');



        DeviceGridHelper.getViewControl()
            .should('exist');

        // The filter has five visible elements.
        DeviceGridHelper.getViewControl()
            .children('div')
            .filter(':visible')
            .should('have.length', 3);

        DeviceGridHelper.getDropdown("filter-by-construction-site-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        pageData().fleetMonitoring.filters.sortByFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 6)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByIdle);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByAlerts);
                        break;
                    case 2:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByBattery);
                        break;
                    case 3:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByFuel);
                        break;
                    case 4:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByName);
                        break;
                    case 5:
                        expect($option).to.contain(Localizer.fleetMonitoringPageFiltersSortByReturnPickupTime);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortByFilter.dropdown());

        // Open the dropdown.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

        pageData().fleetMonitoring.filters.sortOrderFilter.dropdown()
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 2)
            .each(($option, index) => {
                switch (index) {
                    case 0:
                        expect($option).to.contain(Localizer.enumSortDirectionDesc);
                        break;
                    case 1:
                        expect($option).to.contain(Localizer.enumSortDirectionAsc);
                        break;
                    default:
                        throw new Error('Unexpected option index');
                }
            });

        // Close it.
        DropdownHelper.toggleDropdown(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown());

    });

    it('should call API when sort by filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.sortColumnName === 'BatteryLevel') {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.visit('/fleet-monitoring');



        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortColumnName', 'IdleDays');

            // Select the Battery option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 2);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortColumnName', 'BatteryLevel');

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}`)
                    .should('include', 'pageNumber=1&pageSize=25&sortBy=BatteryLevel&sortOrder=Desc');
            });
        });

    });

    it('should call API when sort order filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.sortDirection === SortDirection.Asc) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });
        cy.visit('/fleet-monitoring');



        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('sortDirection', SortDirection.Desc);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('sortDirection', SortDirection.Asc);

                cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}`)
                    .should('include', 'pageNumber=1&pageSize=25&sortBy=IdleDays&sortOrder=Asc');
            });
        });

    });

    it('should call API when construction site filter is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.constructionSites.length) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedListEmpty.json'});
        });

        cy.visit('/fleet-monitoring');



        // First call to get devices
        cy.wait('@postRequest', {timeout: 20_000}).then(({request}) => {
            expect(request.body).to.have.property('contractId', '31eaa6c0-42fa-40c2-a891-bcd16c367409');
            expect(request.body).to.have.property('constructionSiteId', null);
            expect(request.body).to.have.property('constructionSites');
            expect(request.body.constructionSites).to.have.length(0);

            // Select a construction site
            DeviceGridHelper.getDropdownInput('filter-by-construction-site-dropdown').should("exist").should("be.visible").click();
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 14);
            DeviceGridHelper.typeDropdownInput('filter-by-construction-site-dropdown', 'test');
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 9);
            DeviceGridHelper.clickDropdownListItem('filter-by-construction-site-dropdown', 4);

            // Double check the options are set
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            // The request after the first construction site filter change
            cy.wait('@postRequestChanged').then(({request}) => {
                expect(request.body).to.have.nested.property('constructionSites');
                expect(request.body.constructionSites).to.have.length(1);
                expect(request.body.constructionSites[0].id === '1');
                expect(request.body.constructionSites[0].name === 'SAMMUTUKSEN TESTAUS');

                // Deselect the construction site
                DeviceGridHelper.deleteTag(0);

                // The request after the second construction site filter change
                cy.wait('@postRequest').then(({request}) => {
                    expect(request.body).to.have.property('constructionSites');
                    expect(request.body.constructionSites).to.have.length(0);

                    DeviceGridHelper.checkTags([]);
                });
            });
        });
    });

    it('should set input fields with the default values correctly', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedListEmpty.json'}).as('postRequest');

        cy.visit(`/fleet-monitoring?constructionSites=${JSON.stringify([{"name": "TESTITILAUS RENTA EASY"}])}&constructionSiteId=f7422461-9c46-416d-b500-7ab6ae1ee591&sortBy=FluidLevel&sortOrder=Asc`);



        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", "TESTITILAUS RENTA EASY");

            DeviceGridHelper.checkTags(['TESTITILAUS RENTA EASY']);

            filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByFuel);

            filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            cy.visit(`/fleet-monitoring?constructionSites=${JSON.stringify([{"name": "TESTITILAUS RENTA EASY 1"}])}&constructionSiteId=68240177-21ea-4d88-863e-fa924c8005df&sortOrder=Desc&sortBy=BatteryLevel`);

            cy.wait('@postRequest').then(() => {
                DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", "TESTITILAUS RENTA EASY 1");

                DeviceGridHelper.checkTags(['TESTITILAUS RENTA EASY 1']);

                filters.sortByFilter.title()
                    .should('have.text', Localizer.fleetMonitoringPageFiltersSortByBattery);

                filters.sortOrderFilter.title()
                    .should('have.text', Localizer.enumSortDirectionDesc);
            });
        });
    });

    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {
        const {routes: {getDevicesPagedList}, filters} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'}).as('postRequest');

        cy.visit('/fleet-monitoring?constructionSites=[]');



        cy.wait('@postRequest').then(() => {
            DeviceGridHelper.checkTags([]);

            // Select a construction site
            DeviceGridHelper.getDropdownInput('filter-by-construction-site-dropdown').should("exist").should("be.visible").click();
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 14);
            DeviceGridHelper.typeDropdownInput('filter-by-construction-site-dropdown', 'test');
            DeviceGridHelper.getDropdownList('filter-by-construction-site-dropdown').find('[data-cy="dropdownItem"]').should('have.length', 9);
            DeviceGridHelper.clickDropdownListItem('filter-by-construction-site-dropdown', 4);

            // Select the Name option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortByFilter.dropdown(), 4);

            // Select the Ascending option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.filters.sortOrderFilter.dropdown(), 1);

            // Double-check the options are set
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}`)
                .should('include', 'SAMMUTUKSEN%20TESTAUS');

            filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByName);

            filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);

            // Leave the page
            cy.visit('/rent');

            // Go back to the page
            cy.visit('/fleet-monitoring');

            // Options are remembered
            DeviceGridHelper.checkTags(['SAMMUTUKSEN TESTAUS']);

            filters.sortByFilter.title()
                .should('have.text', Localizer.fleetMonitoringPageFiltersSortByName);

            filters.sortOrderFilter.title()
                .should('have.text', Localizer.enumSortDirectionAsc);
        });
    });
});