import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe("Desktop - Shopping cart tests", () => {
    const postalCode: string = "01370";
    const address: string = "Rillitie 8";
    const city: string = "Vantaa";

    it("Prompts for strong authentication for user who has not done any orders", () => {

        pageData().common.interceptFeatureFlags([
            { flagName: RentaEasyConstants.featureFlagRequireStrongAuthOnFirstOrder, enabled: true },
            { flagName: RentaEasyConstants.featureFlagSsoLogin, enabled: true },
        ]);

        arrangeUserContext("Matti.Hautamaki@weare.fi", "tmi jukka wilska");

        addProductToCart();
        assertProductConfirmationModal();
        fillOrder();

        cy.get('.athenaeum-message-box-content')
            .find(".athenaeum-message-box-caption")
            .find("h5")
            .should("have.text", Localizer.shoppingCartStrongAuthenticationRequired)
    });

    it("Adds product to cart and send order with immutable details", () => {
        arrangeUserContext("Urho.Kekkonen@weare.fi", "tmi jukka wilska");

        addProductToCart();
        assertProductConfirmationModal();
        fillOrder();
        sendOrder();
        assertOrderDetails();
    });

    it("Presents subCompanies, when the chosen organization is on level < 2", () => {
        arrangeUserContext("org.main@domain.fi", "Unit test2 Oy");

        addProductToCart();
        assertProductConfirmationModal();
        redirectToShoppingCart();

        // verify whether subcompanies do exist
        pageData().rent.shoppingCart.subCompanyDropDown().should("exist");

        // select different contract (from lvl 2)
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#company-selection", 2);

        // verify whether subcompanies do not exist
        pageData().rent.shoppingCart.subCompanyDropDown().should("not.exist");
    });

    it("Adds product to cart and prevents the order request submission when delivery method is not selected and depot is not selected", () => {

        pageData().common.interceptFeatureFlags([
            { flagName: RentaEasyConstants.featureFlagSsoLogin, enabled: false },
        ]);

        cy.session('private_user_session', () => cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1"), {});

        cy.init("Desktop");
        pageData().rent.visitWithIntercept();

        addProductToCart();
        fillOrder(false, false, false);

        // error message for delivery type
        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.shoppingCartPageValidationDeliveryMethodMissing);

        // select the delivery type
        selectTheFirstDeliveryMethod();

        // repeat submission
        submitOrder();

        // error message for depot
        cy.get('.athenaeum-alert-message')
            .should('contain', Localizer.shoppingCartPageValidationDepotMissing);

        // select the depot
        selectTheFirstDepot();

        // repeat submission - it should be successful now
        submitOrder();
        sendOrder();
        assertOrderDetails(false);
    });

    it("Hides the product return date inputs, when featureFlagShoppingCartProductReturnDateVisible is disabled", () => {
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagShoppingCartProductReturnDateVisible);

        cy.session('private_user_session', () => cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1"), {});

        cy.init("Desktop");
        pageData().rent.visitWithIntercept();

        addProductToCart();
        redirectToShoppingCart(false);

        // product return date not visible
        cy.get("[data-cy=product-return-date]").should('not.exist');
    });

    function arrangeUserContext(email: string, company: string): void {
        cy.session([`company_role_session_${company}_${email}`], () => {
            cy.loginAndSelectCompanyRole(email, company);
        });
        cy.init("Desktop");
        pageData().rent.visitWithIntercept();
    }

    function assertProductConfirmationModal() {
        pageData().rent.confirmationModal.goToCartButton().should("not.be.disabled");
        pageData().rent.confirmationModal.goToCartButton().should("contain", Localizer.productModalGoToCart);
        pageData().rent.confirmationModal.goToCartButton().should("have.class", "athenaeum-button-color_orange");
        pageData().rent.confirmationModal.continueShoppingButton().should("contain", Localizer.productModalContinueShopping);
    }

    function sendOrder() {
        executeWithIntercept(() =>
                pageData().shoppingCart.confirmationDialog()
                    .click(),
            [pageData().shoppingCart.routes.sendOrder as RouteData]);
    }

    function assertOrderDetails(verifyForm: boolean = true) {
        cy.get("h3").should("contain", Localizer.orderInfoText);
        cy.get(".athenaeum-alert-message").contains(Localizer.shoppingCartSentShort);
        if (verifyForm) {
            cy.get("#order_details_address").should("have.text", address);
            cy.get("#order_details_city").should("have.text", city);
            cy.get("#order_details_postalCode").should("have.text", postalCode);
        }
    }

    function redirectToShoppingCart(redirectWithInterception: boolean = true): void {
        if (redirectWithInterception) {
            onRedirect(() => executeWithIntercept(() => cy.visit("/ShoppingCart"),
                [
                    pageData().common.routes.depots as RouteData,
                    pageData().constructionSites.routes.sitesForContract as RouteData
                ]));

            cy.wait(2000);
        } else {
            cy.visit("/ShoppingCart");
        }
    }

    function fillOrder(isDeliveryTypeSelected: boolean = true, isDepotSelected: boolean = true, redirectWithInterception: boolean = true) {
        redirectToShoppingCart(redirectWithInterception);
        pageData().shoppingCart.address().focus().type(address);
        pageData().shoppingCart.postalCode().focus().type(postalCode);
        pageData().shoppingCart.city().focus().type(city);

        // Select the first available delivery type
        if (isDeliveryTypeSelected) {
            selectTheFirstDeliveryMethod();
        }

        // Select the first available depot
        if (isDepotSelected) {
            selectTheFirstDepot();
        }

        // Select first available day
        pageData().shoppingCart.dateRangeInput()
            .click()
            .find(".athenaeum-date-range-input-isToday")
            .then(($btn: any) => {
                if ($btn.hasClass('athenaeum-date-range-input-gridDayDisabled')) {
                    pageData().shoppingCart.dateRangeInput()
                        .find(".athenaeum-date-range-input-monthViewGridDay")
                        .not(".athenaeum-date-range-input-isToday")
                        .not(".athenaeum-date-range-input-gridDayDisabled")
                        .not(".athenaeum-date-range-input-isOutOfRange")
                        .first()
                        .click();
                }
                else {
                    $btn.click();
                }
            });

        //Select next month if current month has only single not disabled day left.
        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .children()
            .then((children) => {
                if (children.length === 1) {
                    cy.get(".athenaeum-date-range-input-monthAction")
                        .last()
                        .click();
                }
            });

        cy.get(".athenaeum-date-range-input-disabledDayHint").should('not.exist');

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay.athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        cy.get(".athenaeum-date-range-input-disabledDayHint")
            .find("label")
            .should("contain.text", Localizer.shoppingCartPageCannotRentOrReturnOnAWeekend);

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-isToday")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        const comment: string = "Renting this construction equipment was a game-changer for our project. " +
            "The excavator we got from this company was in perfect condition, saving us the hassle of maintenance. " +
            "It boosted our productivity, completing tasks swiftly and efficiently. " +
            "Plus, the customer service was outstanding—they went above and beyond to ensure we had what we needed. " +
            "Renting over buying was definitely the right choice for us!";

        cy.get("#shopping_cart_input_comment").type(comment);

        submitOrder();
    }

    function addProductToCart() {
        //Wait that categories are rendered.
        pageData().rent.firstCategoryItem();

        executeWithIntercept(() => cy.get("a").contains("Työkoneet").click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        //Wait for render
        pageData().rent.firstCategoryItem();

        executeWithIntercept(() => cy.get("a").contains("Pyöräkuormaajat")
                .click(),
            [pageData().rent.routes.categoriesData as RouteData]);


        executeWithIntercept(() => {
                cy.get("[id^=product_]").first().find("#addToCart").click();
                pageData().rent.confirmationModal.goToCartButton().should("be.disabled");
            },
            [pageData().rent.routes.saveProductToCart as RouteData]);
    }

    function selectTheFirstDeliveryMethod() {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#shopping_cart_dropdown_delivery_type", 0);
    }

    function selectTheFirstDepot() {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#shopping_cart_dropdown_depot", 0);
    }

    function submitOrder() {
        cy.get("#shopping_cart_submit").trigger('click');
    }
});