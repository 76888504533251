import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AddressHelper, GoogleMap, IGoogleApiSettings, IGoogleMapMarker, Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import {Button, ButtonSize, ButtonType, Icon, IDropdownItem, Input} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import styles from "./EditSiteLocationModal.module.scss";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {ApplicationContext, ch} from "@renta-apps/athenaeum-react-common";
import LocalizationHelper from "@/helpers/LocalizationHelper";
import {GeoCoordinate, GeoLocation} from "@renta-apps/athenaeum-toolkit";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {saveSiteLocation} from "@/services/ConstructionSiteService";
import {useResize} from "@/helpers/Hooks";

interface EditSiteLocationModalProps {
    constructionSiteId: string;
    initialLocation: GeoLocation | null;
    onConstructionSiteLocationChanged: (location: GeoLocation | null) => void;
    onClose(): void;
}

const formatLocation = (location: GeoLocation | null): string => {
    if (!location) {
        return '';
    }

    const {city, address, lat, lon} = location;
    return city && address ? `${address}, ${city}` : lat && lon ? `${lat}, ${lon}` : '-';
};

const createMapMarkersFromLocation = (location: { lat: number, lon: number } | null): IGoogleMapMarker[] => {
    if (!location) {
        return [];
    }

    return [
        {
            position: {
                lat: location.lat,
                lng: location.lon,
            },
        }
    ];
};

const EditSiteLocationModal: React.FC<EditSiteLocationModalProps> = ({constructionSiteId, initialLocation, onClose, onConstructionSiteLocationChanged}) => {
    const _modalRef: React.RefObject<Modal> = useRef(null);
    const _mapContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
    const _mapRef: React.RefObject<GoogleMap> = useRef(null);
    const [saving, setSaving] = useState(false);
    const [mapHeight, setMapHeight] = useState(0);
    const [location, setLocation] = useState(() => formatLocation(initialLocation));
    const [newCoordinate, setNewCoordinate] = useState<GeoCoordinate | undefined>(undefined);
    const [newGeoLocation, setNewGeoLocation] = useState<GeoLocation | undefined>(undefined);
    const [mapMarkers, setMapMarkers] = useState(() => createMapMarkersFromLocation(initialLocation));
    const {windowWidth} = useResize();

    const locationChanged = useMemo(() => {
        return !!(newCoordinate || newGeoLocation || (initialLocation && !location));
    }, [newCoordinate, newGeoLocation, initialLocation, location]);

    useEffect(() => {
        if (!newGeoLocation && !newCoordinate) {
            return;
        }

        const {lat, lon} = newGeoLocation ?? newCoordinate!;
        setMapMarkers(createMapMarkersFromLocation({lat, lon}));
        _mapRef?.current?.setCenterAsync({lat, lng: lon});
    }, [newGeoLocation, newCoordinate]);

    const mapsApiKey = useMemo(() => {
        const context: ApplicationContext = ch.getContext();
        const settings = context.settings as IGoogleApiSettings;

        return settings.googleMapApiKey;
    }, []);

    const mapCenter: google.maps.LatLngLiteral = useMemo(() => {
        return initialLocation ? {
            lat: initialLocation.lat,
            lng: initialLocation.lon,
        } : {
            lat: RentaEasyConstants.defaultLatitude,
            lng: RentaEasyConstants.defaultLongitude,
        };
    }, [initialLocation]);

    const {placesAutocompleteService, placesService} = usePlacesService({apiKey: mapsApiKey});

    useEffect(() => {
        if (!_modalRef.current) {
            return;
        }

        const modalRefLocal = _modalRef.current;
        modalRefLocal?.openAsync();

        return () => {
            modalRefLocal?.closeAsync();
        };
    }, [_modalRef]);

    useEffect(() => {
        if (!_mapContainerRef.current) {
            return;
        }

        // first we render the modal with full height, to calculate the map height, if it's higher than 560px, we set it to 560px
        // and the modal height will adjust accordingly
        const divTimeout = setTimeout(() => {
            if (_mapContainerRef.current) {
                setMapHeight(Math.min(_mapContainerRef.current.offsetHeight, 560));
            }
        }, 5);

        return () => clearTimeout(divTimeout);
    }, [_mapContainerRef]);

    const getPlaceDetails = useCallback((placeId: string) => {
        if (!placesService) {
            return Promise.resolve(undefined);
        }

        return new Promise<GeoLocation | undefined>((resolve) => {
            placesService.getDetails({
                placeId,
                language: Localizer.language,
                fields: ["address_components", "formatted_address", "geometry"],
            }, (place, status) => {
                if (status === 'OK') {
                    const geoLocation: GeoLocation = AddressHelper.getLocationFromGeocodeResult(place!);

                    if (geoLocation.lat === 0 && geoLocation.lon === 0) {
                        return;
                    }

                    resolve(geoLocation);
                } else {
                    resolve(undefined);
                }
            });
        });
    }, [placesService]);

    const getFirstMatchingAddress = useCallback((address: string) => {
        if (!placesService) {
            return Promise.resolve(undefined);
        }

        return new Promise<string | undefined>((resolve) => {
            placesService.findPlaceFromQuery({
                query: address,
                language: Localizer.language,
                fields: ["place_id"],
            }, (places, status) => {
                if (status === 'OK' && places?.length) {
                    resolve(places[0].place_id);
                } else {
                    resolve(undefined);
                }
            });
        });
    }, [placesService]);

    const searchLocations = useCallback((value: string): Promise<IDropdownItem[]> => {
        if (!placesAutocompleteService) {
            return Promise.resolve([]);
        }

        return new Promise((resolve) => {
            placesAutocompleteService?.getPlacePredictions({
                input: value,
                types: ['address'],
                language: Localizer.language,
                componentRestrictions: {country: LocalizationHelper.currentCountryCode},
            }, (predictions, status) => {
                if (status === 'OK') {
                    resolve(predictions?.map((prediction) => ({
                        value: prediction.place_id,
                        name: prediction.structured_formatting.main_text,
                        description: prediction.structured_formatting.secondary_text,
                    })) ?? []);
                } else {
                    resolve([]);
                }
            });
        });
    }, [placesAutocompleteService]);

    const setNewLocation = useCallback((geoLocation?: GeoLocation, coordinate?: GeoCoordinate) => {
        if (!geoLocation && !coordinate) {
            setMapMarkers([]);
        }
        setNewCoordinate(coordinate);
        setNewGeoLocation(geoLocation);
    }, []);

    const handleLocationSuggestionClick = useCallback(async (item: IDropdownItem) => {
        setLocation(`${item.name}, ${item.description}`);
        const geoLocation = await getPlaceDetails(item.value!.toString());
        setNewLocation(geoLocation);
    }, [getPlaceDetails, setNewLocation]);

    const handleInputBlur = useCallback(async () => {
        if (!location) {
            setNewLocation(undefined);
            return;
        }

        const coordinateRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        if (coordinateRegex.test(location)) {
            const [lat, lon] = location.split(',').map(parseFloat);
            setNewLocation(undefined, new GeoCoordinate(lat, lon));

            return;
        }

        const placeId = await getFirstMatchingAddress(location);
        if (!placeId) {
            return;
        }

        const geoLocation = await getPlaceDetails(placeId);
        setNewLocation(geoLocation);
    }, [setNewLocation, getPlaceDetails, getFirstMatchingAddress, location]);

    const handleMapClick = useCallback((coordinate: GeoCoordinate) => {
        setNewLocation(undefined, coordinate);
        setLocation(`${coordinate.lat}, ${coordinate.lon}`);
    }, [setNewLocation]);

    const handleSaveClicked = async () => {
        if (!locationChanged) {
            return;
        }

        setSaving(true);
        try {
            let newLocation: GeoLocation | null = null;
            if (newGeoLocation) { // address selected from suggestions or entered manually
                newLocation = newGeoLocation;
            } else if (newCoordinate) { // coordinates entered manually or clicked on map
                newLocation = new GeoLocation(newCoordinate.lat, newCoordinate.lon);
                const address = await AddressHelper.findLocationByLatLngAsync(new google.maps.LatLng(newCoordinate.lat, newCoordinate.lon));
                newLocation.country = address?.country ?? LocalizationHelper.currentCountryCode;
                newLocation.address = `${newCoordinate.lat}, ${newCoordinate.lon}`;
            }

            await saveSiteLocation(constructionSiteId, newLocation);
            onConstructionSiteLocationChanged(newLocation);
            onClose();
        } catch (e) {
            console.error('Failed to save location:', e);
        } finally {
            setSaving(false);
        }
    };

    // memoize map component to prevent re-rendering, causing a site marker to blink whenever the input value changes
    const MapComponent = useMemo(() => {
        return mapHeight > 0 ? (
            <GoogleMap height={mapHeight}
                       streetViewControl={false}
                       mapTypeControl={false}
                       fullscreenControl={false}
                       initialZoom={10}
                       initialCenter={mapCenter}
                       markers={mapMarkers}
                       onClick={async (_, coordinate) => handleMapClick(coordinate)}
                       ref={_mapRef}
            />
        ) : null;
    }, [handleMapClick, mapHeight, mapCenter, mapMarkers]);

    return (
        <Modal id="edit-location-modal"
               ref={_modalRef}
               preventEsc
               preventClosingOnInsideClick
               preventClosingOnOutsideClick
               size={ModalSize.Default}
               title={Localizer.constructionSiteDetailsMapModalTitle}
               className={styles.modal}
               onClose={async () => onClose()}
               notResponsive={windowWidth > 480}
        >
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <div className={styles.modalHeaderTitle}>{Localizer.constructionSiteDetailsMapModalHeader}</div>
                    <div className={styles.modalHeaderDescription}>{Localizer.constructionSiteDetailsMapModalDescription}</div>
                </div>
                <Input id="construction-site-location"
                       value={location}
                       label={Localizer.constructionSiteDetailsMapModalLocation}
                       className={styles.locationInput}
                       disabled={saving}
                       inputProperties={{
                           placeholder: Localizer.constructionSiteDetailsMapModalPlaceholder,
                           autoComplete: 'off'
                       }}
                       useSuggestions
                       getSuggestions={searchLocations}
                       onSuggestionClick={handleLocationSuggestionClick}
                       onChange={setLocation}
                       onBlur={handleInputBlur}
                />
                <div className={mapHeight ? '' : styles.mapContainer} ref={_mapContainerRef}>
                    {MapComponent}
                </div>
                <div className={styles.actionButtons} data-cy="edit-location-modal-action-buttons">
                    {saving ? (
                        <>
                            <div>
                                <Icon name="fa-solid fa-circle-notch fa-spin" size={24} color="#fe5000"/>
                            </div>
                            <div>
                                {Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}
                            </div>
                        </>
                    ) : (
                        <>
                            <Button onClick={() => onClose()}
                                    size={ButtonSize.Default}
                                    type={ButtonType.Secondary}
                                    className={styles.button}
                            >
                                {Localizer.formCancel}
                            </Button>
                            <Button onClick={() => handleSaveClicked()}
                                    size={ButtonSize.Default}
                                    type={ButtonType.Primary}
                                    className={styles.button}
                                    disabled={!locationChanged}
                            >
                                {Localizer.formSave}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default EditSiteLocationModal;