import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters depots tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('should display depot dropdown for admin', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.depots.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getDropdown("filter-by-depot-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsDepot);

        DeviceGridHelper.getDropdown("filter-by-depot-dropdown").should("be.visible");

        DeviceGridHelper.getDropdown("filter-by-depot-dropdown").click();

        DeviceGridHelper.typeDropdownInput("filter-by-depot-dropdown", "test");

        DeviceGridHelper.clickDropdownListItem("filter-by-depot-dropdown", 1);

        DeviceGridHelper.checkDropdownValue("filter-by-depot-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

        DeviceGridHelper.checkTags(["Unit test depo"]);

        cy.wait('@postRequestFiltered').then(({ request }) => {
            expect(request.body).to.have.nested.property('depots')
            expect(request.body.depots).to.have.length(1);
            expect(request.body.depots[0].name === 'Unit test depo');
            expect(request.body.depots[0].id === '202');
        });
    });

    it('should set depot dropdown with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.depots.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({ fixture: 'fleetGetReturnsPagedList.json' });
        });

        cy.visit(`/fleet-monitoring?depots=${JSON.stringify([{name:"Test depot with no attributes"}])}&selectedTab=palautuspyynn%C3%B6t`);

        DeviceGridHelper.checkTags(["Test depot with no attributes"]);

        cy.wait('@postRequestFiltered').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-depot-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.checkTags(["Test depot with no attributes"]);
        });
    });
});