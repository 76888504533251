import { pageData } from "../../../helpers/CypressHelper";

// Add export to fix compilation error TS1208
export {};


describe('Desktop - Logged in private user frontpage tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session('private_user_session', () => cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1"), {});
    });

    it('Open frontPage and verify proper widgets are visible', () => {
        cy.visit('frontpage');

        pageData().frontPage.widgets.rent().should('exist');
        pageData().frontPage.widgets.fleetMonitoring().should('not.exist');
        pageData().frontPage.widgets.depot().should('exist');

        cy.get('#roleDropdown').should('not.exist');
        cy.get('#apply_company_access_widget').should('exist');
        cy.get('#inventory_management_widget').should('not.exist');
        cy.get('#private_orders_widget').should('exist');
        cy.get('#service_widget').should('exist');
    });

});