import React, {useState} from "react";
import {FleetMonitoringFiltersSortAndPagination} from "@/pages/FleetMonitoring/ViewControl/ViewControl";
import {IDropdownItem} from "@renta-apps/renta-react-components";
import isEqual from "lodash.isequal";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";

export type DropdownFilterFieldName = 'companies' | 'constructionSites' | 'depots' | 'productGroups' | 'statuses';
const filterAndSortFieldNames: string[] = ['companies', 'constructionSites', 'depots', 'productGroups', 'statuses', 'deviceNames', 'sortBy', 'sortOrder'];

type UseFiltersStateReturn = [
    FleetMonitoringFiltersSortAndPagination,
    (filterName: DropdownFilterFieldName, items: IDropdownItem[]) => void,
    (filtersChanges: Partial<FleetMonitoringFiltersSortAndPagination>) => void,
    () => void,
    React.Dispatch<React.SetStateAction<FleetMonitoringFiltersSortAndPagination>>,
];

export const useFiltersState = (initialFilters: FleetMonitoringFiltersSortAndPagination | (() => FleetMonitoringFiltersSortAndPagination)): UseFiltersStateReturn => {
    const [filters, setFilters] = useState<FleetMonitoringFiltersSortAndPagination>(initialFilters);

    const setFilterFromDropdownItems = (filterName: DropdownFilterFieldName, items: IDropdownItem[]) => {
        setFiltersPartial({
            [filterName]: items.map(item => ({id: item.value, name: item.name} as SelectedFilter)),
        });
    };

    const setFiltersPartial = (filtersChanges: Partial<FleetMonitoringFiltersSortAndPagination>) => {
        const changes: Partial<FleetMonitoringFiltersSortAndPagination> = Object.entries(filtersChanges)
            .filter(([key, value]) => !isEqual(value, filters[key as keyof FleetMonitoringFiltersSortAndPagination]))
            .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});

        if (Object.keys(changes).length === 0) {
            return;
        }

        setFilters(prevFilters => ({
            ...prevFilters,
            ...changes,
            // Clear construction sites when companies are cleared
            ...((changes.companies && changes.companies.length === 0 && prevFilters.constructionSites.length) ? {constructionSites: []} : {}),
            // Reset page number when filters or sort change
            ...((filterAndSortFieldNames.some(key => key in changes) && !('pageNumber' in changes)) ? {pageNumber: 1} : {}),
        }));
    };

    const resetFilters = () => {
        setFiltersPartial({
            companies: [],
            constructionSites: [],
            depots: [],
            productGroups: [],
            statuses: [],
            deviceNames: [],
        });
    };

    return [
        filters,
        setFilterFromDropdownItems,
        setFiltersPartial,
        resetFilters,
        setFilters,
    ];
};