export default class InviteUserPostRequest {

    public contractId: string = "";

    public usersList: InvitedUser[] = [];

    public accessToAllConstructionSites: boolean = false;

    public companyMainUserRole: boolean = false;

    public constructionSiteAccesses: IConstructionSiteAccess[] = [];
}

export class InvitedUser {
    public firstName: string = "";

    public lastName: string = "";

    public telephone: string = "";

    public email: string = "";

    constructor(telephone: string, firstName: string = "", lastName: string = "", email: string = "") {
        this.telephone = telephone;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
    }
}

export interface IConstructionSiteAccess {
    access: boolean;
    directRentAccess: boolean;
    fullAccess: boolean;
    id: string;
    name: string;
    pricesVisible: boolean;
    isOpen: boolean;
    group?: string;
}