import Localizer from "@/localization/Localizer";
import {onRedirect} from "@/helpers/CypressHelper";

describe('Desktop - Authenticated construction site details tests', () => {
    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

        cy.visit('frontpage');
    });

    it('Should display page with construction site access friendly error', () => {
        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee592`));

        cy.get('h1')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedTitle);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedDescription);

        cy.get('div')
            .should('contain', Localizer.constructionSiteDetailsCannotBeAccessedWhatNext);

        cy.get('li')
            .should('contain', Localizer.genericTryLoggingInAgain);

        cy.get('a')
            .should('contain', Localizer.genericContactSupport);
    });

    it('Should display page with construction site details', () => {
        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=bade07a5-2828-4856-9d06-64cf361b6c84`));

        cy.get("[data-cy=construction-site-name]")
            .should('contain', 'ConstructionSite6');

        cy.get("[data-cy=construction-site-additional-reference]")
            .should('contain', 'TestSite6ExternalReference');

        cy.get("[data-cy=google_maps_link]")
            .should('contain', 'Rillitie 8, Vantaa');
    });

    it('Should open edit location modal and change location', () => {
        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=bade07a5-2828-4856-9d06-64cf361b6c84`));

        cy.get("[data-cy=construction-site-location]").find('button').click();

        cy.get("#edit-location-modal").should('be.visible');

        cy.get(".athenaeum-google-map-googleMap").should('be.visible');

        cy.get("#construction-site-location").should('have.value', 'Rillitie 8, Vantaa');

        cy.get("[data-cy=edit-location-modal-action-buttons").find('button').eq(1)
            .should('contain', Localizer.formSave)
            .should('be.disabled');

        cy.get("#construction-site-location").clear().type('Kirkonkyläntie 70, Helsinki');

        cy.get("#edit-location-modal_body").click(5, 5); // blur input

        cy.get("[data-cy=edit-location-modal-action-buttons").find('button').eq(1)
            .should('not.be.disabled');

        cy.get("[data-cy=edit-location-modal-action-buttons").find('button').eq(1).click();

        cy.get("#edit-location-modal").should('not.exist');

        cy.get("[data-cy=google_maps_link]")
            .should('contain', 'Kirkonkyläntie 70, Helsinki');
    });
});