import Localizer from "../../../localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";

// @ts-ignore
describe('Desktop - Admin user, news management page tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        // visit frontpage and see that news items are visible
        cy.visit('frontpage');

    });

    it('Visits admin news management page, verifies news content and edit existing item', () => {
        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 2);

        // visit admin news management page and verify that news items are visible
        cy.visit(Localizer.pageRoutesAdminNewsEditor);
        cy.url().should('include', Localizer.pageRoutesAdminNewsEditor);


        // carousel preview
        pageData().admin.news.newsItemsCarouselItems()
            .should("have.length", 2);
        pageData().admin.news.newsItemsCarouselItems().eq(0)
            .find("h2").should("contain.text", "Test News 1");
        pageData().admin.news.newsItemsCarouselItems().eq(1)
            .find("h2").should("contain.text", "Test News 3");

        // all items preview
        pageData().admin.news.newsItemsContainerItems()
            .should("have.length", 3);
        pageData().admin.news.newsItemsContainerItems().eq(0)
            .find("h2").should("have.text", "Test News 1");
        pageData().admin.news.newsItemsContainerItems().eq(1)
            .find("h2").should("have.text", "Test News 2");
        pageData().admin.news.newsItemsContainerItems().eq(2)
            .find("h2").should("have.text", "Test News 3");

        // edit second (disabled) item
        // should have css class "athenaeum-checkbox-checked" when enabled, or "athenaeum-checkbox-unchecked" when disabled
        pageData().admin.news.newsItemsContainerItems().eq(1).find("#newsItemEnabled")
            .find(" .athenaeum-checkbox-checkbox").should("have.class", "athenaeum-checkbox-unchecked");

        // open edit form
        pageData().admin.news.newsItemsContainerItems().eq(1)
            .find("#newsItemEditButton").click();

        // edit item - change title and enable
        pageData().admin.news.newsEditForm().find("#input_newsTitle").clear().type("Test News 2 - Edited");
        pageData().admin.news.newsEditForm().find("#newsIsEnabled .athenaeum-form-inputContainer").click();
        pageData().admin.news.newsEditForm().find("#newsItemSaveButton").click();

        // check if item is updated
        pageData().admin.news.newsItemsContainerItems().eq(1)
            .find("h2").should("have.text", "Test News 2 - Edited");
        pageData().admin.news.newsItemsContainerItems().eq(1).find("#newsItemEnabled")
            .find(" .athenaeum-checkbox-checkbox").should("have.class", "athenaeum-checkbox-checked");

        pageData().admin.news.newsItemsCarouselItems()
            .should("have.length", 3);
        pageData().admin.news.newsItemsContainerItems()
            .should("have.length", 3);

        // open edit form
        pageData().admin.news.newsItemsContainerItems().eq(1)
            .find("#newsItemEditButton").click();

        // edit item - change title and enable
        pageData().admin.news.newsEditForm().find("#input_newsTitle").clear().type("Test News 2");
        pageData().admin.news.newsEditForm().find("#newsIsEnabled .athenaeum-form-inputContainer").click();
        pageData().admin.news.newsEditForm().find("#newsItemSaveButton").click();

        // check if item is updated
        pageData().admin.news.newsItemsContainerItems().eq(1)
            .find("h2").should("have.text", "Test News 2");
        pageData().admin.news.newsItemsContainerItems().eq(1).find("#newsItemEnabled")
            .find(" .athenaeum-checkbox-checkbox").should("have.class", "athenaeum-checkbox-unchecked");

        pageData().admin.news.newsItemsCarouselItems()
            .should("have.length", 2);
        pageData().admin.news.newsItemsContainerItems()
            .should("have.length", 3);

        // navigete to frontpage and check if all news items are visible
        cy.get(".athenaeum-top-nav-middle .athenaeum-link-link").eq(0).click();
        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 2);
    });

    it('Visits admin news management page and add new item', () => {

        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 2);

        // visit admin news management page and verify that news items are visible
        cy.visit(Localizer.pageRoutesAdminNewsEditor);
        cy.url().should('include', Localizer.pageRoutesAdminNewsEditor);


        pageData().admin.news.newsItemsCarouselItems().should("have.length", 2);
        pageData().admin.news.newsItemsContainerItems().should("have.length", 3);

        // add new item
        pageData().admin.news.newsAddNewButton().click();

        pageData().admin.news.newsEditForm().find("#input_newsTitle").clear().type("New Test News");
        pageData().admin.news.newsEditForm().find("#input_newsDescription").clear().type("New Test News Description");
        pageData().admin.news.newsEditForm().find("#newsItemSaveButton").click();

        // check if item is added
        pageData().admin.news.newsItemsCarouselItems()
            .should("have.length", 3);
        pageData().admin.news.newsItemsContainerItems()
            .should("have.length", 4);

        // navigete to frontpage and check if item was added
        cy.get(".athenaeum-top-nav-middle .athenaeum-link-link").eq(0).click();
        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 3);
    });

    it('Visits admin news management page and delete existing item', () => {
        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 3);

        // visit admin news management page and verify that news items are visible
        cy.visit(Localizer.pageRoutesAdminNewsEditor);
        cy.url().should('include', Localizer.pageRoutesAdminNewsEditor);


        pageData().admin.news.newsItemsCarouselItems().should("have.length", 3);
        pageData().admin.news.newsItemsContainerItems().should("have.length", 4);

        pageData().admin.news.newsItemsContainerItems().eq(3).find("#newsItemDeleteButton").trigger('click');
        cy.get('.athenaeum-confirmation-dialog-dialogContent').last().find('button').eq(0).click();

        pageData().admin.news.newsItemsCarouselItems().should("have.length", 2);
        pageData().admin.news.newsItemsContainerItems().should("have.length", 3);

        // navigete to frontpage and check if item was deleted
        cy.get(".athenaeum-top-nav-middle .athenaeum-link-link").eq(0).click();
        cy.get("#frontpageContainer [data-cy='newsCarouselItem']").should("have.length", 2);
    });
});