import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "../../../localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";

// @ts-ignore
describe('Desktop - Logged in fill in basic info tests', () => {
    before(() => {

    });

    beforeEach(() => {
        cy.session('private_user_session', cy.login, {});
    });

    it('Open fill basic info page and checks renta office mandatory', () => {
        cy.visit(Localizer.pageRoutesFillBasicInfo);
        cy.get('#selectedRentaOffice').should('not.contain', Localizer.validatorsRequired.replace('{0}', Localizer.myAccountClosestRentaOffice));
        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        cy.get('#selectedRentaOffice').should('contain', Localizer.validatorsRequired.replace('{0}', Localizer.myAccountClosestRentaOffice));
    });

    it('Open fill basic info page and checks phone number format', () => {
        cy.visit(Localizer.pageRoutesFillBasicInfo);
        cy.url().should('include', Localizer.pageRoutesFillBasicInfo);


        pageData().fillBasicInfo.inputs.telephone.label().should('not.contain', Localizer.validatorsRequired.replace('{0}', Localizer.genericPhoneNumber));

        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        pageData().fillBasicInfo.inputs.telephone.input().focus().clear();
        pageData().fillBasicInfo.inputs.telephone.label().should('contain', Localizer.validatorsRequired.replace('{0}', Localizer.genericPhoneNumber));

        pageData().fillBasicInfo.inputs.telephone.input().focus().type("98765");
        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        pageData().fillBasicInfo.inputs.telephone.label().should('contain', Localizer.genericValidationPhoneNumber);

        pageData().fillBasicInfo.inputs.telephone.input().focus().clear();
        pageData().fillBasicInfo.inputs.telephone.input().focus().type("+87654")
        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        pageData().fillBasicInfo.inputs.telephone.label().should('contain', Localizer.genericValidationPhoneNumber);

        pageData().fillBasicInfo.inputs.telephone.input().focus().clear();
        pageData().fillBasicInfo.inputs.telephone.input().focus().type("765432")
        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        pageData().fillBasicInfo.inputs.telephone.label().should('contain', Localizer.genericValidationPhoneNumber);

        pageData().fillBasicInfo.inputs.telephone.input().focus().clear();
        pageData().fillBasicInfo.inputs.telephone.input().focus().type("+654321")
        pageData().fillBasicInfo.buttons.saveBasicInfo().click();
        pageData().fillBasicInfo.inputs.telephone.label().should('not.contain', Localizer.validatorsRequired.replace('{0}', Localizer.genericPhoneNumber));
    });
});