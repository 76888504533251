import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Button, ButtonType, Form, Modal, NumberInput} from "@renta-apps/athenaeum-react-components";
import {EmissionType} from "@/models/Enums";
import Localizer from "@/localization/Localizer";
import EnumProvider from "@/providers/EnumProvider";
import Co2DefinitionModel from "@/models/server/Co2DefinitionModel";
import RentaEasyController from "@/pages/RentaEasyController";
import {Dictionary} from "typescript-collections";

interface ICo2DefinitionModalProps {
    categoryId?: string;

    productId?: string;

    entityName: string;
}

interface ICo2DefinitionModalState {
    selectedCo2Definitions: Co2DefinitionModel[];
}

export default class Co2DefinitionModal extends BaseComponent<ICo2DefinitionModalProps, ICo2DefinitionModalState> {

    private readonly _modalRef: React.RefObject<Modal> = React.createRef();


    public state: ICo2DefinitionModalState = {
        selectedCo2Definitions: []
    };


    private get modal(): Modal {
        return this._modalRef.current!;
    }


    public async openAsync(): Promise<void> {
        if (this._modalRef.current) {
            await this._modalRef.current.openAsync();
        }
    }

    public async closeAsync(): Promise<void> {
        await this.setState({selectedCo2Definitions: []});
        if (this.modal) {
            await this.modal.closeAsync();
        }
        await this.reRenderAsync();
    }

    private renderCo2DefinitionInput(emissionType: EmissionType): React.ReactNode {
        return (
            <NumberInput label={EnumProvider.getEmissionTypeText(emissionType)}
                         id={`${emissionType}`}
                         value={this.getCo2DefinitionValueOrDefault(emissionType)}
                         key={`${emissionType}`}
                         min={Number.NEGATIVE_INFINITY}
                         step={0.01}
            />
        );
    }

    private async getCo2Definitions(): Promise<void> {
        const id: string = this.props.categoryId ?? this.props.productId ?? "";
        const co2Definitions: Co2DefinitionModel[] = await RentaEasyController.getCo2Definitions(id);
        await this.setState({selectedCo2Definitions: co2Definitions});
    }

    private getCo2DefinitionValueOrDefault(emissionType: EmissionType): number | null {
        const matchingItem: Co2DefinitionModel[] = this.state.selectedCo2Definitions.filter(item => item.emissionType === emissionType);
        if (matchingItem.length === 1) {
            return matchingItem[0].value;
        }
        return null;
    }

    private async saveCo2Definitions(data: Dictionary<string, any>): Promise<void> {

        const copy: Co2DefinitionModel[] = [...this.state.selectedCo2Definitions];

        const categoryId: string | null = this.props.categoryId ?? null;
        const productId: string | null = this.props.productId ?? null;


        Object.keys(EmissionType).map(item => {
            if (Number(item) >= 0) {
                setValueToArray(Number(item));
            }
        });

        function setValueToArray(emissionType: EmissionType): void {
            const value: number = data.getValue(emissionType.toString()) as number;

            // Allow save any number to existing object
            let match: Co2DefinitionModel[] = copy.filter(item => item.emissionType === emissionType);
            if (match.length === 1) {
                match[0].value = value;
            }
            // For new values allow only non 0 values
            else if (value !== 0) {
                copy.push({
                    value: value,
                    categoryId: categoryId,
                    emissionType: emissionType,
                    productId: productId,
                    id: null,
                });
            }
        }

        await this.postAsync(`/api/Admin/SaveCo2Definitions`, copy);

        if (this._modalRef.current) {
            await this._modalRef.current.closeAsync();
        }
    }


    public render(): React.ReactNode {
        return (
            <Modal
                ref={this._modalRef}
                onOpen={async () => {
                    await this.getCo2Definitions();
                }}
                title={`Default emissions for ${this.props.entityName}`}
                onClose={async () => {
                    await this.setState({selectedCo2Definitions: []});
                }}
            >
                {
                    <>
                        <Form id="editEmissions"
                              onSubmit={async (_, data) => await this.saveCo2Definitions(data)}>
                            {
                                Object.keys(EmissionType).map(item => {
                                    if (Number(item) >= 0) {
                                        return this.renderCo2DefinitionInput(Number(item));
                                    }
                                })

                            }
                            <Button submit block
                                    id="saveEmissionsButton"
                                    type={ButtonType.Orange}
                                    label={Localizer.genericSave}
                            />
                        </Form>
                    </>
                }
            </Modal>
        );
    }
}