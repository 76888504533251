import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "@/localization/Localizer";
import Rent from "@/pages/Rent/Rent";

describe('Desktop - Authenticated construction site details feature flags tests', () => {
    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.visit('frontpage');
    });

    describe('Nothing disabled test', () => {
        it('Should display all tabs when all flags are enabled', () => {
            onRedirect(() => {
                executeWithIntercept(() =>
                        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                    [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
                );
            });


            pageData().constructionSite.status.tab();
            pageData().constructionSite.equipment.tab();
            pageData().constructionSite.invoices.tab();
            pageData().constructionSite.users.tab();
            pageData().constructionSite.serviceRequests.tab();
            pageData().constructionSite.environmentControl.tab();
            pageData().constructionSite.emissions.tab();
            pageData().constructionSite.power.tab();

        });
    });

    describe('Disabled flags test', () => {

        it('Should display all tabs when all flags are enabled', () => {
            pageData().common.interceptFeatureFlags([
                {flagName: RentaEasyConstants.featureFlagCo2Enabled, enabled: false},
                {flagName: RentaEasyConstants.featureFlagEnvironmentControl, enabled: false},
                {flagName: RentaEasyConstants.featureFlagServicesView, enabled: false},
                {flagName: RentaEasyConstants.featureFlagConstructionSiteStatusPage, enabled: false},
                {flagName: RentaEasyConstants.featureFlagConstructionSitePowerPage, enabled: false}
            ])
            onRedirect(() => {
                executeWithIntercept(() =>
                        cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                    [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
                );
            });


            pageData().constructionSite.status.tab().should('not.exist');
            pageData().constructionSite.equipment.tab();
            pageData().constructionSite.invoices.tab();
            pageData().constructionSite.users.tab();
            pageData().constructionSite.serviceRequests.tab().should('not.exist');
            pageData().constructionSite.environmentControl.tab().should('not.exist');
            pageData().constructionSite.emissions.tab().should('not.exist');
            pageData().constructionSite.power.tab().should('not.exist');

        });
    });

});