import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import {AlarmMediaType} from "@/models/Enums";
import UserInvitationModel from "@/models/server/UserInvitationModel";
import queryString from "query-string";

export const saveUserAlarmMediaType = async (alarmMediaType: AlarmMediaType): Promise<void> => {
    return ApiProvider.postAsync<void>("/api/Users/SaveUserAlarmMediaType", alarmMediaType, this);
};

export const searchUsersAsync = async (searchTerm: string): Promise<UserInvitationModel[]> => {
    const query = queryString.stringify({searchTerm});
    return ApiProvider.getAsync<UserInvitationModel[]>(`/api/Users/SearchUsers?${query}`, null);
};