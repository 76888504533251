import React from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {PageContainer} from "@renta-apps/athenaeum-react-components";

interface IAlertSourcesProps {
}

const AlertSources: React.FC<IAlertSourcesProps> = (props: IAlertSourcesProps) => {
    return (
        <PageContainer className={styles.pageContainer}>
            <div className={styles.devices}>
                <div id="container" className={styles.container}>
                </div>
            </div>
        </PageContainer>
    );
};

export default AlertSources;