describe('Create a pricing plan', () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.visit('salestools/pricingtool')
    });


    it('Open Templates view, create new template', () => {
        cy.get('#pricingToolTemplates').click()

        // check number of templates
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 2)

        // add template
        cy.get('#AddNewTemplateBtn').click()
        cy.get('#addNewTemplateModal #input_pricingToolNewTemplateName').click()
        cy.get('#addNewTemplateModal #input_pricingToolNewTemplateName').type('zzz test template')
        cy.get('#addNewTemplateModal #input_pricingToolNewTemplateName').type('{Enter}')

        // check if template was added
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 3)
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'zzz test template')
        // select template
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

    })

    it('Open Templates view, make duplicate, delete the duplicate', () => {
        cy.get('#pricingToolTemplates').click()

        // select template
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // duplicate template
        cy.get('#DuplicateTemplateBtn').click()
        cy.get('#duplicateTemplateModal #input_pricingToolNewTemplateName').click()
        cy.get('#duplicateTemplateModal #input_pricingToolNewTemplateName').type('zzz test template 2')
        cy.get('#duplicateTemplateModal #input_pricingToolNewTemplateName').type('{Enter}')

        // check if template was duplicated
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'zzz test template 2')
        // select template
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // delete template
        cy.get('#DeleteTemplateBtn').click()
        cy.get('#confirmDeleteTemplateButton').click()

        // check if template was deleted
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 3)
        // select template
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

    })

    it('Open Templates view, go to template edit view', () => {
        cy.get('#pricingToolTemplates').click()

        // select template
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 3)
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // go to template edit view
        cy.get('#EditTemplateBtn').click()

        // check if template edit view is opened
        cy.get('#discountName > div:nth-child(2)').should('contain.text', 'zzz test template')
        cy.get('#overallDiscuntPercentage > div:nth-child(2)').should('contain.text', '0 %')

        // go back to templates view
        cy.get('#breadCrumb-container #bcItems :nth-child(6)').click()

        // check if template was duplicated
        cy.get('#SelectTemplateDD').click()
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 3)
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'zzz test template')
        // select template
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // delete template
        cy.get('#DeleteTemplateBtn').click()
        cy.get('#confirmDeleteTemplateButton').click()
    })

});