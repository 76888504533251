import React from "react";
import {
    Button, ButtonType,
    Checkbox, ConfirmationDialog, Dropdown, DropdownRequiredType, Form, Inline, InlineType, Modal, ModalSize, OneColumn, PageContainer, PageHeader, PageRow,
    TwoColumns
} from "@renta-apps/athenaeum-react-components";
import UserModel from "@/models/server/UserModel";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import PageDefinitions from "../../providers/PageDefinitions";
import {AlertModel, BasePageParameters, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {TextInput} from "@renta-apps/athenaeum-react-components";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "../../localization/Localizer";
import RentaEasyController from "@/pages/RentaEasyController";
import DepotModel from "@/models/server/DepotModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";

import styles from './FillBasicInfo.module.scss';
import { validatePhoneNumber } from "@/helpers/Validators";

export interface IFillBasicInfoParams extends BasePageParameters {
}

class FormData {
    address: string | null = null;
    postalcode: string | null = null;
    city: string | null = null;
    firstname: string | null = null;
    lastname: string | null = null;
    telephone: string | null = null;
    email: string | null = null;
    agreementAccepted: boolean = false;
    registrationAccepted: boolean = false;
    selectedRentaOffice: string | null = null;
    marketingAccepted: boolean = false;
}

interface IFillBasicInfoState {
    rentalOfficeDropdown: DepotModel[],
    alertModel: AlertModel | null,
    isSending: boolean,
    formData: FormData | null
}

export default class FillBasicInfo extends AuthorizedPage<IFillBasicInfoParams, IFillBasicInfoState> {

    state: IFillBasicInfoState = {
        rentalOfficeDropdown: [],
        alertModel: null,
        isSending: false,
        formData: null
    };

    private readonly _agreementRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _registrationRef: React.RefObject<Checkbox> = React.createRef();
    private readonly _confirmationRef: React.RefObject<ConfirmationDialog> = React.createRef();
    private readonly _marketingListRef: React.RefObject<Checkbox> = React.createRef();

    private get agreementCheckbox(): Checkbox {
        return this._agreementRef.current!;
    }

    private get registrationCheckbox(): Checkbox {
        return this._registrationRef.current!;
    }

    private get rentalOffices(): DepotModel[] {
        return this.state.rentalOfficeDropdown;
    }

    private get isSending(): boolean {
        return this.state.isSending;
    }

    private get formData(): FormData | null {
        return this.state.formData;
    }

    private get hasCompanyOrSiteRoles(): boolean {
        return this.user.organizationRoles?.length > 0
            || this.user.constructionSiteRoles?.length > 0;
    }

    protected get title(): string {
        return Localizer.createAccountText;
    }

    public async handleSubmitAsync(form: Form, data: Dictionary<string, any>): Promise<void> {
        //Preserve input values in state to prevent form emptying on alert
        let formData = new FormData();
        form.copyTo(data, formData);
        await this.setState({formData});

        if (!this.agreementCheckbox.checked) {
            await this.alertErrorAsync(Localizer.registerAgreementNeedsToBeAccepted, true, false);
        }
        else if (!this.registrationCheckbox.checked) {
            await this.alertErrorAsync(Localizer.registerRegisterNeedsToBeAccepted, true, false);
        }
        else {

            const newUser = new UserModel();
            newUser.email = this.userContext.username;
            newUser.favoriteDepotId = data.getValue("selectedRentaOffice");
            newUser.address = data.getValue("address");
            newUser.city = data.getValue("city");
            newUser.firstName = data.getValue("firstname");
            newUser.lastName = data.getValue("lastname");
            newUser.phoneNumber = data.getValue("telephone");
            newUser.postalCode = data.getValue("postalcode");
            newUser.registerAccepted = data.getValue("registrationAccepted");
            newUser.agreementAccepted = data.getValue("agreementAccepted");
            newUser.approveMarketing = data.getValue("marketingAccepted");
            newUser.id = RentaEasyConstants.emptyGuid;

            await this.setState({isSending: true});

            await this.postAsync("/api/Users/SaveUser", newUser);

            if (this.hasCompanyOrSiteRoles){
                await this.toFrontPageAsync();
            } else {
                await PageRouteProvider.redirectAsync(PageDefinitions.welcome.route())
            }
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.setState({isSending: true});

        const response: DepotModel[] = await RentaEasyController.getRentalOfficesAsync(this);

        await this.setState({rentalOfficeDropdown: response, isSending: false});

    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer className={styles.fillBasicInfo}>
                <PageHeader title={this.title}/>

                <PageRow>

                    <Form id="form" onSubmit={async (_, data) => await this.handleSubmitAsync(_, data)}>

                        <TwoColumns>
                            <TextInput required
                                       id="firstname"
                                       label={Localizer.genericFirstName}
                                       value={this.formData?.firstname ?? ""}
                            />

                            <TextInput required
                                       id="lastname"
                                       label={Localizer.genericLastName}
                                       value={this.formData?.lastname ?? ""}
                            />
                        </TwoColumns>

                        <OneColumn>
                            <TextInput required
                                       id="address"
                                       label={Localizer.genericAddress}
                                       value={this.formData?.address ?? ""}
                            />
                        </OneColumn>

                        <TwoColumns>
                            <TextInput required
                                       id="postalcode"
                                       label={Localizer.genericPostalCode}
                                       value={this.formData?.postalcode ?? ""}
                            />

                            <TextInput required
                                       id="city"
                                       label={Localizer.genericCity}
                                       value={this.formData?.city ?? ""}
                            />
                        </TwoColumns>

                        <OneColumn>
                            <TextInput required
                                       id="telephone"
                                       label={Localizer.genericPhoneNumber}
                                       value={this.formData?.telephone ?? RentaEasyConstants.telephoneCountryCode}
                                       validators={[validatePhoneNumber]}
                                       placeholder={Localizer.genericPlaceholderPhoneNumber}
                            />
                        </OneColumn>

                        <OneColumn>
                            <Dropdown required
                                      requiredType={DropdownRequiredType.Restricted}
                                      id="selectedRentaOffice"
                                      label={Localizer.myAccountClosestRentaOffice}
                                      items={this.rentalOffices}
                                      selectedItem={this.formData?.selectedRentaOffice ?? ""}
                            />
                        </OneColumn>

                        <OneColumn className="mb-3">
                            <Inline>
                                <Checkbox inline
                                          ref={this._agreementRef}
                                          id="agreementAccepted"
                                          label={Localizer.myAccountIHaveRedAndAgree}
                                          inlineType={InlineType.Right}
                                          value={this.formData?.agreementAccepted ?? false}
                                />

                                <Button toggleModal
                                        label={Localizer.myAccountPrivacyNotice}
                                        type={ButtonType.Text}
                                        dataTarget="privacyPolicyModal"
                                />
                            </Inline>

                            <Inline>
                                <Checkbox inline
                                          ref={this._registrationRef}
                                          id="registrationAccepted"
                                          label={Localizer.myAccountIHaveRedAndAgree}
                                          inlineType={InlineType.Right}
                                          value={this.formData?.registrationAccepted ?? false}
                                />

                                <Button toggleModal
                                        label={Localizer.myAccountRegisterTitle}
                                        type={ButtonType.Text}
                                        dataTarget="agreementModal"
                                />
                            </Inline>

                            <Inline>
                                <Checkbox inline
                                          ref={this._marketingListRef}
                                          id="marketingAccepted"
                                          label={Localizer.myAccountApproveMarketing}
                                          inlineType={InlineType.Right}
                                          value={this.formData?.marketingAccepted ?? true}
                                />
                            </Inline>

                            <Button submit
                                    id="saveBasicInfo"
                                    type={ButtonType.Orange}
                                    label={Localizer.formSave}
                                    icon={{name: "far save"}}
                                    disabled={this.isSending}
                            />
                        </OneColumn>

                    </Form>

                    <Modal id="agreementModal" info keepTextFormatting isBodyScrollable
                           title={Localizer.userAgreementTitle}
                           content={Localizer.userAgreementText}
                           size={ModalSize.ExtraLarge}
                    />

                    <ConfirmationDialog ref={this._confirmationRef}/>
                </PageRow>

            </PageContainer>
        );
    }
}