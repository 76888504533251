import React from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {BasePageParameters, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {Tab, Tabs} from "@renta-apps/renta-react-components";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import Localizer from "@/localization/Localizer";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import PageDefinitions from "@/providers/PageDefinitions";

import AlertSources from "./AlertSources";
import FleetMonitoringContainer, { FleetMonitoringFiltersAndPagination } from "./FleetMonitoringContainer";
import ReturnRequests from "./ReturnRequests";
import DeviceBottomSheet from "@/pages/FleetMonitoring/DeviceBottomSheet/DeviceBottomSheet";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";

export interface IFleetMonitoringParams extends BasePageParameters {
    sortBy?: string;
    sortOrder?: string;
    contractId?: string;
    pageNumber?: number;
    pageSize?: number;
    deviceNames?: Array<string>;

    selectedTab?: string;
    companies?: Array<SelectedFilter>,
    constructionSites?: Array<SelectedFilter>,
    depots?: Array<SelectedFilter>,
    productGroups?: Array<SelectedFilter>,
    statuses?: Array<SelectedFilter>,
}

interface IFleetMonitoringState {
    isDeviceBottomSheetVisible: boolean,
    isSubscribeToAlertsModalOpen: boolean,
    isReturnRequestModalOpen: boolean,
    isReturnRequestButtonEnabled: boolean,
    currentPageNumber: number,
    currentPageSize: number,
    currentSortBy: string,
    currentSortOrder: string,
    selectedTab?: string;
}

export default class FleetMonitoring extends AuthorizedPage<IFleetMonitoringParams, IFleetMonitoringState> {
    public state: IFleetMonitoringState = {
        isDeviceBottomSheetVisible: false,
        isSubscribeToAlertsModalOpen: false,
        isReturnRequestModalOpen: false,
        isReturnRequestButtonEnabled: false,
        currentPageNumber: 0,
        currentPageSize: 0,
        currentSortBy: "",
        currentSortOrder: "",
        selectedTab: this.typedParameters?.selectedTab ?? undefined,
    };

    protected get title(): string {
        return Localizer.fleetMonitoringPageTitle;
    }

    private async addTabNameToRoute(tabName: string): Promise<void> {
        this.setState({selectedTab: tabName});
        this.handleOnDeviceBottomSheetVisibilityChange(false);
        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(this.getCurrentRouteWithTabNameAdded(tabName), false);
    }

    private getCurrentRouteWithTabNameAdded(selectedTab: string): PageRoute {
        const route = PageRoute.clone(this.route);
        (route.parameters! as IFleetMonitoringParams).selectedTab = selectedTab.toLowerCase();
        return route;
    }

    private async handleOnFilterParamsChangeAsync(params: FleetMonitoringFiltersAndPagination) {
        // when there is page number, page size or sortings change, the state of the device bottom sheet shouldn't be changed; in the other cases (filters) the label should be hidden
        if (this.state.currentPageNumber === params.pageNumber && this.state.currentPageSize === params.pageSize && this.state.currentSortBy === params.sortBy && this.state.currentSortOrder === params.sortOrder) {
            this.handleOnDeviceBottomSheetVisibilityChange(false);
        } else {
            this.setState({currentPageNumber: params.pageNumber ?? 0});
            this.setState({currentPageSize: params.pageSize ?? 0});
            this.setState({currentSortBy: params.sortBy ?? ""});
            this.setState({currentSortOrder: params.sortOrder ?? ""});
        }
        const {
            companies,
            constructionSites,
            depots,
            productGroups,
            statuses,
            deviceNames,
            pageNumber,
            pageSize,
            sortBy,
            sortOrder
        } = params;

        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(
            PageDefinitions.fleetMonitoringPage.route({
                params: {
                    ...(this.state.selectedTab ? {selectedTab: this.state.selectedTab} : {}),
                    companies,
                    constructionSites,
                    depots,
                    productGroups,
                    statuses,
                    deviceNames,
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortOrder,
                }
            })
        );
    }

    private handleOnDeviceBottomSheetVisibilityChange(isVisible: boolean): void {
        this.setState({isDeviceBottomSheetVisible: isVisible});
    }

    private handleOnIsSubscribeToAlertsModalVisibilityChange(isVisible: boolean): void {
        this.setState({isSubscribeToAlertsModalOpen: isVisible});
    }

    private handleOnReturnRequestButtonEnabled(isEnabled: boolean): void {
        this.setState({isReturnRequestButtonEnabled: isEnabled});
    }

    private handleOnIsReturnRequestModalVisibilityChange(isVisible: boolean): void {
        this.setState({isReturnRequestModalOpen: isVisible});
    }

    public render(): React.ReactNode {
        let filters: FleetMonitoringFiltersAndPagination | undefined = undefined;
        if (
            this.typedParameters &&
            (Object.keys(this.typedParameters) as (keyof IFleetMonitoringParams)[])
                .filter((key) => key !== 'selectedTab') // Exclude `selectedTab` as it is not a filter, but typedParameter.
                .some((key) => !!this.typedParameters?.[key])
        ) {
            filters = new FleetMonitoringFiltersAndPagination(
                typeof this.typedParameters.companies === "string" ? JSON.parse(this.typedParameters.companies) : this.typedParameters.companies,
                typeof this.typedParameters.constructionSites === "string" ? JSON.parse(this.typedParameters.constructionSites) : this.typedParameters.constructionSites,
                typeof this.typedParameters.depots === "string" ? JSON.parse(this.typedParameters.depots) : this.typedParameters.depots,
                typeof this.typedParameters.productGroups === "string" ? JSON.parse(this.typedParameters.productGroups) : this.typedParameters.productGroups,
                typeof this.typedParameters.statuses === "string" ? JSON.parse(this.typedParameters.statuses) : this.typedParameters.statuses,
                typeof this.typedParameters.deviceNames === "string" ? JSON.parse(this.typedParameters.deviceNames) : this.typedParameters.deviceNames,
                this.typedParameters.pageNumber,
                this.typedParameters.pageSize,
                this.typedParameters.sortBy,
                this.typedParameters.sortOrder
            );
        }
        return (
            <div className={styles.fleetMonitoring}>
                <div className={styles.titleArea}>
                    <div className={styles.container}>
                        <h1>{Localizer.fleetMonitoringPageTitle}</h1>
                    </div>
                </div>

                {
                    (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagReturnRequestsList) || UnleashHelper.isEnabled(RentaEasyConstants.featureFlagAlertSourcesList)) ? (
                        <Tabs
                            tabHeaderContainerClassName={styles.tabContainer}
                            tabHeaderWrapperClassName={styles.tabWrapper}
                            tabContentWrapperClassName={styles.tabContentWrapper}
                            activeTabByLabelOrRoute={this.state.selectedTab}
                            onSelect={(_tabId: string, label: string, route: string | undefined) => this.addTabNameToRoute(route ?? label)}
                        >
                            <Tab
                                id="devicesTab"
                                label={Localizer.fleetMonitoringPageTabDevices}
                            >
                                <FleetMonitoringContainer
                                    urlParams={filters}
                                    userRoleConstructionSiteId={this.userContext.selectedConstructionSiteId}
                                    userRoleContractId={this.userContext.selectedContractId}
                                    userRoleIsAdmin={this.isAdmin}
                                    isSubscribeToAlertsModalOpen={this.state.isSubscribeToAlertsModalOpen}
                                    isReturnRequestModalOpen={this.state.isReturnRequestModalOpen}
                                    onFilterParamsChange={(params) => this.handleOnFilterParamsChangeAsync(params)}
                                    onDeviceBottomSheetVisibilityChange={(isVisible) => this.handleOnDeviceBottomSheetVisibilityChange(isVisible)}
                                    onSubscribeToAlertsModalVisibilityChange={(isVisible) => this.handleOnIsSubscribeToAlertsModalVisibilityChange(isVisible)}
                                    onReturnRequestModalVisibilityChange={(isVisible) => this.handleOnIsReturnRequestModalVisibilityChange(isVisible)}
                                    onReturnRequestBottomButtonEnableChange={(isEnabled) => this.handleOnReturnRequestButtonEnabled(isEnabled)}
                                />
                            </Tab>
                            {
                                UnleashHelper.isEnabled(RentaEasyConstants.featureFlagReturnRequestsList) &&
                                <Tab
                                    id="returnRequestsTab"
                                    label={Localizer.fleetMonitoringPageTabReturnRequests}
                                >
                                    <ReturnRequests
                                        urlParams={filters}
                                        userRoleConstructionSiteId={this.userContext.selectedConstructionSiteId}
                                        userRoleContractId={this.userContext.selectedContractId}
                                        userRoleIsAdmin={this.isAdmin}
                                        onFilterParamsChange={(params) => this.handleOnFilterParamsChangeAsync(params)}
                                    />
                                </Tab>
                            }
                            {
                                UnleashHelper.isEnabled(RentaEasyConstants.featureFlagAlertSourcesList) &&
                                <Tab
                                    id="alertSourcesTab"
                                    label={Localizer.fleetMonitoringPageTabAlertSources}
                                >
                                    <AlertSources />
                                </Tab>
                            }
                        </Tabs>
                    ) : (
                        <>
                            <div className={`${styles.tabContainer} ${styles.tabContentContainer}`}>
                                <div className={`${styles.tabWrapper} ${styles.tabContentWrapper}`}></div>
                            </div>
                            <div className={styles.tabContentContainer}>
                                <div className={styles.tabContentWrapper}>
                                    <FleetMonitoringContainer
                                        urlParams={filters}
                                        userRoleConstructionSiteId={this.userContext.selectedConstructionSiteId}
                                        userRoleContractId={this.userContext.selectedContractId}
                                        userRoleIsAdmin={this.isAdmin}
                                        isSubscribeToAlertsModalOpen={this.state.isSubscribeToAlertsModalOpen}
                                        isReturnRequestModalOpen={this.state.isReturnRequestModalOpen}
                                        onFilterParamsChange={(params) => this.handleOnFilterParamsChangeAsync(params)}
                                        onDeviceBottomSheetVisibilityChange={(isVisible) => this.handleOnDeviceBottomSheetVisibilityChange(isVisible)}
                                        onSubscribeToAlertsModalVisibilityChange={(isVisible) => this.handleOnIsSubscribeToAlertsModalVisibilityChange(isVisible)}
                                        onReturnRequestModalVisibilityChange={(isVisible) => this.handleOnIsReturnRequestModalVisibilityChange(isVisible)}
                                        onReturnRequestBottomButtonEnableChange={(isEnabled) => this.handleOnReturnRequestButtonEnabled(isEnabled)}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                <DeviceBottomSheet isVisible={this.state.isDeviceBottomSheetVisible}
                                   isReturnRequestButtonEnabled={this.state.isReturnRequestButtonEnabled}
                                   onAdjustAlertsClick={() => this.handleOnIsSubscribeToAlertsModalVisibilityChange(true)}
                                   onRequestReturnClick={() => this.handleOnIsReturnRequestModalVisibilityChange(true)}
                />
            </div>
        );
    }
}