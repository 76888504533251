import React from "react";
import {
    Button,
    ButtonType,
    EmailInput,
    EmailValidator,
    IconSize,
    ITabContainerClassNames,
    Modal,
    ModalSize,
    Tab,
    TabContainer,
    TabContainerHeaderStyleType,
    TabRenderType,
} from "@renta-apps/athenaeum-react-components";
import {AlertModel, BasePageParameters, ch, PageRoute, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {ButtonWithConfirmation, Text} from "@renta-apps/renta-react-components";
import PendingInvitationOverviewModel from "@/models/server/PendingInvitationOverviewModel";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import ServiceGrid from "@/components/Service/ServiceGrid/ServiceGrid";
import UsersGrid from "@/components/UsersGrid/UsersGrid";
import Localizer from "@/localization/Localizer";
import InvitationsGrid from "@/components/InvitationsGrid/InvitationsGrid";
import InvitationsGridToolbarModel from "../../components/InvitationsGrid/Toolbar/InvitationsGridToolbarModel";
import {assert, IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import Contracts from "@/pages/ContractDetails/Contracts/Contracts";
import ConstructionSitesList from "@/components/ConstructionSitesList/ConstructionSitesList";
import UserModel from "@/models/server/UserModel";
import GetUsersPagedListRequest from "@/models/server/Requests/GetUsersPagedListRequest";
import UsersGridToolbarModel from "@/components/UsersGrid/Toolbar/UsersGridToolbarModel";
import RentaEasyController from "@/pages/RentaEasyController";
import UserContext from "@/models/server/UserContext";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import PendingInvitationsRequest from "@/models/server/Requests/PendingInvitationsRequest";
import PageDefinitions from "@/providers/PageDefinitions";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import UnleashHelper from "@/helpers/UnleashHelper";
import styles from "./ContractDetails.module.scss";
import SetContractOrderConfirmationsRequest from "@/models/server/Requests/SetContractOrderConfirmationsRequest";
import EasyPlusInfoPanel from "@/components/EasyPlus/EasyPlusInfoPanel/EasyPlusInfoPanel";
import EasyPlusUserManagement from "@/components/EasyPlus/EasyPlusUserManagement/EasyPlusUserManagement";
import NoAccess from "@/components/NoAccess/NoAccess";
import OrdersGrid from "@/components/OrdersGrid/OrdersGrid";
import ContractEmissions from "./Emissions/ContractEmissions";
import EasyPlusSettings from "@/components/EasyPlus/EasyPlusSettings/EasyPlusSettings";
import {ContractDetailsData} from "@/models/server/ContractDetailsData";

export interface IContractDetailsParams extends BasePageParameters {

    /**
     * Contract id
     */
    id: string;

    selectedTab?: string;
}

interface IContractDetailsState {
    easyPlusAvailable: boolean;
    easyPlusValidTo: Date | null;
    selectedContractId: string,
    masterContract: boolean,
    childContracts: OrganizationContractModel[];
    organizationContract: OrganizationContractModel | null;
    isLoading: boolean;
    alertModel: AlertModel | null;
    orderConfirmationEmail: string | null;
    orderConfirmationEmailValid: boolean;
    hasAccess: boolean | null;
}

class ContractDetails extends AuthorizedPage<IContractDetailsParams, IContractDetailsState> {
    public state: IContractDetailsState = {
        easyPlusAvailable: false,
        easyPlusValidTo: null,
        selectedContractId: "",
        masterContract: false,
        childContracts: [],
        organizationContract: null,
        isLoading: true,
        alertModel: null,
        orderConfirmationEmail: null,
        orderConfirmationEmailValid: true,
        hasAccess: null,
    };

    private readonly _returnItemsInfoModalRef: React.RefObject<Modal> = React.createRef();

    // Getters

    private get isLoading(): boolean {
        return this.state.isLoading;
    }

    private get isNotLoading(): boolean {
        return !this.state.isLoading;
    }

    private get isMasterContract(): boolean {
        return this.state.masterContract;
    }

    private get selectedContractId(): string {
        return this.state.selectedContractId;
    }

    private get hasAccess(): boolean | null {
        return this.state.hasAccess;
    }

    private get contractTitle(): string {
        if (!this.state.organizationContract || !this.state.hasAccess) {
            return "";
        }

        const additionalName: string = (assert(this.state.organizationContract.additionalName).isString.isNotEmpty.isNotWhitespace.getIsSuccess)
            ? `| ${this.state.organizationContract.additionalName}`
            : ``;

        const masterContractText: string = `${this.state.organizationContract.name}  (${this.state.organizationContract.vatId})`;

        const childContractText: string = `${masterContractText} | ${this.state.organizationContract.customerNumber} ${additionalName}`;

        return (this.isMasterContract)
            ? masterContractText
            : childContractText;
    }

    protected get title(): string {
        return (this.contractTitle)
            ? this.contractTitle
            : Localizer.companies;
    }

    public get tabContainerClassNames(): ITabContainerClassNames {
        return {
            navTabs: styles.tabContainerNavTabs,
            headerLink: styles.tabContainerHeaderLink,
            navigationContainer: styles.tabContainerNavigationContainer,
            scrollableContainer: `${styles.tabContainerScrollableContainer} container`,
            tabContent: `${styles.tabContainerTabContent} container`,
        };
    }

    public get userContext(): UserContext {
        return (ch.getContext() as UserContext);
    }

    private async fetchUsersAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null, filters: UsersGridToolbarModel | null): Promise<IPagedList<UserModel>> {
        const request: GetUsersPagedListRequest = {
            sortColumnName,
            sortDirection,
            keyWord: filters ? filters.keyword : null,
            selectedRoleName: filters ? filters.selectedRoleName : null,
            selectedDepotId: null,
            contractId: this.selectedContractId,
            notProcessedUsers: filters ? filters.notProcessedUsers : false,
            pageNumber,
            pageSize
        };

        return await this.postAsync("/api/Companies/GetUsersPagedList", request);
    }

    private async getPendingInvitationsAsync(filters: InvitationsGridToolbarModel, pagesNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null)
        : Promise<IPagedList<PendingInvitationOverviewModel>> {

        const request: PendingInvitationsRequest = {
            contractId: this.selectedContractId,
            constructionSiteId: null,
            pageSize: pageSize,
            pageNumber: pagesNumber,
            sortDirection: sortDirection,
            sortColumnName: sortColumnName,
            keyWord: filters?.keyword ?? null,
            invitationSent: filters?.invitationSent ?? false,
            linkOpened: filters?.linkOpened ?? false,
            registered: filters?.registered ?? false,
        };

        return await RentaEasyController.getPendingInvitationsAsync(request, this);
    }

    private async onDeletePendingInvitationAsync(dataItem: PendingInvitationOverviewModel): Promise<void> {
        await RentaEasyController.deletePendingInvitationAsync(dataItem.invitationId, this);
    }

    private async onResendUserInvitationAsync(invitationId: string): Promise<void> {
        await RentaEasyController.resendInvitationAsync(invitationId, this);
    }

    private async onShowCustomerPrices(contractId: string): Promise<void> {
        await RentaEasyController.setOperatingForContract(contractId, this);
        await PageRouteProvider.redirectAsync(PageDefinitions.rent.route());
    }

    private async onReturnItemForCustomer(): Promise<void> {
        this._returnItemsInfoModalRef.current?.openAsync();
    }

    private renderDepotAdminLinks(): React.ReactNode {
        if (this.userContext.isAdmin && !this.isMasterContract) {
            return (
                <>
                    <Button id={"seeCustomerPricesButton"}
                            label={Localizer.contractDetailsCheckCustomerPrices}
                            type={ButtonType.Orange}
                            className={styles.newButton}
                            onClick={async () => await this.onShowCustomerPrices(this.state.selectedContractId)}
                    />
                    <Button id={"returnItemForCustomerButton"}
                            label={Localizer.contractDetailsReturnItemButtonLabel}
                            type={ButtonType.Orange}
                            className={styles.newButton}
                            onClick={async () => await this.onReturnItemForCustomer()}
                    />
                    <Button id={"makeOrderAsCustomerButton"}
                            label={Localizer.contractDetailsMakeOrderAsCustomer}
                            type={ButtonType.Orange}
                            className={styles.newButton}
                            onClick={async () => await this.onShowCustomerPrices(this.state.selectedContractId)}
                    />
                </>
            );
        }
        return null;
    }

    private renderSiteAdminLinks(): React.ReactNode {
        if (this.userContext.isAdminWithAdminRole) {
            return (
                <ButtonWithConfirmation id={"deleteContractButton"}
                                        confirmationText={Localizer.contractDetailsDeleteConfirm}
                                        confirmButtonLabel={Localizer.componentConfirmationDialogConfirmButton}
                                        cancelButtonLabel={Localizer.componentConfirmationDialogCloseButton}
                                        onClick={async () => await this.deleteContractAsync()}
                >
                    {Localizer.contractDetailsDelete}
                </ButtonWithConfirmation>
            );
        }
        return null;
    }

    private renderContractActionButtons(): React.ReactNode {
        return (
            <div className={styles.contractActionButtons}>
                {
                    this.renderDepotAdminLinks()
                }
                {
                    this.renderSiteAdminLinks()
                }
            </div>
        );
    }

    private async deleteContractAsync(): Promise<void> {
        await this.postAsync("/api/Companies/DeleteContract", this.selectedContractId);
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const contractId: string | undefined = this.typedParameters?.id;

        if (!contractId || contractId === RentaEasyConstants.emptyGuid) {
            PageRouteProvider.back();
            return;
        }

        const data: ContractDetailsData = await this.postAsync("/api/Companies/GetContractData", contractId);

        this.setState({
            isLoading: false,
            childContracts: data.organizationContract!.children,
            masterContract: data.masterContract,
            organizationContract: data.organizationContract,
            selectedContractId: data.selectedContractId,
            easyPlusAvailable: data.easyPlusEnabled,
            easyPlusValidTo: data.easyPlusValidTo,
            orderConfirmationEmail: data.organizationContract!.orderConfirmationEmail,
            orderConfirmationEmailValid: !EmailValidator.instance.validate(data.organizationContract!.orderConfirmationEmail),
            hasAccess: data.hasAccess,
        });

        document.title = this.getTitle();
    }

    private async onReturnItemInfoModalOpenAsync(): Promise<void> {
        this._returnItemsInfoModalRef.current?.openAsync();
    }

    private async onReturnItemInfoModalCloseAsync(): Promise<void> {
        this._returnItemsInfoModalRef.current?.closeAsync();
    }

    private getCurrentRouteWithTabNameAdded(selectedTab: string): PageRoute {
        const route = PageRoute.clone(this.route);
        (route.parameters! as IContractDetailsParams).selectedTab = selectedTab.toLowerCase();
        return route;
    }

    private isSelectedTabFromUrl(localizedString: string): boolean {
        return this.typedParameters?.selectedTab === localizedString.toLowerCase();
    }

    private async addTabNameToRoute(tabName: string): Promise<void> {
        await PageRouteProvider.changeUrlWithRouteWithoutReloadAsync(this.getCurrentRouteWithTabNameAdded(tabName), false);
    }

    private async setOrderConfirmationEmailAsync(value: string): Promise<void> {
        const isValid = !EmailValidator.instance.validate(value);
        this.setState({
            orderConfirmationEmail: value,
            orderConfirmationEmailValid: isValid,
        });
    }

    private async saveOrderConfirmations(): Promise<void> {
        const request: SetContractOrderConfirmationsRequest = {
            contractId: this.selectedContractId,
            email: this.state.orderConfirmationEmail,
        };
        await this.postAsync("/api/Companies/SetOrderConfirmationEmail", request);
    }

    private renderHeader(): React.ReactNode {
        if (!this.state.organizationContract || !this.state.hasAccess) {
            return <></>;
        }

        return (
            <div className={styles.headerContainer}>
                <div className={`${styles.header} container`}>
                    <div className={styles.headerTitle}>{Localizer.adminPageBusinessManagement}</div>
                    <div id="contractName" className={styles.headerContractName}>{this.state.organizationContract.name}</div>
                    <div className={styles.headerContractDetails}>
                        <div className={styles.headerContractDetailsGroup}>
                            <div className={styles.headerContractDetailsLabel}>{Localizer.contractDetailsVatId}:</div>
                            <Text id="contractVatId" tag={"span"} size={"Normal"}>
                                {this.state.organizationContract.vatId}
                            </Text>
                        </div>
                        <div className={styles.headerContractDetailsGroup}>
                            <div className={styles.headerContractDetailsLabel}>{Localizer.contractDetailsCustomerId}:</div>
                            <Text id="customerId" tag={"span"} size={"Normal"}>
                                {this.state.organizationContract.customerNumber}
                            </Text>
                        </div>
                        <div className={styles.headerContractDetailsGroup}>
                            <div className={styles.headerContractDetailsLabel}>{Localizer.contractDetailsReference}:</div>
                            <Text tag={"span"} size={"Normal"}>
                                {this.state.organizationContract.additionalName}
                            </Text>
                        </div>
                    </div>
                    {this.renderContractActionButtons()}
                </div>
            </div>
        );
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        if (this.hasAccess === false) {
            return <NoAccess type="company"/>;
        }

        return (
            <>
                <div className={styles.contractDetails}>
                    {
                        this.renderContractPricesAlert()
                    }
                    {
                        this.renderHeader()
                    }
                    <div className={styles.contractDetailsContent}>
                        {
                            (this.isNotLoading) && (
                                <TabContainer id="ContractDetailsContainer"
                                              renderType={TabRenderType.ActiveOnly}
                                              headerStyleType={TabContainerHeaderStyleType.Underline}
                                              className={styles.contractDetailsTabs}
                                              classNames={this.tabContainerClassNames}
                                >
                                    {
                                        (this.isMasterContract) && (
                                            <Tab id="detailsTab"
                                                 active={this.isSelectedTabFromUrl(Localizer.contractDetailsSubCompanies)}
                                                 onSelect={() => this.addTabNameToRoute(Localizer.contractDetailsSubCompanies)}
                                                 title={Localizer.contractDetailsSubCompanies}>
                                                {
                                                    !this.isLoading && this.isMasterContract && (
                                                        <Contracts allowEdit={this.isAdminWithAdminRole}
                                                                   contracts={this.state.childContracts}
                                                        />
                                                    )
                                                }
                                            </Tab>
                                        )
                                    }
                                    {
                                        <Tab id="sitesTab"
                                             active={this.isSelectedTabFromUrl(Localizer.constructionsites)}
                                             onSelect={() => this.addTabNameToRoute(Localizer.constructionsites)}
                                             title={Localizer.constructionsites}>
                                            {
                                                !this.isLoading && (
                                                    <ConstructionSitesList contractId={this.selectedContractId}
                                                                           masterContract={this.isMasterContract}
                                                    />
                                                )
                                            }
                                        </Tab>

                                    }
                                    <Tab id="usersTab"
                                         active={this.isSelectedTabFromUrl(Localizer.genericUsers)}
                                         onSelect={() => this.addTabNameToRoute(Localizer.genericUsers)}
                                         title={Localizer.genericUsers}>
                                        {
                                            (this.isNotLoading) && (
                                                <>
                                                    <UsersGrid canManageUsers={true}
                                                               contractId={this.selectedContractId}
                                                               fetchUsers={async (pageNumber, pageSize, sortColumnName, sortDirection, filters) =>
                                                                   await this.fetchUsersAsync(pageNumber, pageSize, sortColumnName, sortDirection, filters)}
                                                    />

                                                    <InvitationsGrid getPendingInvitationsAsync={async (filters, pagesNumber: number, pageSize: number, sortColumnName, sortDirection) =>
                                                        await this.getPendingInvitationsAsync(filters, pagesNumber, pageSize, sortColumnName, sortDirection)}
                                                                     onDeletePendingInvitationAsync={async (dataItem: PendingInvitationOverviewModel) => await this.onDeletePendingInvitationAsync(dataItem)}
                                                                     onResendUserInvitationAsync={async (invitationId: string) => await this.onResendUserInvitationAsync(invitationId)}
                                                    />
                                                </>
                                            )
                                        }
                                    </Tab>

                                    {
                                        (!this.isMasterContract) && (
                                            <Tab id="ordersTab"
                                                 active={this.isSelectedTabFromUrl(Localizer.ordersPageOrders)}
                                                 onSelect={() => this.addTabNameToRoute(Localizer.ordersPageOrders)}
                                                 title={Localizer.ordersPageOrders}
                                            >
                                                <div className={styles.ordersReturnsContainer}>
                                                    <div className={styles.confirmationEmailContainer}>
                                                        <div className={styles.confirmationEmail}>
                                                            <EmailInput id="order_emails"
                                                                        label={Localizer.companyDetailsOrderConfirmationEmail}
                                                                        value={this.state.orderConfirmationEmail}
                                                                        onChange={async (_, value) => await this.setOrderConfirmationEmailAsync(value)}
                                                            />
                                                            <Button id="order_emails_button"
                                                                    type={ButtonType.Orange}
                                                                    icon={{name: "save", size: IconSize.Normal}}
                                                                    className={styles.saveCategoryButton}
                                                                    onClick={async () => await this.saveOrderConfirmations()}
                                                                    disabled={!this.state.orderConfirmationEmailValid}
                                                            />
                                                        </div>
                                                    </div>

                                                    <OrdersGrid contractId={this.selectedContractId}
                                                                constructionSiteId={null}
                                                    />
                                                </div>
                                            </Tab>
                                        )
                                    }

                                    {
                                        (!this.isMasterContract) && (
                                            <Tab id="returnsTab"
                                                 active={this.isSelectedTabFromUrl(Localizer.breadCrumbReturns)}
                                                 onSelect={() => this.addTabNameToRoute(Localizer.breadCrumbReturns)}
                                                 title={Localizer.breadCrumbReturns}
                                            >
                                                <div className={styles.ordersReturnsContainer}>
                                                    <OrdersGrid isReturns noTitle
                                                                contractId={this.selectedContractId}
                                                                constructionSiteId={null}
                                                    />
                                                </div>
                                            </Tab>
                                        )
                                    }

                                    {
                                        (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagServicesView)) && (
                                            <Tab id="servicesTab"
                                                 active={this.isSelectedTabFromUrl(Localizer.servicesTabServicesList)}
                                                 onSelect={() => this.addTabNameToRoute(Localizer.servicesTabServicesList)}
                                                 title={Localizer.servicesTabServicesList}
                                            >
                                                <ServiceGrid organizationContractId={this.selectedContractId}
                                                             userId={null}
                                                />
                                            </Tab>
                                        )
                                    }

                                    {
                                        (!this.isMasterContract) && (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCo2Enabled)) && (
                                            <Tab id="emissionsTab"
                                                 active={this.isSelectedTabFromUrl("Co2 Emissions")}
                                                 onSelect={() => this.addTabNameToRoute("Co2 Emissions")}
                                                 title={Localizer.contractDetailsCo2Emissions}>
                                                <ContractEmissions contractId={this.selectedContractId} />
                                            </Tab>
                                        )
                                    }

                                    {
                                        UnleashHelper.isEnabled(RentaEasyConstants.featureFlagPlus) && (
                                            <Tab id={"easyPlus"}
                                                 active={this.isSelectedTabFromUrl("EasyPlus")}
                                                 onSelect={() => this.addTabNameToRoute("EasyPlus")}
                                                 title={"Easy+"}
                                            >
                                                <div className={styles.easyPlusTab}>
                                                    {
                                                        <EasyPlusInfoPanel  easyPlusEnabled={this.state.easyPlusAvailable}/>
                                                    }
                                                    {
                                                        (this.isAdmin) && (
                                                            <EasyPlusSettings contractId={this.selectedContractId} />
                                                        )
                                                    }
                                                    <EasyPlusUserManagement contractId={this.selectedContractId}
                                                                            easyPlusValidTo={this.state.easyPlusValidTo}
                                                                            easyPlusAvailable={this.state.easyPlusAvailable}
                                                                            easyPlusSubscriptionDeActivated={async () => await this.initializeAsync()}
                                                                            easyPlusSubscriptionActivated={async () => await this.initializeAsync()}
                                                                            language={Localizer.language}
                                                    />
                                                </div>
                                            </Tab>
                                        )
                                    }
                                </TabContainer>
                            )
                        }
                    </div>
                </div>

                <Modal info
                       id={"returnItemsInfoModal"}
                       ref={this._returnItemsInfoModalRef}
                       size={ModalSize.Auto}
                       onOpen={() => this.onReturnItemInfoModalOpenAsync()}
                       onClose={() => this.onReturnItemInfoModalCloseAsync()}
                       content={Localizer.contractDetailsReturnItemButtonModalInfoText}
                >
                </Modal>
            </>
        );
    }
}

export default ContractDetails;