import {useState, useEffect, useCallback} from 'react';
import {FleetMonitoringFiltersSortAndPagination} from '../ViewControl/ViewControl';
import {generateUniqueId} from '@/helpers/StringHelper';

type UseDebounceLoadOptions = {
    saveUserSelection: (filters: FleetMonitoringFiltersSortAndPagination) => void;
    loadData: (filters: FleetMonitoringFiltersSortAndPagination, requestId: string) => Promise<void>;
    initialLoad: boolean;
};

export const useDebounceLoad = (
    filtersSortAndPagination: FleetMonitoringFiltersSortAndPagination,
    currentRequestIdRef: React.MutableRefObject<string | null>,
    options: UseDebounceLoadOptions
) => {
    const { saveUserSelection, loadData, initialLoad } = options;

    const [loadTimeout, setLoadTimeout] = useState<any>(null);

    /**
     * Debounce mechanism for filtering to prevent multiple requests in case of quick changes in filtering or page settings
     * @param time - time in milliseconds to wait before submitting the request
     */
    const debounceLoadData = useCallback((time: number) => {
        loadTimeout && clearTimeout(loadTimeout);

        const requestId = generateUniqueId();
        currentRequestIdRef.current = requestId;

        const newTimeout = setTimeout(() => {
            setLoadTimeout(null);

            if (!initialLoad) {
                saveUserSelection(filtersSortAndPagination);
            }

            loadData(filtersSortAndPagination, requestId).catch();
        }, time);
        setLoadTimeout(newTimeout);
    }, [loadTimeout, filtersSortAndPagination, saveUserSelection, loadData, initialLoad]
    );

    useEffect(() => {
        debounceLoadData(initialLoad ? 0 : 1000);
    }, [filtersSortAndPagination]);
};
