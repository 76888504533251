import Localizer from "@/localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import GridHelper from "@/helpers/CypressGridHelper";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";

describe('Desktop - Admin user support request type', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        onRedirect(() =>
            executeWithIntercept(() =>
                    cy.visit(Localizer.pageRoutesAdminAddSupportRequestTypes),
                [
                    pageData().support.routes.getSupportTypes as RouteData,
                ]
            ));
    });

    it('Opens admin support request types', () => {
        GridHelper.getGridVisibleRows(pageData().support.requestTypeTableName())
            .should("have.length", 8);
    });

    it('Opens admin support request types and adds new type', () => {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#sendRequestTo", 0);

        executeWithIntercept(() => cy.get("#input_optionTitle")
                .type("Test title")
                .type('{enter}'),
            [
                pageData().support.routes.saveSupportRequestType as RouteData
            ]);

        cy.wait(200);

        GridHelper.getGridVisibleRows(pageData().support.requestTypeTableName())
            .should("have.length", 10);
    });

    it('Opens admin support request types deletes existing type', () => {
        executeWithIntercept(() =>
                GridHelper.getGridContentByRowAndCell(pageData().support.requestTypeTableName(), 0, 2)
                    .find(".fa-trash-alt")
                    .click(),
            [
                pageData().support.routes.deleteRequestType as RouteData
            ]);
    });
});