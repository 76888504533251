import Localizer from "../../../localization/Localizer";
import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";

// @ts-ignore
describe('Desktop - Authenticated construction site overview tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('Opens construction admin overview page and tests search preserving on page navigation', () => {
        executeWithIntercept(() =>  cy.visit(Localizer.pageRoutesAdminConstructionSitesOverview),
            [pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData]
        )

        cy.testConstructionSitesGridSearchPreservation('test');
    });

    it('Opens construction admin overview page with grid parameters in URL on correct page with correct sorting', () => {
        executeWithIntercept(() =>
                cy.visit(`${Localizer.pageRoutesAdminConstructionSitesOverview}?pageSize=3&pageNumber=3&sortColumn=ownerName&sortDir=0`),
            [pageData().admin.constructionSites.routes.getPagedConstructionSites as RouteData]
        )

        cy.wait(500);
        cy.get('th[data-sort-direction="0"]')
            .find('div > span')
            .should('have.text', Localizer.rolesGroupCompany);

        cy.get('.page-item.active')
            .find('a')
            .should('have.text', '3');
    });
});