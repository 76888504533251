import React, {useEffect, useMemo, useState} from 'react';
import {getConstructionSitesPowerConsumptionByExternalId, getPowerSupplyData} from '@/services/ConstructionSiteService';
import ConstructionSitePowerConsumption from "@/models/server/ConstructionSitePowerConsumption";
import RentaLineChart, {ChartData} from '@/components/Charts/LineChart/RentaLineChart';
import {Box, LoadingDecorator} from '@renta-apps/renta-react-components';
import styles from "./DailyConsumption.module.scss";
import {formatDate} from "@/helpers/DateHelper";
import Localizer from '@/localization/Localizer';
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";
import BasicChartModel from "@/models/server/BasicChartModel";

interface MaxCurrentProps {
    powerSupplyId: number;
    powerSupplyName: string;
    maxPower: number;
    constructionSiteId: string;
}

const MaxCurrent: React.FC<MaxCurrentProps> = ({powerSupplyId, constructionSiteId, powerSupplyName, maxPower}) => {
    const [data, setData] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: BasicChartModel[] =
                    await getPowerSupplyData(constructionSiteId, powerSupplyId);
                setData(result);
            } catch (error) {
                console.error('Error fetching power consumption data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [constructionSiteId]);

    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);

    return (
        <LoadingDecorator className={styles.loadingContainer} hideConentOnLoading={true} isLoading={loading}>
            {
                !easyPlusEnabled && (
                    <Box className={styles.emptyConsumptionContainer} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <span data-cy={"powerRequireEasyPlus"}>{Localizer.constructionSiteDetailsPowerEasyPlusRequired}</span>
                        <EasyPlusLink>{Localizer.easyPlusInfoPanelReadMore}</EasyPlusLink>
                    </Box>
                )
            }
            {
                (easyPlusEnabled && data.length > 0) && (
                    <RentaLineChart referenceLineValue={maxPower}
                                    referenceLineStroke={"#8884d8"}
                                    data={data}
                                    xKey="date"
                                    lines={[
                                        {
                                            dataKey: "value",
                                            dataName: Localizer.constructionSiteDetailsPowerMaxCurrentCurrentTooltip,
                                            stroke: "#ff7300",
                                            strokeWidth: 3,
                                            gradientId: "lineGradient",
                                            type: "linear",
                                        }
                                    ]}
                                    title={powerSupplyName}
                                    subtitle={`${formatDate(data[data.length - 1]?.date)}`}
                                    legendItems={[
                                        {label: Localizer.constructionSiteDetailsPowerMaxCurrentChartLegend, color: "#FE5000"},
                                        {label: Localizer.constructionSiteDetailsPowerMaxCurrentDeviceMaxLimitChartLegend, color: "#116CEA"},
                                    ]}
                                    xAxisFormatter={(date) => {
                                        const parsedDate = new Date(date);
                                        return `${parsedDate.getDate().toString().padStart(2, '0')}.${(parsedDate.getMonth() + 1)
                                            .toString()
                                            .padStart(2, '0')}`;
                                    }}
                                    brush={true}
                    />
                )
            }
            {
                (easyPlusEnabled && data.length === 0) && (
                    <Box className={styles.emptyConsumptionContainer} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <span data-cy={"alertsRequireEasyPlus"}>{Localizer.genericNoData}</span>
                    </Box>
                )
            }
        </LoadingDecorator>
    );
};

export default MaxCurrent;