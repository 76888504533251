// @ts-ignore
import Localizer from "@/localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

context("(Desktop) Construction site page: Emissions tab", () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.intercept('POST', pageData().company.routes.getEquipmentEmissionData.path, {fixture: 'companyDetailsEmissions.json'});

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
                [pageData().company.routes.getContractData as RouteData]
            );
        });

    });

    it("Open emissions tab and verify chart renders", () => {

        executeWithIntercept(() =>
                pageData().company.emissions.tab().click(),
            [pageData().company.routes.getEquipmentEmissionData as RouteData]
        );

        pageData().company.emissions.infoBox()
            .should('be.visible')
            .children('div')
            .should('have.length', 3);

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 17);

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(1)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 34);


        pageData().company.emissions.infoBox()
            .children('div')
            .eq(2)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 1100.5);
    });

});

context("Non-easy plus user context", () => {

    beforeEach(() => {
        cy.session(['company_role_session_no_plus_ever'], () => {
            cy.loginAndSelectCompanyRole("Matti.Salmiakki@weare.fi", "tmi jukka wilska");
        });

        cy.intercept('POST', pageData().company.routes.getEquipmentEmissionData.path, {fixture: 'companyDetailsEmissions.json'});

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesContractDetails}?id=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
                [pageData().company.routes.getContractData as RouteData]
            );
        });
    });

    it("Open emissions tab and verify chart renders", () => {

        executeWithIntercept(() =>
                pageData().company.emissions.tab().click(),
            [pageData().company.routes.getEquipmentEmissionData as RouteData]
        );

        pageData().company.emissions.infoBox()
            .should('be.visible')
            .children('div')
            .should('have.length', 3);

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 17);

        pageData().company.emissions.infoBox()
            .children('div')
            .eq(1)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 34);


        pageData().company.emissions.infoBox()
            .children('div')
            .eq(2)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 1100.5);
    });

    describe('feature flag tests', () => {

        it("Open emissions tab and verify Easy Plus notice", () => {

            pageData().common.interceptFeatureFlags([
                { flagName: RentaEasyConstants.featureFlagCo2Enabled, enabled: true },
                { flagName: RentaEasyConstants.featureFlagFeatureCo2RequiresPlus, enabled: true}
            ]);

            cy.reload();
            pageData().common.waitTopNavRender();

            pageData().company.emissions.tab().click();

            cy.url().should('contain', `selectedTab=co2%20emissions`);

            cy.get('div[data-cy="easyPlusTitle"]').should('contain', Localizer.easyPlusFeatureTitle);

            cy.get('div[data-cy="easyPlusText"]').should('contain', Localizer.easyPlusNewSubscriptionInfo);

            cy.get('div[data-cy="easyPlusText"]').should('contain', Localizer.easyPlusNewSubscriptionInfo);

        });

    });

});
