import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Filters construction site tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('should display construction site dropdown for admin', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.constructionSites.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        // Select company first
        DeviceGridHelper.getDropdown("filter-by-company-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsCompany);

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").should("be.visible");

        DeviceGridHelper.getDropdown("filter-by-company-dropdown").click();

        DeviceGridHelper.typeDropdownInput("filter-by-company-dropdown", "Unit test6 Oy");

        DeviceGridHelper.clickDropdownListItem("filter-by-company-dropdown", 1);

        // Select construction site
        DeviceGridHelper.getDropdown("filter-by-construction-site-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelsConstructionSite);

        DeviceGridHelper.getDropdown("filter-by-construction-site-dropdown").should("be.visible");

        DeviceGridHelper.getDropdown("filter-by-construction-site-dropdown").click();

        DeviceGridHelper.typeDropdownInput("filter-by-construction-site-dropdown", "con");

        DeviceGridHelper.clickDropdownListItem("filter-by-construction-site-dropdown", 0);

        DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

        DeviceGridHelper.checkTags(["Unit test Oy (13579)","ConstructionSite4"]);

        cy.wait('@postRequestFiltered').then(({ request }) => {
            expect(request.body).to.have.nested.property('companies')
            expect(request.body.companies).to.have.length(1);
            expect(request.body.companies[0].name === 'Unit test Oy');
            expect(request.body).to.have.nested.property('constructionSites')
            expect(request.body.constructionSites).to.have.length(1);
            expect(request.body.constructionSites[0].name === 'ConstructionSite4');
            expect(request.body.constructionSites[0].id === 'ab697343-2901-4c73-97a4-f984d831b813');
        });
    });

    it('should set construction site dropdown with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.constructionSites.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({ fixture: 'fleetGetReturnsPagedList.json' });
        });

        cy.visit(`/fleet-monitoring?companies=${JSON.stringify([{name:"Unit test6 Oy (777777)"}])}&constructionSites=${JSON.stringify([{name:"ConstructionSite2"}])}&selectedTab=palautuspyynn%C3%B6t`);

        DeviceGridHelper.checkTags(["Unit test6 Oy (777777)","ConstructionSite2"]);

        cy.wait('@postRequestFiltered').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-construction-site-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.checkTags(["Unit test6 Oy (777777)","ConstructionSite2"]);
        });
    });
});