import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";
import Localizer from "../../../localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";

describe('Desktop - Service preview tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
        cy.visit(Localizer.pageRoutesServices);
    });

    it('Opens service preview, test mandatory checkbox and image uploads', () => {

        cy.contains("Mandatory fields test").click();

        cy.get("#submitServiceDefinitionButton").should('be.disabled');

        // checkbox
        pageData().serviceRequest.getElementByIndex(0).find("i").click();
        cy.get("#submitServiceDefinitionButton").should('be.disabled');

        // multiselect file upload
        pageData().serviceRequest.getElementByIndex(1).find("input[type=file]")
            .first()
            .selectFile([
                {
                    contents: 'cypress/fixtures/test_picture_large.png',
                    fileName: 'test_picture_large.png',
                    mimeType: 'data:image/png',
                    lastModified: Date.now(),
                },
                {
                    contents: 'cypress/fixtures/test_picture_small.png',
                    fileName: 'test_picture_small.png',
                    mimeType: 'data:image/png',
                    lastModified: Date.now(),
                }
            ], {force: true});

        pageData().serviceRequest.getElementByIndex(1).find(".athenaeum-file-input-selectedFile").should('have.length', 2);
        cy.get("#submitServiceDefinitionButton").should('be.disabled');

        // single file upload
        pageData().serviceRequest.getElementByIndex(2).find("input[type=file]")
            .first()
            .selectFile({
                contents: 'cypress/fixtures/test_picture_small.png',
                fileName: 'test_picture_small.png',
                mimeType: 'data:image/png',
                lastModified: Date.now(),
            }, {force: true});

        cy.get("#submitServiceDefinitionButton").should('not.be.disabled');

        // remove one of the files from multiselect
        pageData().serviceRequest.getElementByIndex(1).find('.athenaeum-file-input-selectedFile').first().find("i").click();
        pageData().serviceRequest.getElementByIndex(1).find('.athenaeum-file-input-selectedFile').should('have.length', 1);

        cy.get("#submitServiceDefinitionButton").should('not.be.disabled');
    });

    it('Opens service preview, fills inputs and sends', () => {

        cy.contains("All inputs").click();

        const inputText1 = "Text for text";
        const inputText2 = "Text for text area";
        const inputText3 = "Text for text on second page";
        const inputNumber1 = "14";
        const inputNumber2 = "15";
        const inputNumber3 = "16";
        const inputNumber4 = "17";

        cy.get("#input_service_request_input_1").type(inputText1);
        cy.get("#input_service_request_input_2").type(inputText2);
        cy.get("#input_service_request_input_3").click();
        cy.get(".react-datepicker__month")
            .contains(inputNumber1)
            .click();

        cy.get(".athenaeum-date-range-input-input").click();
        cy.get(".athenaeum-date-range-input-dateRangeInputExpanded")
            .contains(inputNumber2)
            .click();
        cy.get(".athenaeum-date-range-input-dateRangeInputExpanded")
            .contains(inputNumber3)
            .click();

        cy.get("#input_service_request_input_5").clear().type(inputNumber4);

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_6", 0);

        cy.get("#service_request_input_7")
            .find("i")
            .click();
        cy.get("#AddressInput_within_location_service_request_input_8")
            .find("input")
            .focus()
            .type("Kauppurienkatu 5, 90100 Oulu");
        cy.wait(1500);
        cy.get("#AddressInput_within_location_service_request_input_8")
            .type("{downarrow}{enter}");
        cy.wait(1500);

        // Skipping file input for now

        cy.get("#nextGroupButton").click();
        cy.get("#service_request_input_10").type(inputText3);

        cy.intercept("POST", "/api/Services/CreateCustomServiceRequest", (req) => {
            expect(req.body.inputGroups.length).to.equal(2);
            const group1 = req.body.inputGroups[0];
            const group2 = req.body.inputGroups[1];
            expect(group1.inputs.length).to.equal(9);
            expect(group2.inputs.length).to.equal(1);

            expect(group1.inputs[0].selectedValue).to.equal(inputText1);
            expect(group1.inputs[1].selectedValue).to.equal(inputText2);
            expect(group1.inputs[2].selectedValue).to.contains(inputNumber1);
            expect(group1.inputs[3].selectedValue).to.contains(inputNumber2).and.to.contains(inputNumber3);
            expect(group1.inputs[4].selectedValue).to.equal(inputNumber4);
            expect(group1.inputs[5].selectedValue).to.equal("Value 0");
            expect(group1.inputs[6].selectedValue).to.equal("true");
            expect(group1.inputs[7].selectedValue).to.contains("Kauppurienkatu 5");
            expect(group2.inputs[0].selectedValue).to.equal(inputText3);
        }).as("createCustomServiceRequest");

        cy.get("#submitServiceDefinitionButton").click();

        cy.wait("@createCustomServiceRequest").its('response.statusCode').should('equal', 200);
    });

    it('Opens service preview and checks that conditions work', () => {

        cy.contains("Conditions test").click();

        cy.get("#service_request_input_5")
            .should('not.exist');

        cy.get("#service_request_input_1")
            .find("i")
            .click();

        cy.get("#service_request_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_2", 0);

        cy.get("#service_request_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_3", 1);

        cy.get("#service_request_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_4", 0);

        cy.get("#service_request_input_5")
            .should('be.visible');

        cy.get("#service_request_input_1")
            .find("i")
            .click();

        cy.get("#service_request_input_5")
            .should('not.exist');

        cy.get("#service_request_input_1")
            .find("i")
            .click();

        cy.get("#service_request_input_5")
            .should('be.visible');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_3", 0);

        cy.get("#service_request_input_5")
            .should('not.exist');

        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#service_request_input_3", 1);

        cy.get("#service_request_input_5")
            .should('be.visible');
    });
});