import React, {useEffect, useState} from "react";
import styles from "./DeviceDetails.module.scss";
import {DeviceDetailedModel} from "@/models/server/DeviceDetailedModel";
import {getDeviceExtendedDetailsAsync} from "@/services/FleetService";
import DeviceDetailsHeader from "@/pages/DeviceDetailsPage/components/DeviceDetailsHeader";

import DeviceDetailsLocation from "@/pages/DeviceDetailsPage/components/DeviceDetailsLocation";
import DeviceDetailsAlerts from "@/pages/DeviceDetailsPage/components/DeviceDetailsAlerts";
import DeviceDetailsDocuments from "@/pages/DeviceDetailsPage/components/DeviceDetailsDocuments";
import DeviceDetailsUsage from "@/pages/DeviceDetailsPage/components/DeviceDetailsUsage";
import DeviceDetailsContext from "@/pages/DeviceDetailsPage/DeviceDetailsContext";
import {IDeletedAlarm, INewAlarm} from "../FleetMonitoring/FleetMonitoringContainer";

interface IDeviceDetailsProps {
    rentaId: string;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
}

const DeviceDetails: React.FC<IDeviceDetailsProps> = (props: IDeviceDetailsProps) => {
    const [deviceDetails, setDeviceDetails] = useState<DeviceDetailedModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadDeviceDetails = async (rentaId: string): Promise<void> => {
        try {
            setIsLoading(true);
            // Admin can see device alerts from every organization and construction site. Other users have a specific role, so their search is narrowed.
            const constructionSiteId = (props.userRoleIsAdmin || !props.userRoleConstructionSiteId) ? undefined : props.userRoleConstructionSiteId;
            const contractId = (props.userRoleIsAdmin || !props.userRoleContractId) ? undefined : props.userRoleContractId;

            const response = await getDeviceExtendedDetailsAsync(constructionSiteId, contractId, rentaId);
            if (response) {
                setDeviceDetails(response);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    useEffect(() => {
        loadDeviceDetails(props.rentaId).catch();
    }, []);

    const removeDeletedAlarms = (deletedAlarms: IDeletedAlarm[]) => {
        setDeviceDetails(prevDeviceDetails => {
            if (!prevDeviceDetails) {
                return prevDeviceDetails;
            }

            return {
                ...prevDeviceDetails,
                configuredAlerts: prevDeviceDetails.configuredAlerts.filter(alert =>
                    !deletedAlarms.some(deleted => deleted.alarmId === alert.id)
                )
            };
        });
    };

    const saveNewAlarms = (newAlarms: INewAlarm[]) => {
        setDeviceDetails(prevDeviceDetails => {
            if (!prevDeviceDetails) {
                return prevDeviceDetails;
            }

            return {
                ...prevDeviceDetails,
                configuredAlerts: [
                    ...prevDeviceDetails.configuredAlerts,
                    ...newAlarms.map(({configuredAlert}) => configuredAlert)
                ]
            };
        });
    };

    return (
        <DeviceDetailsContext.Provider value={{
            serialNumber: deviceDetails?.trackUnitSerialNumber,
            rentaId: props.rentaId,
            rentaGroup: deviceDetails?.rentaGroup ?? null,
            rentaGroupId: deviceDetails?.rentaGroupId ?? null,
            userRoleConstructionSiteId: props.userRoleConstructionSiteId,
            userRoleContractId: props.userRoleContractId,
            userRoleIsAdmin: props.userRoleIsAdmin,
            deviceDetails: deviceDetails,
            removeDeletedAlarms: (deletedAlarms: IDeletedAlarm[]) => removeDeletedAlarms(deletedAlarms),
            saveNewAlarms: (newAlarms: INewAlarm[]) => saveNewAlarms(newAlarms),
        }}>
            <div className={styles.deviceDetailsPageContainer}>
                <DeviceDetailsHeader/>

                <div id="container" className={styles.container}>
                    <div className={styles.sideBySide}>
                        <DeviceDetailsLocation isLoading={isLoading}/>

                        <DeviceDetailsAlerts isLoading={isLoading}/>
                    </div>

                    <DeviceDetailsUsage/>

                    <DeviceDetailsDocuments/>
                </div>
            </div>
        </DeviceDetailsContext.Provider>
    );
};

export default DeviceDetails;