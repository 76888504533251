import React, {useCallback, useEffect, useRef, useState} from "react";
import {Modal, ModalSize} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import styles from "./AuthenticationModal.module.scss";
import {useResize} from "@/helpers/Hooks";
import UserStrongAuthentication from "@/models/server/Responses/UserStrongAuthentication";
import {getUserStrongAuthenticationAsync, removeUserStrongAuthenticationAsync} from "@/services/AdminService";
import {Button, Icon} from "@renta-apps/renta-react-components";

interface EditSiteLocationModalProps {
    userEmail: string;
    onClose(): void;
    onUserAuthenticationRemovedAsync?(): Promise<void>;
}

const AuthenticationModal: React.FC<EditSiteLocationModalProps> = ({userEmail, onClose, onUserAuthenticationRemovedAsync}) => {
    const _modalRef: React.RefObject<Modal> = useRef(null);
    const [data, setData] = useState<UserStrongAuthentication | null>(null);
    const [removing, setRemoving] = useState(false);
    const [error, setError] = useState(false);
    const {windowWidth} = useResize();

    const loadData = useCallback(async () => {
        try {
            const strongAuth = await getUserStrongAuthenticationAsync(userEmail);
            setData(strongAuth);
        } catch (error) {
            console.error('Error loading strong authentication: ', error);
        }
    }, [userEmail]);

    useEffect(() => {
        if (!_modalRef.current) {
            return;
        }

        loadData().catch();
        const modalRefLocal = _modalRef.current;
        modalRefLocal?.openAsync();

        return () => {
            modalRefLocal?.closeAsync();
        };
    }, [_modalRef, loadData]);
    
    const removeStrongAuthentication = async () => {
        try {
            setRemoving(true);
            setError(false);
            const success = await removeUserStrongAuthenticationAsync(userEmail);
            if (success) {
                onClose();
                await onUserAuthenticationRemovedAsync?.();
            } else {
                setError(true);
            }
        } catch (error) {
            setError(true);
            console.error('Error removing strong authentication: ', error);
        } finally {
            setRemoving(false);
        }
    };

    return (
        <Modal id="user-authentication-modal"
               ref={_modalRef}
               preventEsc
               preventClosingOnInsideClick
               preventClosingOnOutsideClick
               size={ModalSize.Default}
               title={Localizer.usersGridAuthenticationModalTitle}
               className={styles.modal}
               onClose={async () => onClose()}
               notResponsive={windowWidth > 480}
        >
            <div className={styles.modalContent}>
                <div className={styles.contentBox}>
                    <div className={styles.textContent}>
                        <div className={styles.title}>{Localizer.usersGridAuthenticationModalHeader}</div>
                        <div className={styles.description}>{Localizer.usersGridAuthenticationModalDescription}</div>
                    </div>
                    <div className={styles.textContent}>
                        <div className={styles.header}>{Localizer.genericName}</div>
                        {data ? (
                            <div className={styles.baseText}>{data?.userName}</div>
                        ) : (
                            <div className={styles.baseText}><Icon name="fa-solid fa-circle-notch fa-spin" size={16} color="#fe5000"/></div>
                            )}
                        </div>
                    <div className={styles.textContent}>
                        <div className={styles.header}>{Localizer.usersGridAuthenticationModalIdentificationNumber}</div>
                        {data ? (
                            <div className={styles.baseText}>{data?.identificationNumber}</div>
                        ) : (
                            <div className={styles.baseText}><Icon name="fa-solid fa-circle-notch fa-spin" size={16} color="#fe5000"/></div>
                            )}
                        </div>
                </div>
                <div className={styles.contentBox}>
                    <div className={styles.title}>{Localizer.usersGridAuthenticationModalActions}</div>
                    {removing ? (
                        <div className={styles.pleaseWait}>
                            <div><Icon name="fa-solid fa-circle-notch fa-spin" size={24} color="#fe5000"/></div>
                            <div>{Localizer.fleetMonitoringPageSubscribeToAlertsModalPleaseWait}</div>
                        </div>
                    ) : (
                        <Button onClick={removeStrongAuthentication} disabled={!data}>{Localizer.usersGridAuthenticationModalRemoveAuthentication}</Button>
                    )}
                    {error && <div className={styles.error}>{Localizer.usersGridAuthenticationModalCouldNotRemove}</div>}
                </div>
                <div className={styles.closeButton}>
                    <Button onClick={onClose}>{Localizer.genericClose}</Button>
                </div>
            </div>
        </Modal>
);
};

export default AuthenticationModal;