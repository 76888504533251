describe('Create a pricing plan', () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.visit('salestools/pricingtool')
    });

    const currentDate = new Date().toLocaleDateString();

    it('Open Discounts view and verify available items', () => {

        cy.get('#pricingToolDiscounts').click()

        selectContract()

        // check number of discounts
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 2)

        // select construction site
        cy.get('#SelectConstructionSiteDD').click()
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // check number of discounts
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 2)

        // select construction site
        cy.get('#SelectConstructionSiteDD').click()
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-item:nth-child(2) span').click()

        // check number of discounts
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 0)

    });

    it('Open Discounts view, create new discount', () => {

        cy.get('#pricingToolDiscounts').click()

        selectContract()

        // select construction site
        cy.get('#SelectConstructionSiteDD').click()
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-item:nth-child(2) span').click()

        // add discount
        cy.get('#AddNewDiscountBtn').click()
        cy.get('#AddNewDiscountModal #input_pricingToolNewDiscountName').click()
        cy.get('#AddNewDiscountModal #input_pricingToolNewDiscountName').type('aaa test discount')
        cy.get('#AddNewDiscountModal #input_pricingToolNewDiscountName').type('{Enter}')

        // check if discount was added
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 1)
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'aaa test discount')

    })

    it('Open Discounts view, make duplicate, delete the duplicate', () => {

        cy.get('#pricingToolDiscounts').click()

        selectContract()

        // select construction site
        cy.get('#SelectConstructionSiteDD').click()
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-item:nth-child(2) span').click()

        // select discount
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // duplicate discount
        cy.get('#DuplicateDiscountBtn').click()
        cy.get('#DuplicateDiscountModal #input_pricingToolNewDiscountName').click()
        cy.get('#DuplicateDiscountModal #input_pricingToolNewDiscountName').type('aaa test discount 2')
        cy.get('#DuplicateDiscountModal #input_pricingToolNewDiscountName').type('{Enter}')

        // check if discount was duplicated
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 2)
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'aaa test discount 2')
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // delete discount
        cy.get('#DeleteDiscountBtn').click()
        cy.get('#confirmDeleteDiscountButton').click()

        // check if discount was deleted
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 1)
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').click()

    })

    it('Open Discounts view, go to discount edit view and delete the discount', () => {

        cy.get('#pricingToolDiscounts').click()

        selectContract()

        // select construction site
        cy.get('#SelectConstructionSiteDD').click()
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 4)
        cy.get('#SelectConstructionSiteDD .athenaeum-dropdown-item:nth-child(2) span').click()

        // select discount
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // go to discount edit view
        cy.get('#EditDiscountBtn').click()

        // check if discount edit view is opened
        cy.get('#organizationContractName > div:nth-child(2)').should('contain.text', 'Unit test6 Oy')
        cy.get('#constructionSiteName > div:nth-child(2)').should('contain.text', 'ConstructionSite2')
        cy.get('#discountName > div:nth-child(2)').should('contain.text', 'aaa test discount')
        cy.get('#overallDiscuntPercentage > div:nth-child(2)').should('contain.text', '0 %')
        cy.get('#discountTimeLimitStart > div:nth-child(2)').should('contain.text', '-')
        cy.get('#discountTimeLimitEnd > div:nth-child(2)').should('contain.text', '-')
        cy.get('#statusLogContainer > div > div:nth-child(1)').should('contain.text', 'Draft')
        cy.get('#statusLogContainer > div > div:nth-child(2)').should('contain.text', currentDate)
        cy.get('#statusLogContainer > div > div:nth-child(3)').should('contain.text', 'juhani.sihvonen@weare.fi')

        // go back to templates view
        cy.get('#breadCrumb-container #bcItems :nth-child(6)').click()

        selectContract()

        // check if template was duplicated
        cy.get('#SelectDiscountDD').click()
        cy.get('#SelectDiscountDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 3)
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'aaa test discount')
        cy.get('#SelectDiscountDD .athenaeum-dropdown-item:nth-child(1) span').click()

        // delete template
        cy.get('#DeleteDiscountBtn').click()
        cy.get('#confirmDeleteDiscountButton').click()
    })

    function selectContract() {

        // select organization contract
        cy.get('#SelectContractDD').click()
        cy.get('#SelectContractDD #filter_inputSelectContractDD').click()
        cy.get('#SelectContractDD #filter_inputSelectContractDD').type('unit test6 oy')
        cy.get('#SelectContractDD #filter_inputSelectContractDD').type('{Enter}')
        cy.get('#SelectContractDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 1)
        cy.get('#SelectContractDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'Unit test6 Oy')
        cy.get('#SelectContractDD .athenaeum-dropdown-item:nth-child(1) span').click()
    }

});