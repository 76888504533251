import React, {FC, useState} from "react";
import styles from "@/pages/ShoppingCart/ShoppingCart.module.scss";
import Localizer from "@/localization/Localizer";

import {PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    Spinner,
} from "@renta-apps/athenaeum-react-components";
import RentaEasyController from "@/pages/RentaEasyController";
import PageDefinitions from "@/providers/PageDefinitions";
import OrderDetails from "@/models/server/OrderDetails";
import UserContext from "@/models/server/UserContext";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import {InvitedUser} from "@/models/server/InviteUserPostRequest";
import {IResponse, IShoppingCartState} from "@/pages/ShoppingCart/ShoppingCart";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import shoppingCartService from "@/services/ShoppingCartService";

type ShoppingCartFormButtonsProps = {
    isSpinning: boolean;
    assertIsValid: () => Promise<boolean>;
    order: OrderDetails;
    setHasDiscounts: (discountedProductExternalIds: string[]) => Promise<void>;
    userContext: UserContext;
    inviteUserRequest: InviteNewUserRequest | null;
    contractId: string | null;
    contractDiscounts: OrganizationContractDiscounts | null;
    updateState: (state: Partial<IShoppingCartState>) => void;
    unavailableProductIds: string[];
    shoppingCartProducts: ShoppingCartProductModel[];
    requireStrongAuthentication: boolean;
    promptAuthentication: () => Promise<boolean | void>;
    confirmAsync: (message: string) => Promise<boolean>;
}

const ShoppingCartFormButtons: FC<ShoppingCartFormButtonsProps> = (props: ShoppingCartFormButtonsProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const clearShoppingCartAsync = async (): Promise<void> => {
        const confirmed = await props.confirmAsync(Localizer.shoppingCartPageClearConfirmation);

        if (confirmed) {
            await RentaEasyController.clearShoppingCartAsync(this);
            await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
        }
    }

    const redirectToRentPageAsync = async () => {
        await PageRouteProvider.redirectAsync(PageDefinitions.rent.route());
    }

    const sendOrderToEmailAsync = async (): Promise<void> => {
        const isValid: boolean = await props.assertIsValid();

        if (!isValid) {
            return;
        }
        setIsLoading(true);
        await shoppingCartService.sendOrderToEmailAsync(props.order);
        setIsLoading(false);
    }

    const askForQuoteAsync = async (): Promise<void> => {

        const isValid: boolean = await props.assertIsValid();

        if (!isValid) {
            return;
        }

        if (props.contractDiscounts?.productSpecificPrices?.length) {

            const discountedProductExternalIds: string[] = props.contractDiscounts?.productSpecificPrices?.where(
                    product => !!product.externalId
                )
                .map(
                    product => product.externalId!
                )
                ?? [];

            await props.setHasDiscounts(discountedProductExternalIds);
        }

        const confirmed = await props.confirmAsync(Localizer.shoppingCartPageSendQuoteRequest);

        if (confirmed) {
            setIsLoading(true);
            shoppingCartService.askForQuoteAsync(props.order, props.userContext.temporaryContractIdForPrices ?? props.contractId);
            setIsLoading(false);
        }
    }

    const hasUnavailableProducts = (): boolean => {
        return (props.unavailableProductIds?.length > 0) && props.shoppingCartProducts.some(
                    shoppingCartroduct => props.unavailableProductIds.some(
                        unavailableProductId => shoppingCartroduct.product?.id === unavailableProductId
                    )
                );
    }

    const sendOrderAsync = async (): Promise<void> => {

        if (UnleashHelper.isEnabled(RentaEasyConstants.featureFlagSsoLogin) && props.requireStrongAuthentication) {
            await props.promptAuthentication();
            return;
        }

        const isValid: boolean = await props.assertIsValid();

        if (!isValid) {
            return;
        }

        const confirmationMessage: string = (hasUnavailableProducts())
            ? `${Localizer.shoppingCartPageSendOrderConfirmation}\n\n${Localizer.shoppingCartPageSendOrderConfirmationBody}\n\n${Localizer.shoppingCartPageOrderProcessInfo}`
            : `${Localizer.shoppingCartPageSendOrderConfirmation}\n\n${Localizer.shoppingCartPageOrderProcessInfo}`;

        const confirmed = await props.confirmAsync(confirmationMessage);

        if (confirmed) {
            const order = {
                ...props.order,
                invitedUserFirstName: props.inviteUserRequest?.firstName ?? "",
                invitedUserLastName: props.inviteUserRequest?.lastName ?? "",
                invitedUserEmail: props.inviteUserRequest?.email ?? "",
                invitedUserTelephone: props.inviteUserRequest?.telephone ?? ""
            };

            setIsLoading(true);
            const orderId: string | null = await shoppingCartService.sendOrderAsync(order);
            setIsLoading(false);

            // Invite user
            const request: InviteNewUserRequest | null =  props.inviteUserRequest;

            let inviteUserResponse: IResponse | null = null;

            if (request)
            {
                const users: InvitedUser[] = [];
                users.push({
                    email: request.email,
                    telephone: request.telephone,
                    lastName: request.lastName,
                    firstName: request.firstName,
                });

                setIsLoading(true);
                inviteUserResponse = await shoppingCartService.inviteUsersAsync(users, request.contractId, request.accessToAllConstructionSites, request.companyMainUserRole, request.constructionSiteAccesses);
                setIsLoading(false);

                props.updateState({inviteUserRequest: null, inviteUserResponse});
            }

            if (!orderId) {
                return;
            }

            await PageRouteProvider.redirectAsync(PageDefinitions.order.route({
                id: orderId,
                params: {
                    orderConfirmation: true,
                    inviteUserConfirmation: inviteUserResponse,
                }
            }));
        }
    }


    return (
        <div className={styles.buttonContainer}>
            {isLoading && (
                <div className={styles.loading}>
                    <Spinner global />
                </div>
            )}

            <Button label={Localizer.shoppingCartPageAddProducts}
                    minWidth={"100%"}
                    type={ButtonType.Default}
                    disabled={props.isSpinning || isLoading}
                    onClick={async () => await redirectToRentPageAsync()}
            />

            <Button label={Localizer.shoppingCartPageSendOrderToYourEmail}
                    minWidth={"100%"}
                    type={ButtonType.Default}
                    disabled={props.isSpinning || isLoading}
                    onClick={async () => await sendOrderToEmailAsync()}
            />

            <Button label={Localizer.shoppingCartPageAskForQuote}
                    minWidth={"100%"}
                    type={ButtonType.Default}
                    disabled={props.isSpinning || isLoading}
                    onClick={async () => await askForQuoteAsync()}
            />

            <Button submit
                    id={"shopping_cart_submit"}
                    label={Localizer.shoppingCartPageSendOrder}
                    minWidth={"100%"}
                    type={ButtonType.Primary}
                    disabled={props.isSpinning || isLoading}
                    onClick={async () => await sendOrderAsync()}
            />

            <Button label={Localizer.shoppingCartPageClear}
                    minWidth={"100%"}
                    type={ButtonType.Danger}
                    disabled={props.isSpinning || isLoading}
                    onClick={async () => await clearShoppingCartAsync()}
            />
        </div>
    );
}

export default ShoppingCartFormButtons;