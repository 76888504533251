import React, {useEffect, useRef, useState} from "react";
import styles from "./InviteUserForm.module.scss";
import InviteNewUserRequest from "@/models/server/Requests/InviteNewUserRequest";
import {Button, ButtonContainer, ButtonType, Checkbox, Dropdown, DropdownRequiredType, DropdownSelectType, EmailInput, EmailValidator, Form, InlineType, OneColumn, PageRow, TextInput, TwoColumns,} from "@renta-apps/athenaeum-react-components";
import InviteUserPostRequest, {IConstructionSiteAccess, InvitedUser} from "@/models/server/InviteUserPostRequest";
import Localizer from "@/localization/Localizer";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {validatePhoneNumber} from "@/helpers/Validators";
import {ch} from "@renta-apps/athenaeum-react-common";
import InviteUserPageDataResponse from "@/models/server/Responses/InviteUserPageDataResponse";
import UserContext from "@/models/server/UserContext";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import {Dropdown as DropdownNew, DropdownMode, IDropdownItem} from "@renta-apps/renta-react-components";
import UserInvitationModel from "@/models/server/UserInvitationModel";
import {searchUsersAsync} from "@/services/UserService";

type InviteUserFormProps = {
    contractId: string,
    constructionSiteId?: string | null,
    data: InviteUserPageDataResponse,
    multiple?: boolean;
    displayCancelButton?: boolean;
    displaySaveButtonIcon?: boolean;
    singleColumn?: boolean;
    sending?: boolean;
    saveButtonText: string;
    onSave: (request: InviteUserPostRequest) => Promise<void>;
    onCancel?: () => Promise<void>;
};


const InviteUserForm: React.FC<InviteUserFormProps> = (props: InviteUserFormProps) => {
    const newUserOption = {name: Localizer.inviteUserNewUser, value: "NEW_USER"};

    const [usersList, setUsersList] = useState<InvitedUser[]>([]);
    const [newUser, setNewUser] = useState<InvitedUser>(new InvitedUser(RentaEasyConstants.telephoneCountryCode));
    const [data, setData] = useState<InviteUserPageDataResponse | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [existingUsers, setExistingUsers] = useState<UserInvitationModel[]>([]);
    const [existingUsersOptions, setExistingUsersOptions] = useState<IDropdownItem[]>([newUserOption]);
    const [selectedExistingUser, setSelectedExistingUser] = useState<IDropdownItem | undefined>(undefined);
    const [constructionSiteAccessInput, setConstructionSiteAccessInput] = useState<IConstructionSiteAccess[]>([]);
    const [isConstructionSiteUser, setIsConstructionSiteUser] = useState<boolean>(false);
    const firstNameRef = useRef<EmailInput>(null);

    useEffect(() => {
        if (props.data) {
            setData({
                ...props.data,
                constructionSiteAccesses: props.data.constructionSiteAccesses.map(item => ({
                    ...item,
                    group: item.isOpen ? Localizer.constructionSitesActive : Localizer.constructionSitesNonActive,
                })),
            });

            const selectedSite: IConstructionSiteAccess | undefined = props.data.constructionSiteAccesses.find(site => (site.id === props.constructionSiteId));
            if (selectedSite) {
                setConstructionSiteAccessInput([selectedSite]);
            }
        }

        setIsConstructionSiteUser((ch.getContext() as UserContext)!.isConstructionSiteUser);
    }, [props.data, props.constructionSiteId]);

    const addUserToList = async (): Promise<InvitedUser[] | null> => {
        const isUserValid = await validateUser();
        if (!isUserValid) {
            return null;
        }

        if (usersList.some(user => user.email === newUser.email)) {
            await ch.alertErrorAsync(Localizer.inviteUserEmailAlreadyInList);
            return null;
        }

        const newList = [ ...usersList, newUser ];
        setUsersList(newList);
        setNewUser(new InvitedUser(RentaEasyConstants.telephoneCountryCode));
        setExistingUsersOptions([newUserOption]);
        setSelectedExistingUser(undefined);
        firstNameRef.current?.focus();

        return newList;
    };

    const validateConstructionSiteAccess = async (): Promise<boolean> => {
        if (!data!.accessToAllConstructionSites && (!(constructionSiteAccessInput.length > 0))) {
            await ch.alertErrorAsync(`${Localizer.inviteUserSelectOne}`);
            return false;
        }

        return true;
    };

    const validateUser = async (): Promise<boolean> => {
        if (!newUser.firstName || !newUser.lastName || !newUser.email || !newUser.telephone) {
            await ch.alertWarningAsync(Localizer.inviteUserInfoMissing);
            return false;
        }

        const isPhoneNumberValid = validatePhoneNumber(newUser.telephone) === null;
        const isEmailAddressValid = EmailValidator.instance.validate(newUser.email) === null;

        return isPhoneNumberValid && isEmailAddressValid;
    };

    const handleSaveMultiple = async () => {
        let usersListToSave: InvitedUser[] | null = usersList;
        if (
            newUser.firstName?.length ||
            newUser.lastName?.length ||
            newUser.email?.length ||
            (
                newUser.telephone?.length &&
                newUser.telephone !== RentaEasyConstants.telephoneCountryCode
            )
        ) {
            usersListToSave = await addUserToList();
            if (!usersListToSave) {
                return;
            }
        }

        if (!usersListToSave.length) {
            await ch.alertErrorAsync(Localizer.inviteUserInfoMissing);
            return;
        }

        const isAccessValid = await validateConstructionSiteAccess();
        if (!isAccessValid) {
            return;
        }

        const request: InviteUserPostRequest = {
            contractId: props.contractId,
            usersList: usersListToSave,
            accessToAllConstructionSites: data!.accessToAllConstructionSites,
            companyMainUserRole: data!.companyMainUserRole,
            constructionSiteAccesses: constructionSiteAccessInput.map(item => ({...item, access: true})),
        };

        await props.onSave(request);
    };

    const handleSaveSingle = async () => {
        const isUserValid = await validateUser();
        if (!isUserValid) {
            return null;
        }

        const isAccessValid = await validateConstructionSiteAccess();
        if (!isAccessValid) {
            return;
        }

        const request: InviteNewUserRequest = {
            contractId: props.contractId,
            accessToAllConstructionSites: data!.accessToAllConstructionSites,
            companyMainUserRole: data!.companyMainUserRole,
            constructionSiteAccesses: constructionSiteAccessInput.map(item => ({...item, access: true})),
            usersList: [],
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            telephone: newUser.telephone,
        };

        await props.onSave(request);
    };

    const onSave = async () => {
        if (props.multiple) {
            await handleSaveMultiple();
            return;
        }

        await handleSaveSingle();
    };

    const setConstructionSiteAccessProperty = (checked: boolean, property: string, itemIndex: number) => {
        setConstructionSiteAccessInput(prev => prev.map((item, index) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    [property]: checked,
                };
            }

            return item;
        }));
    };

    useEffect(() => {
        if (!selectedExistingUser) {
            setExistingUsersOptions([newUserOption]);
            setSelectedExistingUser(undefined);
            setNewUser(new InvitedUser(RentaEasyConstants.telephoneCountryCode));
            return;
        }

        const selectedUser = existingUsers.find(user => user.userId === selectedExistingUser.value);
        if (!selectedUser) {
            return;
        }

        setNewUser({
            firstName: selectedUser.firstName!,
            lastName: selectedUser.lastName!,
            email: selectedUser.email!,
            telephone: selectedUser.phoneNumber!,
        });
        setExistingUsersOptions([selectedExistingUser]);
    }, [selectedExistingUser]);

    const mapUserToDropdownItem = (user: UserInvitationModel): IDropdownItem => {
        return user.firstName && user.lastName ? {
            name: `${user.firstName} ${user.lastName}`,
            value: user.userId,
            description: user.email,
        } : {
            name: user.email!,
            value: user.userId,
        };
    };

    useEffect(() => {
        const searchUsers = async (search: string) => {
            const users = await searchUsersAsync(search.trim());
            setExistingUsers(users);
            setExistingUsersOptions(users.map(mapUserToDropdownItem));
        };

        if (searchTerm.length === 0 && selectedExistingUser) {
            return;
        }
        if (searchTerm.length < 3) {
            setExistingUsers([]);
            setExistingUsersOptions([newUserOption]);
            return;
        }

        searchUsers(searchTerm).catch();
    }, [searchTerm, selectedExistingUser]);

    const ContainerComponent = props.singleColumn ? OneColumn : TwoColumns;
    const ButtonContainerComponent = props.singleColumn ? OneColumn : ButtonContainer;

    return data && (
        <div className={styles.inviteUserForm}>
            <ContainerComponent>
                <PageRow>
                    <Form>
                        <DropdownNew id="select-user-dropdown"
                                     items={existingUsersOptions}
                                     label={Localizer.inviteUserSelectUser}
                                     selectedItem={selectedExistingUser ?? newUserOption}
                                     onChange={setSelectedExistingUser}
                                     onSearch={setSearchTerm}
                                     allowEmpty
                                     searchable
                                     mode={DropdownMode.DROPDOWN}
                        />
                        <TextInput ref={firstNameRef}
                                   id="firstName"
                                   label={Localizer.genericFirstName}
                                   value={newUser.firstName}
                                   onChange={async (_, value) => setNewUser(current => ({...current, firstName: value}))}
                                   readonly={!!selectedExistingUser && !!newUser.firstName}
                                   required
                        />
                        <TextInput id="lastName"
                                   label={Localizer.genericLastName}
                                   value={newUser.lastName}
                                   onChange={async (_, value) => setNewUser(current => ({...current, lastName: value}))}
                                   readonly={!!selectedExistingUser && !!newUser.lastName}
                                   required
                        />
                        <EmailInput id="email"
                                    label={Localizer.genericEmail}
                                    value={newUser.email}
                                    onChange={async (_, value) => setNewUser(current => ({...current, email: value}))}
                                    readonly={!!selectedExistingUser && !!newUser.email}
                                    required
                        />
                        <TextInput id="phone"
                                   label={Localizer.genericPhoneNumber}
                                   value={newUser.telephone}
                                   onChange={async (_, value) => setNewUser(current => ({...current, telephone: value}))}
                                   validators={[validatePhoneNumber]}
                                   placeholder={Localizer.genericPlaceholderPhoneNumber}
                                   readonly={!!selectedExistingUser && !!newUser.telephone}
                                   required
                        />
                    </Form>

                    {!!props.multiple && (
                        <Button onClick={async () => {
                            await addUserToList();
                        }}
                                id="addUserToListButton"
                                type={ButtonType.Orange}
                                label={Localizer.inviteUserAddAnotherEmail}
                        />
                    )}
                </PageRow>
                {!!props.multiple && (
                    <PageRow className={styles.usersList}>
                        {
                            usersList.map(({firstName, lastName, email}) =>
                                (
                                    <div className={styles.userRow} key={email}>
                                        <div className={styles.userInfo}>
                                            <div>{firstName} {lastName}</div>
                                            <div className={styles.userEmail}>{email}</div>
                                        </div>

                                        <div>
                                            <a onClick={() => setUsersList(prev => prev.filter(user => user.email !== email))}>
                                                <i className="far fa-times-circle"/>
                                            </a>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </PageRow>
                )}
            </ContainerComponent>
            <ContainerComponent>
                {
                    (!data.rightsToSingleConstructionSite) &&
                    (
                        <PageRow className={Utility.css(styles.checkBoxContainer, styles.margin)}>
                            <Checkbox inline inlineType={InlineType.Right}
                                      value={data.accessToAllConstructionSites}
                                      id="constructionSiteRole_yes"
                                      label={Localizer.inviteUserRightsToAllConstructionSitesText}
                                      onChange={async (_, checked) => setData(prev => ({...prev!, accessToAllConstructionSites: checked}))}
                                      readonly={!data.rightsToAllConstructionSitesEnabled}
                            />

                            <small>
                                {Localizer.inviteUserRightsToAllConstructionSitesDescriptionContent}
                            </small>
                        </PageRow>
                    )
                }

                {
                    (data.accessToAllConstructionSites) &&
                    (
                        <PageRow className={styles.margin}>
                            <Checkbox inline inlineType={InlineType.Right}
                                      value={data.companyMainUserRole}
                                      id="companyMainUserRole_yes"
                                      label={Localizer.inviteUserMainUserRole}
                                      onChange={async (_, checked) => setData(prev => ({...prev!, companyMainUserRole: checked}))}
                                      readonly={!data.companyMainUserRoleEnabled}
                            />
                        </PageRow>
                    )
                }
            </ContainerComponent>
            {
                (!data.accessToAllConstructionSites) &&
                (
                    <>
                        {
                            (!data.rightsToSingleConstructionSite) && (
                                <PageRow>
                                    <Dropdown multiple required
                                              className={props.singleColumn ? styles.dropDown : undefined}
                                              requiredType={DropdownRequiredType.Restricted}
                                              label={Localizer.inviteUserSelectOne}
                                              selectType={DropdownSelectType.Checkbox}
                                              selectedItems={constructionSiteAccessInput}
                                              items={data.constructionSiteAccesses}
                                              onChange={async (dropdown) => setConstructionSiteAccessInput(dropdown.selectedItems)}
                                    />
                                </PageRow>
                            )
                        }

                        {
                            constructionSiteAccessInput.map((constructionSiteAccess, index) =>
                                (
                                    <div key={constructionSiteAccess.name}
                                         className={styles.box}
                                    >
                                        <PageRow className="boxHeader">
                                            <h3>
                                                {constructionSiteAccess.name}
                                            </h3>
                                        </PageRow>

                                        <TwoColumns>
                                            <PageRow className={styles.checkBoxContainer}>
                                                <Checkbox inline
                                                          inlineType={InlineType.Right}
                                                          value={constructionSiteAccess.fullAccess}
                                                          id="@fullAccessOnId"
                                                          label={Localizer.inviteUserFullAccessText}
                                                          readonly={isConstructionSiteUser}
                                                          onChange={async (_, checked) => setConstructionSiteAccessProperty(checked, "fullAccess", index)}
                                                />

                                                <small>
                                                    {Localizer.inviteUserFullAccessDescriptionContent}
                                                </small>
                                            </PageRow>

                                            {
                                                (!constructionSiteAccess.fullAccess) &&
                                                (
                                                    <PageRow className={styles.checkBoxContainer}>
                                                        <Checkbox inline
                                                                  inlineType={InlineType.Right}
                                                                  value={constructionSiteAccess.directRentAccess}
                                                                  id="@directAccessOnId"
                                                                  label={Localizer.inviteUserRightsToRentDirectlyText}
                                                                  onChange={async (_, checked) => setConstructionSiteAccessProperty(checked, "directRentAccess", index)}
                                                        />

                                                        <small>
                                                            {Localizer.inviteUserRightsToRentDirectlyDescriptionContent}
                                                        </small>
                                                    </PageRow>
                                                )
                                            }
                                        </TwoColumns>

                                        {
                                            (!constructionSiteAccess.fullAccess) &&
                                            (
                                                <TwoColumns>
                                                    <PageRow className={styles.checkBoxContainer}>
                                                        <Checkbox inline
                                                                  inlineType={InlineType.Right}
                                                                  value={constructionSiteAccess.pricesVisible}
                                                                  id="@pricesVisibleOnId"
                                                                  label={Localizer.inviteUserPriceInformationDisplayedText}
                                                                  onChange={async (_, checked) => setConstructionSiteAccessProperty(checked, "pricesVisible", index)}
                                                        />

                                                        <small>
                                                            {Localizer.inviteUserPriceInformationDisplayedDescriptionContent}
                                                        </small>
                                                    </PageRow>
                                                </TwoColumns>
                                            )
                                        }
                                    </div>
                                )
                            )
                        }
                    </>
                )
            }

            <ButtonContainerComponent>
                <Button block
                        id="addUserButton"
                        onClick={async () => await onSave()}
                        type={ButtonType.Orange}
                        label={props.saveButtonText}
                        icon={props.displaySaveButtonIcon ? {name: "fa fa-envelope"} : undefined}
                        disabled={props.sending}
                        className={Utility.css(styles.button, styles.sendButton)}
                />

                {
                    (!!props.displayCancelButton && !!props.onCancel) &&
                    (
                        <Button block
                                label={Localizer.formCancel}
                                type={ButtonType.Light}
                                onClick={async () => props.onCancel!()}
                                className={Utility.css(styles.button, styles.cancelButton)}
                        />
                    )
                }
            </ButtonContainerComponent>
        </div>
    );
};

export default InviteUserForm;