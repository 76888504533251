import {ApiProvider} from "@renta-apps/athenaeum-react-common";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {DeviceModel} from "@/models/server/DeviceModel";
import DeviceDetailsRequest from "@/models/server/Requests/DeviceDetailsRequest";
import DeviceExtendedDetailsRequest from "@/models/server/Requests/DeviceExtendedDetailsRequest";
import ListDevicesPagedRequest from "@/models/server/Requests/ListDevicesPagedRequest";
import ListReturnPagedRequest from "@/models/server/Requests/ListReturnsPagedRequest";
import GetDevicesCountRequest from "@/models/server/Requests/GetDevicesCountRequest";
import {DeviceDetailedModel} from "@/models/server/DeviceDetailedModel";
import {DeviceDetails} from "@/models/server/DeviceDetails";
import {DeviceUsage} from "@/models/ProductLocation/Models/DeviceUsage";
import GetDeviceLocationRequest from "@/models/server/Requests/GetDeviceLocationRequest";
import GetMachineDocumentsRequest from "@/models/server/Requests/GetMachineDocumentsRequest";
import DeviceDetailsRequestModel from "@/models/server/Requests/DeviceDetailsRequestModel";
import queryString from "query-string";
import {ConstructionSiteReturnModel} from "@/models/server/ConstructionSiteReturnModel";
import FileInfoModel from "@/models/server/FileInfoModel";
import {ReturnRequestModel} from "@/models/server/ReturnRequestModel";
import {ReturnDetailsModel} from "@/models/server/ReturnDetailsModel";
import {ConstructionSiteName} from "@/models/server/ConstructionSiteName";
import {IDropdownItem} from "@renta-apps/renta-react-components/components/Dropdown/Dropdown";
import SearchConstructionSiteNamesRequest from "@/models/server/Requests/SearchConstructionSiteNamesRequest";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";


export const getDeviceDetailsAsync = async (
    assetId: string | null,
    rentaId: string,
    contractNumber: string | null,
    orderLineId: string | null,
    constructionSiteId: string | null,
    contractId: string | null,
): Promise<DeviceDetails> => {
    const request: DeviceDetailsRequest = {
        assetId: assetId,
        rentaId: rentaId,
        contractNumber: contractNumber,
        orderLineId: orderLineId,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceDetails", request);
};

export const getDevicesDetailsAsync = async (
    devices: DeviceDetailsRequestModel[],
    constructionSiteId: string | null,
    contractId: string | null,
): Promise<DeviceDetails[]> => {
    return ApiProvider.postAsync("/api/Fleet/GetDevicesDetails", {
        devices,
        constructionSiteId: constructionSiteId,
        contractId: contractId,
    });
};

export const getDeviceDebugDetailsAsync = async (
    assetId: string | null,
    rentaId: string
): Promise<string> => {
    const request: DeviceDetailsRequestModel = {
        assetId: assetId,
        rentaId: rentaId,
        contractNumber: null,
        orderLineId: null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceDebugDetails", request);
};

export const getDeviceExtendedDetailsAsync = async (
    constructionSiteId: string | undefined,
    contractId: string | undefined,
    rentaId: string,
): Promise<DeviceDetailedModel> => {
    const request: DeviceExtendedDetailsRequest = {
        constructionSiteId: constructionSiteId ?? null,
        contractId: contractId ?? null,
        rentaId: rentaId,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDeviceExtendedDetails", request);
};

export const getDeviceUsageAsync = async (
    serialNumber: string,
    rentaId: string,
): Promise<DeviceUsage[] | null> => {
    const request: GetDeviceLocationRequest = {
        trackingSerialNumber: serialNumber,
        rentaId: rentaId,
    };

    return ApiProvider.postAsync("/api/Locations/GetDeviceUsage", request);
};

export const getDeviceDocumentsAsync = async (
    rentaId: string
): Promise<FileInfoModel[]> => {
    const request: GetMachineDocumentsRequest = {
        rentaId: rentaId,
    };
    return ApiProvider.postAsync("/api/Product/GetMachineDocuments", request);
};

export const getDevicesPagedListAsync = async (
    pageNumber: number,
    pageSize: number,
    userContractId: string | undefined,
    userSiteId: string | undefined,
    sortColumnName: string | null,
    sortDirection: SortDirection | null,
    deviceNames: string[] | undefined,
    companies: SelectedFilter[] | undefined,
    constructionSites: SelectedFilter[] | undefined,
    depots: SelectedFilter[] | undefined,
    productGroups: SelectedFilter[] | undefined,
    statuses: SelectedFilter[] | undefined,
): Promise<IPagedList<DeviceModel>> => {
    const request: ListDevicesPagedRequest = {
        sortColumnName: sortColumnName,
        sortDirection: sortDirection,
        pageSize: pageSize,
        pageNumber: pageNumber,
        contractId: userContractId ?? null,
        constructionSiteId: userSiteId ?? null,
        deviceNames: deviceNames ?? null,
        companies: companies ?? null,
        constructionSites: constructionSites ?? null,
        depots: depots ?? null,
        productGroups: productGroups ?? null,
        statuses: statuses ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDevicesPagedList", request);
};

export const getDevicesCountAsync = async (
    userContractId: string | undefined,
    userSiteId: string | undefined,
    deviceNames: string[] | undefined,
    companies?: Array<SelectedFilter> | undefined,
    constructionSites?: Array<SelectedFilter> | undefined,
    depots?: Array<SelectedFilter> | undefined,
    productGroups?: Array<SelectedFilter> | undefined,
    statuses?: Array<SelectedFilter> | undefined,
): Promise<number> => {
    const request: GetDevicesCountRequest = {
        contractId: userContractId ?? null,
        constructionSiteId: userSiteId ?? null,
        deviceNames: deviceNames ?? null,
        companies: companies ?? null,
        constructionSites: constructionSites ?? null,
        depots: depots ?? null,
        productGroups: productGroups ?? null,
        statuses: statuses ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetDevicesCount", request);
};

export const getConstructionSiteEquipmentAsync = async (constructionSiteId: string): Promise<ConstructionSiteReturnModel> => {
    const query = queryString.stringify({constructionSiteId});

    return ApiProvider.getAsync(`/api/Fleet/GetConstructionSiteEquipment?${query}`, null);
};

export const getConstructionSiteNames = async (searchTerm: string, companies: IDropdownItem[], searchLimit: number = 50): Promise<ConstructionSiteName[]> => {
    const request: SearchConstructionSiteNamesRequest = {
        companies: companies?.map(company => ({name: company.name, id: company.value?.toString()})) ?? null,
        searchTerm: searchTerm ?? null,
        searchStart: 0,
        searchLimit: searchLimit ?? null,
    };

    return ApiProvider.postAsync<ConstructionSiteName[]>(`/api/Fleet/SearchConstructionSiteNames`, request);
};

export const getReturnsPagedListAsync = async (
    pageNumber: number,
    pageSize: number,
    userContractId: string | undefined,
    userSiteId: string | undefined,
    sortColumnName: string | null,
    sortDirection: SortDirection | null,
    deviceNames: string[] | undefined,
    companies: SelectedFilter[] | undefined,
    constructionSites: SelectedFilter[] | undefined,
    depots: SelectedFilter[] | undefined,
    productGroups: SelectedFilter[] | undefined,
    statuses: SelectedFilter[] | undefined,
): Promise<IPagedList<ReturnRequestModel>> => {
    const request: ListReturnPagedRequest = {
        sortColumnName: sortColumnName,
        sortDirection: sortDirection,
        pageSize: pageSize,
        pageNumber: pageNumber,
        contractId: userContractId ?? null,
        constructionSiteId: userSiteId ?? null,
        constructionSites: constructionSites ?? null,
        companies: companies ?? null,
        productGroups: productGroups ?? null,
        depots: depots ?? null,
        statuses: statuses ?? null,
        deviceNames: deviceNames ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetReturnsPagedList", request);
};

export const getReturnsCountAsync = async (
    userContractId: string | undefined,
    userSiteId: string | undefined,
    deviceNames: string[] | undefined,
    companies?: Array<SelectedFilter> | undefined,
    constructionSites?: Array<SelectedFilter> | undefined,
    depots?: Array<SelectedFilter> | undefined,
    productGroups?: Array<SelectedFilter> | undefined,
    statuses?: Array<SelectedFilter> | undefined,
): Promise<number> => {
    const request: GetDevicesCountRequest = {
        contractId: userContractId ?? null,
        constructionSiteId: userSiteId ?? null,
        deviceNames: deviceNames ?? null,
        companies: companies ?? null,
        constructionSites: constructionSites ?? null,
        depots: depots ?? null,
        productGroups: productGroups ?? null,
        statuses: statuses ?? null,
    };

    return ApiProvider.postAsync("/api/Fleet/GetReturnsCount", request);
};

export const getReturnDetailsAsync = async (id: string): Promise<ReturnDetailsModel> => {
    return ApiProvider.postAsync("/api/Fleet/GetReturnDetails", id);
};

export const getReturnsDetailsAsync = async (ids: string[]): Promise<ReturnDetailsModel[]> => {
    return ApiProvider.postAsync("/api/Fleet/GetReturnsDetails", ids);
};

