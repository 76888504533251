import TransformProvider from "@/providers/TransformProvider";
import ProductModel from "@/models/server/ProductModel";
import {PriceType, ProductType} from "@/models/Enums";
import ShoppingCartProductModel from "@/models/server/ShoppingCartProductModel";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import Localizer from "@/localization/Localizer";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import LocalizationHelper from "./LocalizationHelper";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

export enum ProductPriceType {
    Daily,
    Weekly,
    Monthly,
    Unit,
    Rental = Daily | Weekly | Monthly
}

export interface IProductPrice {

    /**
     * Value of the price, if applicable. Includes {@link vat}.
     */
    value: number | null | undefined,

    /**
     * Type of the price.
     */
    type: ProductPriceType,

    rentbasis: number | null,

}

export interface IPriceData {

    /**
     * Value of the price, if applicable. Includes {@link vat}.
     */
    value: number | null | undefined,

    /**
     * Unit of the price, if type is {@link ProductPriceType.Unit}.
     */
    unit: string | null,

    /**
     * VAT included in the {@link value}.
     */
    vat: number,

    /**
     * Type of the price.
     */
    type: ProductPriceType,


    /**
     * Is the price discounted.
     */
    isDiscounted: boolean,

    rentbasis: number | null;
}

export interface ILocalizedPriceData {

    /**
     * Localized value of the price.
     */
    value: string,

    /**
     * Value of the price without unit.
     */
    valueNoUnit: string,

    /**
     * Localized unit of the price.
     */
    unit: string | null,

    hideUnit: boolean;

    type: ProductPriceType;

    /**
     * Is the price discounted.
     */
    isDiscounted: boolean,
}

export default class PriceHelper {

    // Fields

    /**
     * Localized units for prices.
     */
    public static readonly units = {

        /**
         * Localized unit for daily prices.
         *
         * @param vat VAT percentage.
         */
        daily: (vat: number | null) => PriceHelper.formatUnit(Localizer.productDay, vat),

        /**
         * Localized unit for weekly prices.
         *
         * @param basis How many days should be considered a week. Usually 5 or 7.
         * @param vat VAT percentage.
         */
        weekly: (basis: number, vat: number | null) => PriceHelper.formatUnit(Localizer.product5Days.format(basis), vat),

        /**
         * Localized unit for monthly prices.
         *
         * @param vat VAT percentage.
         */
        monthly: (vat: number | null) => PriceHelper.formatUnit(Localizer.product1Month, vat),
    };

    // Constructors

    // noinspection JSUnusedLocalSymbols - This class is "static", and should not be instantiated.
    private constructor() {
    }

    // Properties

    /**
     * Constant value used if Product has no price data available.
     */
    public static get offer(): string {
        return `${Localizer.genericOffer}`;
        //  return PriceHelper.formatUnit(Localizer.genericOffer);
    }

    /**
     * Constant value used if Product has prices hidden.
     */
    public static get hidden(): string {
        return "";
    }

    public static hasDiscount(product: ProductModel): boolean {
        return (product.productType === ProductType.Rental && product.customerDailyPrice !== undefined && product.customerDailyPrice !== null) ||
            (product.productType === ProductType.Sales && product.customerUnitPrice !== undefined && product.customerUnitPrice !== null);
    }

    /**
     * Three-letter currency code used in the current environment.
     */
    public static get environmentCurrencyCode(): string {

        switch (ch.getContext().country) {
            case "se":
            case "sv":
                return "SEK";
            case "pl":
                return "PLN";
            case "nor":
            case "no":
            case "nb":
                return "NOK";
            case "dk":
            case "da":

                return "DKK";
            default:
                return "EUR";
        }
    }

    /**
     * VAT applicable to the current user.
     * Environment VAT if private, 0 otherwise.
     */
    public static get userVat(): number {
        return ((ch.getContext() as UserContext).isPrivateUser)
            ? this.environmentVat
            : 0;
    }

    /**
     * VAT of the current environment.
     */
    public static get environmentVat(): number {
        return Number.parseFloat(ch.getContext().settings.vatPercentage);
    }

    public static get salesProductsEnabled(): boolean {
        return UnleashHelper.isEnabled(RentaEasyConstants.featureFlagSalesItems);
    }

    /**
     * Are weekly prices enabled in the current environment.
     */
    public static get weeklyPricesEnabled(): boolean {

        switch (ch.getContext().country) {
            case "se":
            case "sv":
                return false;
            case "pl":
                return true;
            case "nor":
            case "no":
            case "nb":
                return false;
            case "dk":
            case "da":
                return false;
            default:
                return true;
        }
    }

    /**
     * Are monthly prices enabled in the current environment.
     */
    public static get monthlyPricesEnabled(): boolean {
        return this.weeklyPricesEnabled;
    }

    // Methods

    private static getCorrectPrice(customerPrice: number | null,
                                   priceWithoutVat: number | null,
                                   priceWithVat: number | null,
                                   vat: number | null,
                                   customerPriceType: PriceType | null,
                                   priceType: PriceType | null,
    ): number | null | undefined {


        if (priceType === PriceType.Hidden || customerPriceType === PriceType.Hidden) {
            return undefined;
        }

        if (priceType === PriceType.Offer || customerPriceType === PriceType.Offer) {
            return null;
        }

        return (typeof customerPrice === "number")
            ? customerPrice
            : (typeof priceWithoutVat === "number") && (!vat)
                ? priceWithoutVat
                : (typeof priceWithVat === "number") && (vat)
                    ? priceWithVat
                    : null;
    }

    private static formatUnit(unit: string,
                              vat: number | null = null
    ): string {

        unit = TransformProvider.toCapitalizedInitialLetter(unit);

        const vatString: string = (typeof vat === "number")
            ? ` (${this.getVatString(vat)})`
            : "";

        return `${unit}${vatString}`;
    }

    public static toPrices(product: ProductModel,
                           vat: number | null,
                           discount: ProductOrganizationContractPriceModel | null = null
    ): IPriceData[] {

        if (product.productType === ProductType.Sales) {

            if (!this.salesProductsEnabled) {

                // Or throw exception?
                return [];
            }

            const unitPrice: number | null | undefined = this.getCorrectPrice(product.customerUnitPrice, product.unitPrice, product.unitPriceWithTax, vat, product.customerDailyPriceType, product.unitPriceType);

            return [
                {
                    type: ProductPriceType.Unit,
                    unit: this.formatUnit(product.unitType ?? "", vat),
                    value: unitPrice,
                    vat: vat ?? 0,
                    rentbasis: product.rentBasis,
                    isDiscounted: product.customerUnitPrice !== null && unitPrice === product.customerUnitPrice
                }
            ];
        }

        if (product.productType === ProductType.Rental) {

            let customerDailyPrice: number | null = product.customerDailyPrice ?? null;

            let customerWeeklyPrice: number | null = product.customerWeeklyPrice ?? null;

            let customerMonthlyPrice: number | null = product.customerMonthlyPrice ?? null;

            // Copied from C# ProductModel
            if (discount?.dailyPrice) {

                if (discount.rentType === 1) {
                    customerDailyPrice = discount.dailyPrice;
                    customerMonthlyPrice = discount.monthlyPrice;
                }
                else if (discount.rentType === 8) {
                    const vuokrape8: number = 0.14285714285;
                    customerDailyPrice = vuokrape8 * discount.dailyPrice;
                    customerWeeklyPrice = discount.dailyPrice;
                    customerMonthlyPrice = customerDailyPrice * 30;
                }
                else if (discount.rentType === 9) {
                    customerDailyPrice = discount.dailyPrice / 30;
                    customerMonthlyPrice = discount.dailyPrice;
                }
            }

            const dailyPrice: number | null | undefined = this.getCorrectPrice(customerDailyPrice, product.dailyPrice, product.dailyPriceWithTax, vat, product.customerDailyPriceType, product.dailyPriceType);

            const weeklyPrice: number | null | undefined = (this.weeklyPricesEnabled)
                ? this.getCorrectPrice(customerWeeklyPrice, product.weeklyPrice, product.weeklyPriceWithTax, vat, product.customerDailyPriceType, product.dailyPriceType)
                : null;

            const monthlyPrice: number | null | undefined = (this.monthlyPricesEnabled)
                ? this.getCorrectPrice(customerMonthlyPrice, product.monthlyPrice, product.monthlyPriceWithTax, vat, product.customerMonthlyPriceType, product.monthlyPriceType)
                : null;

            let rentalPrices: IPriceData[] = [];

            rentalPrices.push({
                type: ProductPriceType.Daily,
                unit: this.units.daily(vat),
                value: dailyPrice,
                vat: vat!,
                isDiscounted: (customerDailyPrice !== null),
                rentbasis: null,
            });

            if (this.weeklyPricesEnabled) {
                rentalPrices.push({
                    type: ProductPriceType.Weekly,
                    unit: this.units.weekly(product.rentBasis, vat),
                    rentbasis: product.rentBasis,
                    value: weeklyPrice,
                    vat: vat!,
                    isDiscounted: (customerWeeklyPrice !== null)
                });
            }

            if (this.monthlyPricesEnabled) {
                rentalPrices.push({
                    type: ProductPriceType.Monthly,
                    unit: this.units.monthly(vat),
                    value: monthlyPrice,
                    vat: vat!,
                    rentbasis: null,
                    isDiscounted: (customerMonthlyPrice !== null)
                });
            }

            if (rentalPrices.length <= 0) {

                // Return only one null value if no price data.

                return [
                    {
                        value: null,
                        vat: vat ?? 0,
                        unit: null,
                        rentbasis: null,
                        type: ProductPriceType.Rental,
                        isDiscounted: false,
                    }
                ];
            }
            else {
                return rentalPrices;
            }
        }

        throw new TypeError(`Invalid ProductType enum value '${product.productType}'`);
    }

    /**
     * @return {@link offer} if value is null or zero. Localized price value otherwise.
     */
    public static toLocalizedValue(value: IProductPrice): string {

        if (value.value === undefined) {
            return this.hidden;
        }

        if (!value.value) {
            let offerPostFix: string = "";
            switch (value.type) {
                case ProductPriceType.Daily:
                    offerPostFix = Localizer.generic1stDay;
                    break;
                case ProductPriceType.Monthly:
                    offerPostFix = Localizer.genericMonth;
                    break;
                case ProductPriceType.Weekly:
                    offerPostFix = Localizer.get(Localizer.genericWeek, value.rentbasis);
                    break;
                case ProductPriceType.Unit:
                    return this.offer;
            }

            return `${this.offer} / ${offerPostFix}`;
        }

        return value.value.toLocaleString(
            LocalizationHelper.currentLocale,
            {
                style: "currency",
                currency: this.environmentCurrencyCode
            });
    }

    public static toLocalizedPrices(product: ProductModel,
                                    vat: number | null,
                                    discount: ProductOrganizationContractPriceModel | null = null
    ): ILocalizedPriceData[] {

        const prices: IPriceData[] = this.toPrices(product, vat, discount);

        return prices.map((price) => {
            return {
                value: this.toLocalizedValue(price),
                valueNoUnit: price.value?.toString() ?? "",
                unit: price.unit,
                hideUnit: price.value === null || price.value === undefined,
                isDiscounted: price.isDiscounted,
                type: price.type,
            };
        });
    }

    /**
     * Sum multiple products prices together.
     * If no product has available prices or the specified {@link ProductPriceType} is not enabled for the environment, returns 0.
     *
     * @param products Products which prices to calculate and sum together.
     * @param vat Currently applicable VAT.
     * @param type {@link PriceType} to calculate.
     * @param discounts Contracts discount.
     */
    public static sum(products: ShoppingCartProductModel[],
                      vat: number,
                      type: ProductPriceType,
                      discounts: OrganizationContractDiscounts | null = null
    ): IProductPrice {

        if ((type === ProductPriceType.Weekly && !this.weeklyPricesEnabled)
            || (type === ProductPriceType.Monthly && !this.monthlyPricesEnabled)
            || (type === ProductPriceType.Unit && !this.salesProductsEnabled)) {
            return {value: 0, type: type, rentbasis: null};
        }

        const sum: number = products
            .filter(
                product =>
                    !!product?.product)
            .sum(product => this.calculateProductPrice(product.product!, vat, product.count, type, discounts));

        return {value: sum, type, rentbasis: type};
    };

    public static sumAttached(products: ProductModel[],
                              vat: number,
                              mainProductCount: number,
                              type: ProductPriceType,
                              discounts: OrganizationContractDiscounts | null = null
    ): number {

        if ((type === ProductPriceType.Weekly && !this.weeklyPricesEnabled)
            || (type === ProductPriceType.Monthly && !this.monthlyPricesEnabled)
            || (type === ProductPriceType.Unit && !this.salesProductsEnabled)) {
            return 0;
        }

        return products
            .filter(product => !!product.quantity)
            .sum(product => this.calculateProductPrice(product, vat, product.quantity! * mainProductCount, type, discounts));
    };

    private static calculateProductPrice(product: ProductModel,
                                         vat: number,
                                         count: number,
                                         type: ProductPriceType,
                                         discounts: OrganizationContractDiscounts | null = null
    ): number {
        const discount: ProductOrganizationContractPriceModel | null =
            discounts?.productSpecificPrices?.find(
                productPrice =>
                    productPrice.externalId === product.externalId)
            ?? null;

        const prices: IPriceData[] = this.toPrices(product, vat, discount);

        const price: IPriceData | undefined = prices.find(price => price.type === type);

        let priceValue = price?.value ?? 0.0;

        if (product.discountPercentage)
            priceValue = priceValue * (100.0 - product.discountPercentage)/100.0;

        return priceValue * count;
    }

    public static getVatString(vat: number | null): string {
        return (vat)
            ? `${Localizer.productDetailsVatValue.format(vat)}`
            : Localizer.productDetailsVat0;
    }
}