import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {PageContainer} from "@renta-apps/athenaeum-react-components";
import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";
import {IDropdownItem, Pagination} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import {ReturnRequestModel} from "@/models/server/ReturnRequestModel";
import {ReturnDetailsModel} from '@/models/server/ReturnDetailsModel';
import {getReturnDetailsAsync, getReturnsDetailsAsync, getReturnsPagedListAsync} from "@/services/FleetService";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";

import ViewControl, {FleetMonitoringFiltersSortAndPagination} from "./ViewControl/ViewControl";
import ReturnRequestGrid from "./ReturnGrid/ReturnGrid";
import {FleetMonitoringFiltersAndPagination} from "./FleetMonitoringContainer";
import {getContractData} from "@/services/CompanyService";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import {useFiltersState} from "@/pages/FleetMonitoring/Hooks/useFiltersState";
import {useDebounceLoad} from "./Hooks/useDebounceLoad";

interface IReturnRequestsProps {
    urlParams?: FleetMonitoringFiltersAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    onFilterParamsChange: (filters: FleetMonitoringFiltersAndPagination) => void;
}

const defaultPageNumber = 1;
const defaultPageSize = 25;

const ReturnRequests: React.FC<IReturnRequestsProps> = (props: IReturnRequestsProps) => {
    const USER_DATA_STORAGE_KEY = useMemo(() => {
        return `ReturnRequestFilters-${props.userRoleContractId}-${props.userRoleConstructionSiteId}`;
    }, []);

    const createInitialFilters = useCallback((): FleetMonitoringFiltersSortAndPagination => {
        let initialParams = props.urlParams;
        if (!initialParams) {
            initialParams = UserInteractionDataStorage.get(USER_DATA_STORAGE_KEY, new FleetMonitoringFiltersAndPagination(), DataStorageType.Page);
        }

        return {
            companies: initialParams!.companies ?? [],
            constructionSites: initialParams!.constructionSites ?? [],
            depots: initialParams!.depots ?? [],
            deviceNames: initialParams!.deviceNames ?? [],
            productGroups: initialParams!.productGroups ?? [],
            sortBy: initialParams!.sortBy ?? 'PickupTime',
            sortOrder: initialParams!.sortOrder ?? 'Asc',
            statuses: initialParams!.statuses ?? [],
            pageNumber: initialParams!.pageNumber === undefined ? defaultPageNumber : Number(initialParams!.pageNumber),
            pageSize: initialParams!.pageSize === undefined ? defaultPageSize : Number(initialParams!.pageSize),
        };
    }, []);

    const [
        filtersSortAndPagination,
        setFilterFromDropdownItems,
        setFiltersPartial,
        resetFilters,
    ] = useFiltersState(createInitialFilters);

    // Other state variables.
    const [filteredReturnRequests, setFilteredReturnRequests] = useState<ReturnRequestModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [initialLoad, setInitialLoad] = useState(true);
    const [scrollToTop, setScrollToTop] = useState(false);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);
    const [userCompaniesList, setUserCompaniesList] = useState<IDropdownItem[]>([]);

    const dataGridRef = useRef<HTMLDivElement>(null);
    const currentRequestIdRef = useRef<string | null>(null);

    useEffect(() => {
        loadContractDataList(props.userRoleContractId, props.userRoleIsAdmin).catch();
    }, []);

    const sortByItems: IDropdownItem[] = [
        {
            name: Localizer.fleetMonitoringPageFiltersSortByPickupTime,
            value: "PickupTime",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByStatus,
            value: "Status",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByCompany,
            value: "CustomerName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByConstructionSite,
            value: "ConstructionSiteName",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByDepot,
            value: "Depot",
        },
    ];

    const handleOnPageNumberChange = (pageNumber: number) => {
        setScrollToTop(true);
        setFiltersPartial({pageNumber});
    };

    const handleOnPageSizeChange = (pageSize: number) => {
        setScrollToTop(true);
        setFiltersPartial({pageSize});
    };

    const loadReturnRequests = async (filters: FleetMonitoringFiltersSortAndPagination, requestId: string) => {
        setIsLoading(true);

        try {
            if (!initialLoad) {
                saveUserSelection(filters);
            }

            const filteredReturnRequests: IPagedList<ReturnRequestModel> = await getReturnsPagedListAsync(
                filters.pageNumber,
                filters.pageSize,
                (props.userRoleIsAdmin || !props.userRoleContractId) ? undefined : props.userRoleContractId,
                (props.userRoleIsAdmin || !props.userRoleConstructionSiteId) ? undefined : props.userRoleConstructionSiteId,
                filters.sortBy,
                SortDirection[filters.sortOrder as keyof typeof SortDirection],
                filters.deviceNames,
                filters.companies,
                filters.constructionSites,
                filters.depots,
                filters.productGroups,
                filters.statuses,
            );

            // Ensure only the latest request's result is applied.
            if (currentRequestIdRef.current !== requestId) return;

            const {totalItemCount, items} = filteredReturnRequests;
            setFilteredReturnRequests(items);
            setTotalItemCount(totalItemCount);

        } catch (error) {
            console.error('Error loading return requests:', error);
        } finally {
            setIsLoading(false);
            setInitialLoad(false);
            if (scrollToTop && dataGridRef.current) {
                setScrollToTop(false);
                // 45 - height of the top nav bar
                window.scrollTo({top: dataGridRef.current.offsetTop - 45, behavior: 'smooth'});
            }
        }
    };

    const loadContractDataList = async (userRoleContractId: string | null, userRoleIsAdmin: boolean) => {
        if (userRoleIsAdmin || !userRoleContractId) {
            setUserCompaniesList([]);
            return;
        }

        try {
            const contractData = await getContractData(userRoleContractId);
            const companiesList = [mapCompanyToDropdownItem(contractData.organizationContract!)]
                .concat(contractData.organizationContract?.children?.map(mapCompanyToDropdownItem) ?? []);

            setUserCompaniesList(companiesList);
        } catch (error) {
            console.error('Error loading contract data:', error);
        }
    };

    const mapCompanyToDropdownItem = (company: OrganizationContractModel): IDropdownItem => {
        return {
            name: `${company.name ?? ''} ${company.customerNumber ? `(${company.customerNumber})` : ''}`,
            value: company.contractId,
        };
    };

    const loadReturnRequestDetails = async (id: string): Promise<ReturnDetailsModel> => {
        try {
            return await getReturnDetailsAsync(id);
        } catch (error) {
            console.error('Error loading return request details:', error);
            throw error;
        }
    };

    const loadReturnRequestsDetails = async (ids: string[]): Promise<ReturnDetailsModel[]> => {
        try {
            return await getReturnsDetailsAsync(ids);
        } catch (error) {
            console.error('Error loading return requests details:', error);
            throw error;
        }
    };

    const saveUserSelection = (filters: FleetMonitoringFiltersSortAndPagination) => {
        props.onFilterParamsChange(filters);

        UserInteractionDataStorage.set(USER_DATA_STORAGE_KEY, filters, DataStorageType.Page);
    };

    useDebounceLoad(filtersSortAndPagination, currentRequestIdRef, {
        saveUserSelection,
        loadData: loadReturnRequests,
        initialLoad,
    });

    return (
        <PageContainer className={styles.pageContainer}>
            <div className={styles.devices}>
                <div id="container" className={styles.container}>
                    <ViewControl
                        userRoleConstructionSiteId={props.userRoleConstructionSiteId}
                        userRoleContractId={props.userRoleContractId}
                        userRoleIsAdmin={props.userRoleIsAdmin}
                        filters={filtersSortAndPagination}
                        sortByItems={sortByItems}
                        userCompaniesList={userCompaniesList}
                        mode="returns"
                        onDropdownFieldChanged={setFilterFromDropdownItems}
                        onFiltersChanged={setFiltersPartial}
                        onFiltersReset={resetFilters}
                    />

                    <ReturnRequestGrid
                        data={filteredReturnRequests}
                        returnRequestDetails={(id: string) => loadReturnRequestDetails(id)}
                        returnRequestsDetails={(ids: string[]) => loadReturnRequestsDetails(ids)}
                        isLoading={isLoading}
                        gridRef={dataGridRef}
                    />

                    <Pagination
                        pageNumber={filtersSortAndPagination.pageNumber}
                        pageSize={filtersSortAndPagination.pageSize}
                        totalItemCount={totalItemCount}
                        onPageNumberChange={(pageNumber: number) => handleOnPageNumberChange(pageNumber)}
                        onPageSizeChange={(pageSize: number) => handleOnPageSizeChange(pageSize)}
                    />
                </div>
            </div>
        </PageContainer>
    );
};

export default ReturnRequests;