import PageDefinitions from "@/providers/PageDefinitions";
import {ApiProvider, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import {GlobalSearchProviders, GlobalSearchResponse} from "@renta-apps/athenaeum-react-components";
import GlobalSearchRequest from "@/models/server/Requests/GlobalSearchRequest";

export class GlobalSearchUtils {

    private static onClicks: {[key: string]: (item: object) => void} = {
        "categories": (item: any) => {
            const route = PageDefinitions.rent.route({id: ("id" in item) ? item.id as string : ""});
            PageRouteProvider.redirectAsync(route);
        },
        "products": (item: any) => {
            const route = PageDefinitions.productDetails.route({id: ("url" in item) ? item.url as string : ""});
            PageRouteProvider.redirectAsync(route);
        },
        "constructionSites": (item: any) => {
            const route = PageDefinitions.constructionSite.route({params: {id: ("id" in item) ? item.id as string : ""}});
            PageRouteProvider.redirectAsync(route);
        },
        "depots": (item: any) => {
            const route = PageDefinitions.depots.route({params: {selectedDepot: ("name" in item) ? item.name as string : ""}});
            PageRouteProvider.redirectAsync(route);
        },
        // users search disabled for now, and it breaks the pipeline after fields were added to the IAdminUserManagementParams
        // "users": (item: any) => {
        //     const route = PageDefinitions.adminUserManagement.route({params: {userId: ("id" in item) ? item.id as string : ""}});
        //     PageRouteProvider.redirectAsync(route);
        // },
        "organizationContracts": (item: any) => {
            const route = PageDefinitions.contractDetails.route({params: {id: ("contractId" in item) ? item.contractId as string : ""}});
            PageRouteProvider.redirectAsync(route);
        },
    }

    public static handleSearchItemClick = async (type: string, item: object): Promise<void> => {
        if (type in GlobalSearchUtils.onClicks) {
            GlobalSearchUtils.onClicks[type](item);
        } else {
            console.error(`GlobalSearchUtils.handleSearchItemClick: type '${type}' not handled`);
        }
    }

    public static handleSearchResultsMoreClick = async (): Promise<void> => {
        await PageRouteProvider.redirectAsync(PageDefinitions.globalSearch.route());
    }

    public static fetchSearchProvidersAsync = async(): Promise<GlobalSearchProviders> => {
        return await ApiProvider.postAsync("/api/Search/GlobalSearchOptions", {});
    }

    public static fetchSearchResultsAsync = async (searchTerm: string, searchProviders: string[], resultsLimit = 10): Promise<GlobalSearchResponse> => {
        const request: GlobalSearchRequest = new GlobalSearchRequest();
        request.searchTerm = searchTerm;
        request.searchProviders = searchProviders;
        request.resultsLimit = resultsLimit;
        return await ApiProvider.postAsync("/api/Search/GlobalSearch", request);
    }
}

