import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styles from "./FleetMonitoringContainer.module.scss";

import {IPagedList, SortDirection} from "@renta-apps/athenaeum-toolkit";

import {IDropdownItem, Pagination} from "@renta-apps/renta-react-components";
import Localizer from "@/localization/Localizer";
import AlarmModel from "@/models/server/AlarmModel";
import {DeviceModel} from "@/models/server/DeviceModel";
import {DeviceDetails} from "@/models/server/DeviceDetails";
import UserInteractionDataStorage, {DataStorageType} from "@/providers/UserInteractionDataStorage";
import {getDeviceDebugDetailsAsync, getDeviceDetailsAsync, getDevicesDetailsAsync, getDevicesPagedListAsync} from "@/services/FleetService";
import DeviceGrid from "./DeviceGrid/DeviceGrid";
import SubscribeToAlertsModal from "./Modals/SubscribeToAlertsModal";
import ViewControl, {FleetMonitoringFiltersSortAndPagination} from "./ViewControl/ViewControl";
import DeviceDetailsRequestModel from "@/models/server/Requests/DeviceDetailsRequestModel";
import {ch} from "@renta-apps/athenaeum-react-common";
import UserContext from "@/models/server/UserContext";
import {PageContainer} from "@renta-apps/athenaeum-react-components";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";
import {getContractData} from "@/services/CompanyService";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import RequestReturnModal from "@/pages/FleetMonitoring/Modals/RequestReturnModal/RequestReturnModal";
import {useDebounceLoad} from "./Hooks/useDebounceLoad";
import {useFiltersState} from "./Hooks/useFiltersState";

export interface INewAlarm {
    deviceId: string,
    configuredAlert: AlarmModel,
}

export interface IDeletedAlarm {
    deviceId: string,
    alarmId: string,
}

interface IFleetMonitoringContainerProps {
    urlParams?: FleetMonitoringFiltersAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    isSubscribeToAlertsModalOpen: boolean;
    isReturnRequestModalOpen: boolean;
    onFilterParamsChange: (filters: FleetMonitoringFiltersAndPagination) => void;
    onDeviceBottomSheetVisibilityChange: (isVisible: boolean) => void;
    onSubscribeToAlertsModalVisibilityChange: (isVisible: boolean) => void;
    onReturnRequestModalVisibilityChange: (isVisible: boolean) => void;
    onReturnRequestBottomButtonEnableChange: (isEnabled: boolean) => void;
}

export type DeviceGridModel = DeviceModel & { itemKey: string };

export const getItemKey = (item: { rentaId: string, contractNumber: string | null, orderLineId: string | null }) => `${item.rentaId}_${item.contractNumber}_${item.orderLineId}`;

export class FleetMonitoringFiltersAndPagination {
    constructor(
        public companies?: SelectedFilter[],
        public constructionSites?: SelectedFilter[],
        public depots?: SelectedFilter[],
        public productGroups?: SelectedFilter[],
        public statuses?: SelectedFilter[],
        public deviceNames?: string[],
        public pageNumber?: number,
        public pageSize?: number,
        public sortBy?: string,
        public sortOrder?: string,
    ) {
    }
}

const defaultPageNumber = 1;
const defaultPageSize = 25;

const FleetMonitoringContainer: React.FC<IFleetMonitoringContainerProps> = ({
    urlParams,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    isSubscribeToAlertsModalOpen,
    isReturnRequestModalOpen,
    onFilterParamsChange,
    onDeviceBottomSheetVisibilityChange,
    onSubscribeToAlertsModalVisibilityChange,
    onReturnRequestModalVisibilityChange,
    onReturnRequestBottomButtonEnableChange,
}) => {
    const USER_DATA_STORAGE_KEY = useMemo(() => {
        return `FleetMonitoringFilters-${userRoleContractId}-${userRoleConstructionSiteId}`;
    }, []);

    const createInitialFilters = useCallback((): FleetMonitoringFiltersSortAndPagination => {
        let initialParams = urlParams;
        if (!initialParams) {
            initialParams = UserInteractionDataStorage.get(USER_DATA_STORAGE_KEY, new FleetMonitoringFiltersAndPagination(), DataStorageType.Page);
        }

        return {
            companies: initialParams!.companies || [],
            constructionSites: initialParams!.constructionSites || [],
            depots: initialParams!.depots || [],
            deviceNames: initialParams!.deviceNames || [],
            productGroups: initialParams!.productGroups || [],
            sortBy: initialParams!.sortBy ?? 'IdleDays',
            sortOrder: initialParams!.sortOrder ?? 'Desc',
            statuses: initialParams!.statuses || [],
            pageNumber: initialParams!.pageNumber === undefined ? defaultPageNumber : Number(initialParams!.pageNumber),
            pageSize: initialParams!.pageSize === undefined ? defaultPageSize : Number(initialParams!.pageSize),
        };
    }, []);

    const [
        filtersSortAndPagination,
        setFilterFromDropdownItems,
        setFiltersPartial,
        resetFilters,
    ] = useFiltersState(createInitialFilters);

    // Other state variables.
    const [filteredDevices, setFilteredDevices] = useState<DeviceGridModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedDevicesKeys, setSelectedDevicesKeys] = useState<Set<string>>(new Set());
    const [selectedSingleDevice, setSelectedSingleDevice] = useState<DeviceModel | null>(null);
    const [selectedDevices, setSelectedDevices] = useState<DeviceModel[]>([]);
    const [totalItemCount, setTotalItemCount] = useState<number>(1);
    const [scrollToTop, setScrollToTop] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const dataGridRef = useRef<HTMLDivElement>(null);
    const currentRequestIdRef = useRef<string | null>(null);

    const userContext = ch.getContext() as UserContext;

    const [userCompaniesList, setUserCompaniesList] = useState<IDropdownItem[]>([]);

    useEffect(() => {
        loadContractDataList(userRoleContractId, userRoleIsAdmin).catch();
    }, []);

    const sortByItems: IDropdownItem[] = [
        {
            name: Localizer.fleetMonitoringPageFiltersSortByIdle,
            value: "IdleDays",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByAlerts,
            value: "AlertsCount",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByBattery,
            value: "BatteryLevel",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByFuel,
            value: "FluidLevel",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByName,
            value: "Name",
        },
        {
            name: Localizer.fleetMonitoringPageFiltersSortByReturnPickupTime,
            value: "IsReturnRequested",
        },
    ];

    const handleOnGearButtonClick = (device: DeviceModel): void => {
        setSelectedSingleDevice(device);
        onSubscribeToAlertsModalVisibilityChange(true);
    };

    const handleSubscribeToAlertsModalClose = (): void => {
        setSelectedSingleDevice(null);
        onSubscribeToAlertsModalVisibilityChange(false);
    };

    const handleOnReturnButtonClick = (device: DeviceModel): void => {
        setSelectedSingleDevice(device);
        onReturnRequestModalVisibilityChange(true);
    };

    const handleRequestReturnModalClose = (): void => {
        setSelectedSingleDevice(null);
        onReturnRequestModalVisibilityChange(false);
    };

    const handleOnDebugButtonClick = async (assetId: string | null, rentaId: string): Promise<void> => {
        try {
            const data = await getDeviceDebugDetailsAsync(assetId, rentaId);

            console.info(JSON.parse(data));
            window.alert("Check console log for data");
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    useEffect(() => {
        const selectedDevices = filteredDevices.filter(device => selectedDevicesKeys.has(device.itemKey));
        setSelectedDevices(selectedDevices);
        const anyDeviceNotReturnRequested = selectedDevices.some(device => !device.isReturnRequested);
        const constructionSiteIdsSet = new Set(selectedDevices.map(device => device.constructionSiteId));
        onReturnRequestBottomButtonEnableChange(constructionSiteIdsSet.size === 1 && !constructionSiteIdsSet.has(null) && anyDeviceNotReturnRequested);
        onDeviceBottomSheetVisibilityChange(selectedDevices.length > 0);
    }, [selectedDevicesKeys, filteredDevices]);

    const handleOnItemClick = (selectedDevices: string[]): void => {
        setSelectedDevicesKeys(new Set(selectedDevices));
    };

    const handleOnPageNumberChange = (pageNumber: number) => {
        setScrollToTop(true);
        setFiltersPartial({pageNumber});
    };

    const handleOnPageSizeChange = (pageSize: number) => {
        setScrollToTop(true);
        setFiltersPartial({pageSize});
    };

    const saveUserSelection = (filters: FleetMonitoringFiltersSortAndPagination) => {
        onFilterParamsChange(filters);

        UserInteractionDataStorage.set(USER_DATA_STORAGE_KEY, filters, DataStorageType.Page);
    };

    const loadDeviceDetails = async (item: DeviceModel): Promise<DeviceDetails> => {
        try {
            return await getDeviceDetailsAsync(
                item.assetId,
                item.rentaId,
                item.contractNumber,
                item.orderLineId,
                userRoleIsAdmin ? null : userRoleConstructionSiteId,
                userRoleIsAdmin ? null : userRoleContractId);
        } catch (error) {
            console.error('Error loading device details:', error);
            throw error;
        }
    };

    const loadContractDataList = async (userRoleContractId: string | null, userRoleIsAdmin: boolean) => {
        if (userRoleIsAdmin || !userRoleContractId) {
            setUserCompaniesList([]);
            return;
        }

        try {
            const contractData = await getContractData(userRoleContractId);
            const companiesList = [mapCompanyToDropdownItem(contractData.organizationContract!)]
                .concat(contractData.organizationContract?.children?.map(mapCompanyToDropdownItem) ?? []);

            setUserCompaniesList(companiesList);
        } catch (error) {
            console.error('Error loading contract data:', error);
        }
    };

    const mapCompanyToDropdownItem = (company: OrganizationContractModel): IDropdownItem => {
        return {
            name: `${company.name ?? ''} ${company.customerNumber ? `(${company.customerNumber})` : ''}`,
            value: company.contractId,
        };
    };

    const loadDevicesDetails = async (devices: DeviceDetailsRequestModel[]): Promise<DeviceDetails[]> => {
        try {
            return await getDevicesDetailsAsync(devices, userRoleConstructionSiteId, userRoleContractId);
        } catch (error) {
            console.error('Error loading devices details:', error);
            throw error;
        }
    };

    const loadDevices = async (filters: FleetMonitoringFiltersSortAndPagination, requestId: string) => {
        setIsLoading(true);

        try {
            // Map the string value to the enum value
            const sortDirectionEnum = SortDirection[filters.sortOrder as keyof typeof SortDirection];

            const filteredDevices: IPagedList<DeviceModel> = await getDevicesPagedListAsync(
                filters.pageNumber,
                filters.pageSize,
                (userRoleIsAdmin || !userRoleContractId) ? undefined : userRoleContractId,
                (userRoleIsAdmin || !userRoleConstructionSiteId) ? undefined : userRoleConstructionSiteId,
                filters.sortBy!,
                sortDirectionEnum,
                filters.deviceNames,
                filters.companies,
                filters.constructionSites,
                filters.depots,
                filters.productGroups,
                filters.statuses,
            );

            // Ensure only the latest request's result is applied.
            if (currentRequestIdRef.current !== requestId) {
                return;
            }

            const {totalItemCount, items} = filteredDevices;
            setFilteredDevices(items.map(item => ({...item, itemKey: getItemKey(item)})));
            setSelectedDevicesKeys(new Set());
            setTotalItemCount(totalItemCount);

        } catch (error) {
            console.error('Error loading devices:', error);
        } finally {
            setIsLoading(false);
            setInitialLoad(false);
            if (scrollToTop && dataGridRef.current) {
                setScrollToTop(false);
                // 45 - height of the top nav bar
                window.scrollTo({top: dataGridRef.current.offsetTop - 45, behavior: 'smooth'});
            }
        }
    };

    const removeDeletedAlarms = (deletedAlarms: IDeletedAlarm[]) => {
        // Ensure that the state updates are processed sequentially and the changes are not overwritten
        setFilteredDevices(prevFilteredDevices => {
            const updatedFilteredDevices = [...prevFilteredDevices];

            deletedAlarms.forEach(({deviceId, alarmId}) => {
                const index = updatedFilteredDevices.findIndex(device => device.rentaId === deviceId);

                if (index !== -1) {
                    // If the device is found, merge the new alarm's configuredAlert with its existing configuredAlerts
                    updatedFilteredDevices[index] = {
                        ...updatedFilteredDevices[index],
                        configuredAlerts: updatedFilteredDevices[index].configuredAlerts.filter(alert => alert.id !== alarmId)
                    };
                }
            });

            return updatedFilteredDevices;
        });
    };

    const saveNewAlarms = (newAlarms: INewAlarm[]) => {
        setFilteredDevices(prevFilteredDevices => {
            // Ensure that the state updates are processed sequentially and the changes are not overwritten
            const updatedFilteredDevices = [...prevFilteredDevices];

            newAlarms.forEach(({deviceId, configuredAlert}) => {
                const index = updatedFilteredDevices.findIndex(device => device.rentaId === deviceId);

                if (index !== -1) {
                    // If the device is found, merge the new alarm's configuredAlert with its existing configuredAlerts
                    updatedFilteredDevices[index] = {
                        ...updatedFilteredDevices[index],
                        configuredAlerts: [...updatedFilteredDevices[index].configuredAlerts, configuredAlert]
                    };
                }
            });

            return updatedFilteredDevices;
        });
    };

    useDebounceLoad(filtersSortAndPagination, currentRequestIdRef, {
        saveUserSelection,
        loadData: loadDevices,
        initialLoad,
    });

    return (
        // I guess we need the PageContainer in order to display alert notifications (not device alerts) properly, otherwise they would be displayed in the RequestReturnModal
        <PageContainer className={styles.pageContainer}>
            <div className={styles.devices}>
                <div id="container" className={styles.container}>
                    <ViewControl userRoleConstructionSiteId={userRoleConstructionSiteId}
                                 userRoleContractId={userRoleContractId}
                                 userRoleIsAdmin={userRoleIsAdmin}
                                 filters={filtersSortAndPagination}
                                 sortByItems={sortByItems}
                                 userCompaniesList={userCompaniesList}
                                 mode="devices"
                                 onDropdownFieldChanged={setFilterFromDropdownItems}
                                 onFiltersChanged={setFiltersPartial}
                                 onFiltersReset={resetFilters}
                    />

                    <DeviceGrid data={filteredDevices}
                                selectedItemKeys={selectedDevicesKeys}
                                deviceDetails={(item: DeviceModel) => loadDeviceDetails(item)}
                                devicesDetails={(devices: DeviceDetailsRequestModel[]) => loadDevicesDetails(devices)}
                                onGearButtonClick={(device) => handleOnGearButtonClick(device)}
                                onReturnButtonClick={(device) => handleOnReturnButtonClick(device)}
                                onDebugClick={userRoleIsAdmin ? (assetId: string | null, rentaId: string) => handleOnDebugButtonClick(assetId, rentaId) : undefined}
                                onRowCheckboxClick={(selectedDevices) => handleOnItemClick(selectedDevices)}
                                isEasyPlusUser={userContext.isEasyPlusUser}
                                userRoleIsAdmin={userRoleIsAdmin}
                                isLoading={isLoading}
                                gridRef={dataGridRef}
                    />

                    <Pagination pageNumber={filtersSortAndPagination.pageNumber}
                                pageSize={filtersSortAndPagination.pageSize}
                                totalItemCount={totalItemCount}
                                onPageNumberChange={(pageNumber: number) => handleOnPageNumberChange(pageNumber)}
                                onPageSizeChange={(pageSize: number) => handleOnPageSizeChange(pageSize)}
                    />
                </div>
            </div>

            <SubscribeToAlertsModal selectedDevices={selectedSingleDevice ? [selectedSingleDevice] : selectedDevices}
                                    saveNewAlarms={(newAlarms: INewAlarm[]) => saveNewAlarms(newAlarms)}
                                    removeDeletedAlarms={(deletedAlarms: IDeletedAlarm[]) => removeDeletedAlarms(deletedAlarms)}
                                    isOpen={isSubscribeToAlertsModalOpen}
                                    onClose={handleSubscribeToAlertsModalClose}
            />

            <RequestReturnModal isOpen={isReturnRequestModalOpen}
                                onClose={handleRequestReturnModalClose}
                                isEasyPlusUser={userContext.isEasyPlusUser}
                                selectedDevices={selectedSingleDevice ? [selectedSingleDevice] : selectedDevices.filter(device => !device.isReturnRequested)}
            />
        </PageContainer>
    );
};

export default FleetMonitoringContainer;