import React from "react";
import Localizer from "@/localization/Localizer";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BasePageParameters} from "@renta-apps/athenaeum-react-common";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import NoAccess from "@/components/NoAccess/NoAccess";
import ConstructionSiteDetailsContent from "./ConstructionSiteDetailsContent";

export interface IConstructionSiteDetailsParams extends BasePageParameters {
    /**
     * Id of the Construction Site to display.
     */
    id: string;

    selectedTab?: string;
}

interface IConstructionSiteDetailsState {
    constructionSite: ConstructionSiteModel | null;
    canManageUsers: boolean;
    hasAccess: boolean | null;
}

interface IConstructionSiteDetailsData {
    constructionSite: ConstructionSiteModel;
    canManageUsers: boolean;
    hasAccess: boolean;
}

class ConstructionSiteDetails extends AuthorizedPage<IConstructionSiteDetailsParams, IConstructionSiteDetailsState> {
    public state: IConstructionSiteDetailsState = {
        constructionSite: null,
        canManageUsers: false,
        hasAccess: null,
    };

    private get constructionSite(): ConstructionSiteModel | null {
        return this.state.constructionSite;
    }

    private get canManageUsers(): boolean {
        return this.state.canManageUsers;
    }

    private get hasAccess(): boolean | null {
        return this.state.hasAccess;
    }

    private get canSeeInvoices(): boolean {
        const {isConstructionSiteMainUser, isSingleConstructionSiteMainUserUser, isCompanyMainUser, isAdmin} = this.userContext;
        return isConstructionSiteMainUser || isSingleConstructionSiteMainUserUser || isCompanyMainUser || isAdmin;
    }

    private get constructionSiteId(): string | undefined {
        return this.typedParameters?.id;
    }

    protected get title(): string {
        return Localizer.breadCrumbConstructionsiteDetails;
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        if (!this.constructionSiteId) {
            await this.toFrontPageAsync();
        } else {
            const data: IConstructionSiteDetailsData = await this.postAsync("/api/ConstructionSites/GetConstructionSiteDetails", this.constructionSiteId);

            if (this.isMounted) {
                this.setState({
                    constructionSite: data.constructionSite,
                    canManageUsers: data.canManageUsers,
                    hasAccess: data.hasAccess,
                });
            }
        }
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized || !this.constructionSiteId) {
            return null;
        }

        if (this.hasAccess === false) {
            return <NoAccess type="constructionSite"/>;
        }

        return (
            <ConstructionSiteDetailsContent constructionSite={this.constructionSite}
                                            constructionSiteId={this.constructionSiteId}
                                            route={this.route}
                                            selectedTab={this.typedParameters?.selectedTab}
                                            canSeeInvoices={this.canSeeInvoices}
                                            canManageUsers={this.canManageUsers}
                                            co2FeatureEnabled={UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCo2Enabled)}
                                            servicesViewEnabled={UnleashHelper.isEnabled(RentaEasyConstants.featureFlagServicesView)}
                                            statusPageEnabled={UnleashHelper.isEnabled(RentaEasyConstants.featureFlagConstructionSiteStatusPage)}
                                            powerPageEnabled={UnleashHelper.isEnabled(RentaEasyConstants.featureFlagConstructionSitePowerPage)}
                                            environmentControlFeatureEnabled={UnleashHelper.isEnabled(RentaEasyConstants.featureFlagEnvironmentControl)}
                                            renderPriceAlert={() => this.renderContractPricesAlert()}
            />
        );
    }
}

export default ConstructionSiteDetails;