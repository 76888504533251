import React from "react";
import {PageContainer, PageHeader, PageRow} from "@renta-apps/athenaeum-react-components";
import Localizer from "@/localization/Localizer";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import {BasePageParameters, ch} from "@renta-apps/athenaeum-react-common";
import styles from "./ApplyCompanyAccount.module.scss";
import ApplyCompanyAccountForm from "@/pages/ApplyCompanyAccount/ApplyCompanyAccountForm";

export interface IApplyCompanyAccountParams extends BasePageParameters {
}
export default class ApplyCompanyAccount extends AuthorizedPage<IApplyCompanyAccountParams,  {}> {
    protected get title(): string {
        return Localizer.applyCompanyAccountTitle;
    }

    public render(): React.ReactNode {
        if (!this.isAuthorized) {
            return null;
        }

        return (
            <PageContainer id={styles.applyCompanyAccountPage}
                           className={styles.applyCompanyAccountPage}
            >
                <PageHeader title={this.title}
                            subtitle={Localizer.applyCompanyAccountHelpText}
                />

                <PageRow>
                    <ApplyCompanyAccountForm/>
                </PageRow>
            </PageContainer>
        );
    }
}