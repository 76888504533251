import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";
import {Method} from "cypress/types/net-stubbing";



describe('Desktop - Easy plus access - power page test', () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
            );
        });
    });

    Cypress.on('uncaught:exception', (err, runnable) => {
        return false;
    });

    function intercept(func: { path: string; method: string }, file: string): void {
        cy.intercept(func.method as Method, func.path, {fixture: file});

    }

    it('Give user has access to data - Renders charts with localized data', () => {

        const {getPowerSupplyDailyMaxCurrent, getConstructionSitesPowerInfo, getConstructionSitesPowerConsumptionByExternalId} = pageData().constructionSite.power.routes;

        intercept(getPowerSupplyDailyMaxCurrent, 'power/getPowerSupplyDailyMaxCurrent.json')
        intercept(getConstructionSitesPowerInfo, 'power/getConstructionSitesPowerInfoSuccess.json')
        intercept(getConstructionSitesPowerConsumptionByExternalId, 'power/getConstructionSitesPowerConsumptionByExternalId.json')

        pageData().constructionSite.power.tab().click();

        cy.get('#easy-plus-panel-power-container').should("not.exist");

        cy.get("#power-no-device-panel-container").should("not.exist");

        cy.get("#power-consumption-chart-container").should("exist");

        cy.get("#power-consumption-chart-container").find('h2').should('have.text', Localizer.constructionSiteDetailsPowerConsumptionTitle);

        cy.get("#power-consumption-chart-container").find('[data-cy=renta-line-chart-container]').should('exist');

        cy.get("[data-cy=max-current-chart-container]").should("exist");

        cy.get("[data-cy=max-current-chart-container]").first().find('h2').should('have.text', Localizer.constructionSiteDetailsPowerMaxCurrentTitle);

        cy.get("[data-cy=max-current-chart-container]").first().find('[data-cy=renta-line-chart-container]').should('exist');


    });

    it('Give user has no power data - Displays friendly no devices info panel', () => {
        const {getConstructionSitesPowerInfo, getConstructionSitesPowerConsumptionByExternalId} = pageData().constructionSite.power.routes;
        intercept(getConstructionSitesPowerInfo, 'power/nodata/getConstructionSitesPowerInfoNoData.json')
        intercept(getConstructionSitesPowerConsumptionByExternalId, 'power/nodata/getConstructionSitesPowerConsumptionByExternalId.json')

        pageData().constructionSite.power.tab().click();

        cy.get('#easy-plus-panel-power-container').should("not.exist");

        cy.get("#power-no-device-panel-container").should("exist");

        cy.get("#power-consumption-chart-container").should("not.exist");

        cy.get('[data-cy=text-content]')
            .find('span')
            .eq(0)
            .should('contain', Localizer.constructionSiteDetailsPowerNoDevicesInfoPanelLine1);

        cy.get('[data-cy=text-content]')
            .find('span')
            .eq(1)
            .should('contain', Localizer.constructionSiteDetailsPowerNoDevicesInfoPanelLine2);

        cy.get('[data-cy=images]')
            .find('img')
            .should('have.length', 2);

    });
});

describe('Desktop - No easy plus - power page test', () => {

    beforeEach(() => {
        cy.session(['company_role_session_no_plus_ever'], () => {
            cy.loginAndSelectCompanyRole("Matti.Salmiakki@weare.fi", "tmi jukka wilska");
        });

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
            );
        });
    });


    it('Give user has no easy plus access - Displays easy plus needed to view data', () => {
        const {getConstructionSitesPowerInfo, getConstructionSitesPowerConsumptionByExternalId} = pageData().constructionSite.power.routes;
        cy.intercept(getConstructionSitesPowerInfo.method as Method, getConstructionSitesPowerInfo.path, {fixture: 'power/nodata/getConstructionSitesPowerInfoNoData.json'});
        cy.intercept(getConstructionSitesPowerConsumptionByExternalId.method as Method, getConstructionSitesPowerConsumptionByExternalId.path, {fixture: 'power/nodata/getConstructionSitesPowerConsumptionByExternalId.json'});

        pageData().constructionSite.power.tab().click();

        cy.get("#power-consumption-chart-container")
            .should("not.exist");

        cy.get("#power-no-device-panel-container")
            .should("not.exist");

        cy.get('#easy-plus-panel-power-container')
            .should("exist");

        cy.get('[data-cy=text-content]')
            .find('span')
            .eq(0)
            .should('contain', Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine1);

        cy.get('[data-cy=text-content]')
            .find('span')
            .eq(1)
            .should('contain', Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine2);

        cy.get('[data-cy=text-content]')
            .find('span')
            .eq(2)
            .should('contain', Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine3);

        cy.get('[data-cy=images]')
            .find('img')
            .should('have.length', 2);

        cy.get('button')
            .should('contain', Localizer.easyPlusNewSubscriptionInfoButton);

    });

});