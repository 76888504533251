import Localizer from "../../../localization/Localizer";
import GridHelper from "@/helpers/CypressGridHelper";

// @ts-ignore
describe('Desktop - Admin user, admin products tests', () => {

    before(() => {

    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('Verify data on page', () => {
        loadPage();
        verifyTableHasData();
    });

    it('Can filter data', () => {
        loadPage();
        verifyTableHasData();

        cy.get("#admin_products_keyword").type("Betonimylly 10l");

        cy.get("#admin_products_search_button").click();

        GridHelper.getGridVisibleRows("#table_admin_products")
            .should("have.length", 2);
    });

    function loadPage() {
        cy.intercept('POST', 'api/Admin/GetAllProducts').as('getAllProducts');

        cy.visit(Localizer.pageRoutesAdminProducts);

        cy.waitSuccess('@getAllProducts');
    }

    function verifyTableHasData() {
        cy.get("#table_admin_products")
            .find("tbody")
            .find("tr")
            .should("have.length.above", 3);
    }

});