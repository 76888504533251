import Localizer from "@/localization/Localizer";
import * as path from "path";
import GridHelper from "@/helpers/CypressGridHelper";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

context("Desktop - Authenticated ContractDetails tests", () => {
    before(() => {
    });

    describe("Normal tests", () => {

        beforeEach(() => {
            cy.useSharedAdminUserSession();
            cy.visit('frontpage');

        });

        it('As admin should redirect to 404 when contract is deleted', () => {

            onRedirect(() => cy.visit(`${Localizer.pageRoutesContractDetails}?id=ba8d6b47-5ef1-49c9-bd86-74e0e880ae03`));

            cy.get('h1')
                .should('contain', Localizer.companyDetailsCannotBeAccessedTitle);

            cy.get('div')
                .should('contain', Localizer.companyDetailsCannotBeAccessedDescription);

            cy.get('div')
                .should('contain', Localizer.companyDetailsCannotBeAccessedWhatNext);

            cy.get('li')
                .should('contain', Localizer.genericTryLoggingInAgain);

            cy.get('a')
                .should('contain', Localizer.genericContactSupport);

        });
    });

    describe("Feature flag specific tests", () => {

        beforeEach(() => {
            cy.useSharedAdminUserSession();
            pageData().common.interceptFeatureFlags([
                {flagName: RentaEasyConstants.featureFlagCo2Enabled, enabled: false},
                {flagName: RentaEasyConstants.featureFlagPlus, enabled: true},
            ]);
            cy.visit('frontpage');


        });

        it('As admin go to contract details page, check buttons and tabs work', () => {


            onRedirect(() => pageData().frontPage.widgets.admin().click());

            onRedirect(() =>
                executeWithIntercept(
                    () => pageData().admin.links.companies().click(),
                    [pageData().admin.companies.routes.getCompaniesPagedList as RouteData]
                ));


            cy.get('#admin_companies_search')
                .type("wilska");

            executeWithIntercept(
                () => cy.get('#admin_companies_search_submit').click(),
                [pageData().admin.companies.routes.getCompaniesPagedList as RouteData]
            );

            onRedirect(() =>
                executeWithIntercept(() =>
                        GridHelper.getGridContentByRowAndCell(pageData().admin.companies.tableName(), 0, 1)
                            .find('[class*=athenaeum-grid-link]')
                            .first()
                            .click(),
                    [
                        pageData().company.routes.getContractData as RouteData,
                        pageData().constructionSites.routes.sitesForContract as RouteData
                    ]
                ));

            pageData().common.waitTopNavRender();

            checkButtons();

            checkUsersTab();

            checkOrdersTab();

            checkReturnsTab();

            cy.get('#emissionsTab')
                .should('not.exist');

            checkEasyPlusTab();

        });


    });


    function verifyRentPageIsOpenAndGoBack() {
        cy.url().should('include', `${Localizer.pageRoutesRent}`);
        cy.contains(`${Localizer.categoriesShowCustomers} TMI JUKKA WILSKA ${Localizer.genericPrices}`);

        pageData().rent.firstCategoryItem();

        onRedirect(() => executeWithIntercept(() => cy.go('back'),
            [pageData().company.routes.getContractData as RouteData]
        ));

    }

    function checkButtons() {
        onRedirect(() =>
            executeWithIntercept(() => cy.get('#seeCustomerPricesButton').click(),
                [pageData().admin.routes.setTemporaryContractId as RouteData])
        );

        verifyRentPageIsOpenAndGoBack();

        cy.get('#returnItemForCustomerButton').click();
        cy.get('#returnItemsInfoModal').find('i').click();


        onRedirect(() =>
            executeWithIntercept(() => cy.get('#makeOrderAsCustomerButton').click(),
                [pageData().admin.routes.setTemporaryContractId as RouteData])
        );

        verifyRentPageIsOpenAndGoBack();

        cy.get('#deleteContractButton').trigger('click');
        cy.get('#confirmation-modal-cancel').click();

        cy.get('#showActiveSitesSwitch').click();
        cy.get('[class^=ConstructionSitesList_constructionSiteInfoBox]').should('have.length.greaterThan', 0);
    }

    function checkOrdersTab() {
        cy.get('#tab_ordersTab').find('a').click();

        cy.url().should('contain', `selectedTab=${Localizer.ordersPageOrders.toLowerCase()}`);

        cy.get('#order_emails').type('test@test');
        cy.get('#order_emails_button').should('be.disabled');

        cy.get('#order_emails').type('.com');
        cy.get('#order_emails_button').should('not.be.disabled');
    }

    function checkReturnsTab() {
        cy.get('#tab_returnsTab').find('a').click();

        cy.url().should('contain', `selectedTab=${Localizer.breadCrumbReturns.toLowerCase()}`);
    }

    function checkUsersTab() {
        cy.get('#tab_usersTab').find('a').click();

        executeWithIntercept(() => pageData().inviteUser.toolbar.buttons.inviteUsers().click(),
            [pageData().inviteUser.routes.getInvitationPageData as RouteData]
        );

        cy.url().should('include', `${Localizer.pageRoutesInviteUser}?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409`);
        onRedirect(() => cy.go('back'));

        executeWithIntercept(() => pageData().inviteUser.toolbar.buttons.downloadCsv().click(),
            [pageData().inviteUser.routes.downloadCsv as RouteData]
        );

        const folder = Cypress.config('downloadsFolder');
        const filePath = path.join(folder, 'Renta_Easy_Users.csv');
        cy.readFile(filePath).end();

        cy.get('#table_users_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should('have.length', 7);

        cy.get('#searchInput').type('Uuno');

        executeWithIntercept(() => cy.get('#submitButton').click(),
            [pageData().company.routes.usersPagedList as RouteData]
        );

        cy.get('#table_users_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should('have.length', 1);

        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should("have.length.at.least", 8);
    }

    function checkEasyPlusTab() {

        pageData().company.easyPlus.tab().find('a').click();

        cy.url().should('contain', `selectedTab=easyplus`);

        cy.get('div[data-cy="easyPlusInfoPanelLine1"]').should('contain', Localizer.easyPlusInfoPanelLine1);

        cy.get('div[data-cy="easyPlusInfoPanelLine2"]').should('contain', Localizer.easyPlusInfoPanelLine2);

        cy.get('div[data-cy="easyPlusInfoPanelLine3"]').should('contain', Localizer.easyPlusInfoPanelLine3);

        const gridRow = 6;

        // Invite a user to EasyPlus.
        executeWithIntercept(() => {
                GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 3)
                    .find(pageData().company.easyPlus.switchToggler)
                    .first()
                    .click();

                pageData().company.easyPlus.confirmationDialog().should('not.exist');
            },
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridInvited);

        // Cancel the invitation.
        executeWithIntercept(() =>
                GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 3)
                    .find(pageData().company.easyPlus.switchToggler)
                    .first()
                    .click(),
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, gridRow, 2)
            .children('div')
            .eq(0)
            .should('contain', Localizer.easyPlusManagementUsersGridNotActive);

        // user invoice reference
        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, 0, 1)
            .find('button')
            .click();

        executeWithIntercept(() => {
                pageData().company.easyPlus.invoiceReferenceModal().should('exist');
                pageData().company.easyPlus.invoiceReferenceModal().find('input').type('reference');
                pageData().company.easyPlus.invoiceReferenceModal().find('#save-invoice-reference-button').click();
            },
            [
                pageData().company.routes.usersPagedList as RouteData,
            ]
        );

        pageData().company.easyPlus.invoiceReferenceModal().should('not.exist');

        GridHelper.getGridContentByRowAndCell(pageData().company.easyPlus.tableName, 0, 1)
            .find('div')
            .should('contain', 'reference');
    }
});