import Localizer from "../../../localization/Localizer";

describe('Desktop - Logged in company user confirmed order tests', () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('Open order details page and verify proper widgets are visible', () => {

        cy.visit('/orderdetails/A6647D65-4031-46B1-BB8C-4C14A3848C8B?orderConfirmation=true');

        cy.get('div.athenaeum-alert-alert').contains(Localizer.shoppingCartSentShort);

        cy.get('#editModeButton').contains(Localizer.catalogEditProductEditButton);

        cy.get('#saveButton').should('not.exist');

        cy.get('h3').contains(Localizer.orderInfoText);

        cy.get('span[data-status="Ordered"]').contains(Localizer.enumOrderStatusConfirmed);

        cy.get('#editModeButton').click();

        cy.get('#editModeButton').contains(Localizer.formCancel);

        cy.get('#saveButton').contains(Localizer.genericSave);
    });

});