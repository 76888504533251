import $ from "jquery";
import {ILanguage, IPagedList, Utility} from "@renta-apps/athenaeum-toolkit";
import {AlertModel, ApiProvider, ApplicationContext, ch, IBaseComponent, PageRoute, WebApplicationType} from "@renta-apps/athenaeum-react-common";
import SaveProductToCartRequest from "@/models/server/SaveProductToCartRequest";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import PageDefinitions from "@/providers/PageDefinitions";
import DepotModel from "@/models/server/DepotModel";
import {OrganizationContractModel} from "@/models/server/OrganizationContractModel";
import {ConstructionSiteModel} from "@/models/server/ConstructionSiteModel";
import ShoppingCartModel from "@/models/server/ShoppingCartModel";
import TagManager from "react-gtm-module";
import PendingInvitationOverviewModel from "@/models/server/PendingInvitationOverviewModel";
import PendingInvitationsRequest from "@/models/server/Requests/PendingInvitationsRequest";
import Localizer from "@/localization/Localizer";
import {CookieConsentType, IconSize, IFooterLink, ISocialNetworkIconLink} from "@renta-apps/athenaeum-react-components";
import ProductModel from "@/models/server/ProductModel";
import CompanyNameModel from "@/models/CompanyNameModel";
import Co2DefinitionModel from "@/models/server/Co2DefinitionModel";
import UserContext from "@/models/server/UserContext";
import {ContractConstructionSiteUserModel} from "@/models/server/ContractConstructionSiteUserModel";
import ImageProcessor from "@/helpers/ImageProcessor";
import PreSignedUrlModel from "@/models/server/PreSignedUrlModel";
import ImageUploadResultModel from "@/models/ImageUploadResultModel";
import CreatePreSignedUrlRequest from "@/models/server/Requests/CreatePreSignedUrlRequest";
import InviteUserRequest from "@/models/server/Requests/InviteUserRequest";
import LocationRequest from "@/models/server/Requests/LocationRequest";
import InviteUserPageDataResponse from "@/models/server/Responses/InviteUserPageDataResponse";
import EditContractRequest from "@/models/server/Requests/EditContractRequest";
import AlarmModel from "@/models/server/AlarmModel";
import ListAlarmsRequest from "@/models/server/Requests/ListAlarmsRequest";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import GetRelatedProductsRequest from "@/models/server/GetRelatedProductsRequest";
import RelatedProductsResponse from "@/models/server/Responses/RelatedProductsResponse";

class RentaEasyController {

    private _initialized: boolean = false;
    private _initializing: boolean = false;

    public equipmentListSupportsMonthlyPrices(): boolean {
        return (ch.country === "fi" || ch.country === "pl");
    }

    public constructionSitePricesEnabledForEndUsers(): boolean {
        return (ch.isSweden || ch.isNorway);
    }


    public get supportedLanguages(): ILanguage[] {

        const supportedLanguages: string[] = (ch.getContext() as ApplicationContext).settings.supportedLanguages;
        return Localizer.supportedLanguages.filter(language => supportedLanguages.some(s =>
            language.code === s));

    }

    private static isMobileApp(): boolean {
        const href: string = document.location.href.toLowerCase();
        return href.endsWith("/mobile") || href.endsWith("/mobile/");
    }

    private static isPwaApp(): boolean {
        return (
            (window.matchMedia('(display-mode: standalone)').matches) ||
            ((window.navigator as any).standalone) ||
            (document.referrer.includes("android-app://"))
        );
    }

    private static getApplicationType(): WebApplicationType {
        return (RentaEasyController.isMobileApp())
            ? WebApplicationType.MobileApp
            : (RentaEasyController.isPwaApp())
                ? WebApplicationType.PwaApp
                : (ch.mobile)
                    ? WebApplicationType.MobileBrowser
                    : WebApplicationType.DesktopBrowser;
    }

    protected getEndpoint(): string {
        const timezoneOffset: number = Utility.timezoneOffset;
        const applicationType: WebApplicationType = RentaEasyController.getApplicationType();
        return "/Application?handler=GetContext&timezoneOffset=" + timezoneOffset + "&applicationType=" + applicationType;
    }

    public async initializeContextAsync(render: () => void): Promise<void> {
        const xsrfToken: string = $("input:hidden[name=\"__RequestVerificationToken\"]").val() as string;
        await ApiProvider.getAsync(this.getEndpoint(), null);
        const context: ApplicationContext = ch.getContext();
        context.xsrfToken = xsrfToken;
        render();
    }

    public async initializeAsync(): Promise<void> {
        EnumProvider.initialize();
        TransformProvider.initialize();
        PageDefinitions.initialize();
    }

    public async authorizeAsync(): Promise<void> {
        if (!this._initializing) {
            this._initialized = false;
            await this.initializeAsync();
        }
    }

    public async invokeStrongAuthentication(action: string, caller: IBaseComponent) {
        window.location.href = await ApiProvider.invokeWithForcedSpinnerAsync(() => ApiProvider.getAsync<string>(`/api/Application/${action}`, caller), true);
    }

    public async saveProductToShoppingCartAsync(request: SaveProductToCartRequest, caller: IBaseComponent): Promise<void> {
        await ApiProvider.postAsync("/api/ShoppingCart/SaveProductToCart", request, caller);
    }

    public async setOperatingForContract(contractId: string, caller: IBaseComponent): Promise<void> {
        await ApiProvider.postAsync(`/api/Admin/SetTemporaryContractId`, contractId, caller);
    }

    public async setOperatingForConstructionSite(constructionSiteId: string, caller?: IBaseComponent): Promise<void> {
        await ApiProvider.postAsync(`/api/Catalog/SetTemporaryConstructionSiteId`, constructionSiteId, caller);
    }

    public async clearOperatingForContract(caller: IBaseComponent): Promise<void> {
        await ApiProvider.postAsync("/api/Catalog/ClearTemporaryContractId", null, caller);
    }

    public async getCo2Definitions(entityId: string): Promise<Co2DefinitionModel[]> {
        return ApiProvider.postAsync(`/api/Admin/GetCo2Definitions`, entityId);
    }

    public async getContractDiscounts(contractId: string, productExternalIds: string[], caller: IBaseComponent): Promise<OrganizationContractDiscounts> {
        return await ApiProvider.postAsync(`/api/Companies/GetContractDiscounts?contractId=${contractId}`, productExternalIds, caller);
    }

    public async deletePendingInvitationAsync(invitationId: string, caller: IBaseComponent): Promise<AlertModel> {
        return await ApiProvider.postAsync("/api/Users/DeleteInvitation", invitationId, caller);
    }

    public async resendInvitationAsync(invitationId: string, caller: IBaseComponent): Promise<AlertModel> {
        return await ApiProvider.postAsync("/api/Users/ResendUserInvitation", invitationId, caller);
    }

    public async getInvitationPageData(request: InviteUserRequest): Promise<InviteUserPageDataResponse> {
        return await ApiProvider.postAsync("/api/Users/GetInvitationPageData", request);
    }

    public async getRelatedProductsAsync(request: GetRelatedProductsRequest): Promise<RelatedProductsResponse> {
        return await ApiProvider.postAsync("/api/Product/GetRelatedProducts", request);
    }

    public async getRentalOfficesAsync(caller: IBaseComponent): Promise<DepotModel[]> {
        return await ApiProvider.postAsync(`/api/Locations/GetAll`, null, caller);
    }

    public async getRentalOfficesByLocationAsync(request: LocationRequest, caller: IBaseComponent): Promise<DepotModel[]> {
        return await ApiProvider.postAsync(`/api/Locations/GetAll`, request, caller);
    }

    public async getContractAsync(contractId: string, caller: IBaseComponent): Promise<OrganizationContractModel | null> {
        return await ApiProvider.postAsync(`/api/Companies/GetContract?contractId=${contractId}`, null, caller);
    }

    public async getContractConstructionSitesUsersAsync(contractId: string, caller: IBaseComponent): Promise<ContractConstructionSiteUserModel[]> {
        return await ApiProvider.postAsync(`/api/Companies/GetConstructionSitesUsers?contractId=${contractId}`, null, caller);
    }

    public async getUnavailableProductsIdsAsync(depotId: string, caller: IBaseComponent): Promise<string[]> {
        return await ApiProvider.postAsync(`/api/Product/GetUnavailableProductsIds?rentalLocationId=${depotId}`, null, caller);
    }

    public async getContractsConstructionSitesAsync(contractId: string, caller: IBaseComponent): Promise<ConstructionSiteModel[]> {
        return await ApiProvider.postAsync(`/api/ConstructionSites/GetContractsConstructionSites`, contractId, caller);
    }

    public async clearShoppingCartAsync(caller?: IBaseComponent): Promise<void> {
        await ApiProvider.postAsync("/api/ShoppingCart/EmptyShoppingCart", null, caller);
    }

    public async getProductDetailsAsync(rentalObjectId: string): Promise<ProductModel> {
        return await ApiProvider.postAsync("/api/Product/FindProductByExternalId", rentalObjectId);
    }

    public async getShoppingCartAsync(caller?: IBaseComponent): Promise<ShoppingCartModel> {
        return await ApiProvider.postAsync("/api/ShoppingCart/GetShoppingCart", null, caller);
    }

    public async hasOrderedBeforeAsync(caller?: IBaseComponent): Promise<boolean> {
        return await ApiProvider.postAsync("/api/ShoppingCart/HasOrderedBefore", null, caller);
    }

    public async getPendingInvitationsAsync(request: PendingInvitationsRequest, caller: IBaseComponent): Promise<IPagedList<PendingInvitationOverviewModel>> {
        return await ApiProvider.postAsync("/api/Users/GetPagedPendingInvitations", request, caller);
    }

    public async getCustomerCompaniesAsync(caller: IBaseComponent): Promise<CompanyNameModel[]> {
        return await ApiProvider.postAsync("/api/Companies/ListCompanyNames", null, caller);
    }

    public async createPreSignedUrlForUploadAsync(request: CreatePreSignedUrlRequest): Promise<PreSignedUrlModel> {
        return await ApiProvider.postAsync("/api/Files/CreatePreSignedUrlForUpload", request, null);
    }

    public async createPreSignedUrlForDeleteAsync(imageReference: string): Promise<PreSignedUrlModel> {
        return await ApiProvider.postAsync("/api/Files/CreatePreSignedUrlForDelete", {imageReference}, null);
    }

    public async saveContractName(request: EditContractRequest, caller?: IBaseComponent | null): Promise<OrganizationContractModel> {
        return await ApiProvider.postAsync("/api/Admin/EditContract", request, caller);
    }

    public async fetchAlarms(request: ListAlarmsRequest): Promise<IPagedList<AlarmModel>> {
        return await ApiProvider.postAsync("/api/Admin/ListAlarms", request);
    }

    public async deleteAlarmAsync(id: string): Promise<void> {
        await ApiProvider.postAsync("/api/Alarm/DeleteAlarm", id);
    }

    public async get(path: string): Promise<Response> {
        const httpRequest = {
            url: path,
            method: "GET"
        } as RequestInit;
        const headers = new Headers();
        headers.set("Content-Type", "application/json");


        //XSRT Token
        const xsrfToken: string | null = ch.getXsrfToken();
        if (xsrfToken) {
            headers.set("xsrf-token", xsrfToken);
        }

        //Version
        headers.set("renta-version", ch.version);
        httpRequest.credentials = "include";

        httpRequest.headers = headers;
        return await fetch(path, httpRequest);
    }

    public get footerLinks(): IFooterLink[] {
        const context: ApplicationContext | null = ch.findContext();
        if (!context?.country) {
            return [];
        }

        const availableFooterLinks = [
            {label: Localizer.frontPageCapsText, urlLanguageItem: Localizer.footerRentaFrontPageUrlLanguageItemName},
            {label: Localizer.productsCapsText, urlLanguageItem: Localizer.footerRentaProductsUrlLanguageItemName},
            {label: Localizer.businessCapsText, urlLanguageItem: Localizer.footerRentaBusinessUrlLanguageItemName},
            {label: Localizer.staffCapsText, urlLanguageItem: Localizer.footerRentaStaffUrlLanguageItemName},
            {label: Localizer.workPlacesCapsText, urlLanguageItem: Localizer.footerRentaWorkPlacesUrlLanguageItemName},
            {label: Localizer.contactInfoCapsText, urlLanguageItem: Localizer.footerRentaContactInfoUrlLanguageItemName},
        ];
        const footerLinks: IFooterLink[] = [];
        availableFooterLinks.forEach(item => {
            const url = Localizer.getValue(context.country, item.urlLanguageItem);
            if (!url || url === item.urlLanguageItem || !url.startsWith("http")) {
                return;
            }

            footerLinks.push({
                label: item.label,
                href: url,
            });
        });

        return footerLinks;
    }

    public get footerSocialNetworkLinks(): ISocialNetworkIconLink[] {
        const context: ApplicationContext | null = ch.findContext();
        if (!context?.country) {
            return [];
        }

        const availableSocialNetworks = [
            {name: "Facebook", languageItem: Localizer.footerFacebookUrlLanguageItemName, iconName: "facebook-square"},
            {name: "Instagram", languageItem: Localizer.footerInstagramUrlLanguageItemName, iconName: "instagram-square"},
            {name: "YouTube", languageItem: Localizer.footerYouTubeUrlLanguageItemName, iconName: "youtube-square"},
            {name: "LinkedIn", languageItem: Localizer.footerLinkedInUrlLanguageItemName, iconName: "linkedin"},
            {name: "TikTok", languageItem: Localizer.footerTikTokUrlLanguageItemName, iconName: "tiktok", iconSize: IconSize.ExtraSmall},
        ];
        const socialNetworks: ISocialNetworkIconLink[] = [];
        availableSocialNetworks.forEach(item => {
            const url = Localizer.getValue(context.country, item.languageItem);
            if (!url || url === item.languageItem || !url.startsWith("http")) {
                return;
            }

            socialNetworks.push({
                name: item.name,
                href: url,
                iconName: item.iconName,
                iconSize: item.iconSize,
            });
        });

        return socialNetworks;
    }

    public get isApplicationContextAvailable(): boolean {
        return ch.findContext() !== null;
    }

    public get languages(): ILanguage[] {
        const context: ApplicationContext | null = ch.findContext();

        if (!context) {
            return [];
        }

        const supportedLanguages: string[] = context.settings.supportedLanguages;
        return Localizer.supportedLanguages.filter(language => supportedLanguages.some(s =>
            language.code === s));
    }

    public get serviceRequestEditorInputGroupingEnabled(): boolean {
        return true;
    }

    public get planEditorInputGroupingEnabled(): boolean {
        return true;
    }

    public getFavoriteOperationEndpoint(favorite: boolean): string {
        if (!favorite) {
            return "/api/Catalog/AddFavoriteProduct";
        }
        return "/api/Catalog/DeleteFavoriteProduct";
    }

    public isDevelopment(): boolean {
        return ch.getContext().isDevelopment;
    }

    public isStaging(): boolean {
        return ch.getContext().isStaging;
    }

    public isProduction(): boolean {
        return !(this.isDevelopment() || this.isStaging());
    }

    public get isEasyPlusUser(): boolean {
        return (ch.getContext() as UserContext).isEasyPlusUser;
    }

    public async uploadImageAsync(fileSrc: string, request: CreatePreSignedUrlRequest): Promise<ImageUploadResultModel | null> {
        // Shrink image for local use to ease browser's memory usage
        const shrunkImage = await ImageProcessor.shrinkImage(fileSrc);

        if (!shrunkImage.isSuccessful) {
            await ch.alertErrorAsync(Localizer.errorImageCorrupted, true);
            return null;
        }

        const preSignedUrl: PreSignedUrlModel = await this.createPreSignedUrlForUploadAsync(request);

        try {

            // Image data to blob
            let fileBlob: Blob = await fetch(fileSrc).then(r => r.blob());
            const requestOptions = {
                method: 'PUT',
                body: fileBlob,
            };

            // Upload image to S3
            let response: Response = await fetch(preSignedUrl.url, requestOptions);
            if (!response.ok) {
                await ch.alertErrorAsync(Localizer.errorImageUploadFailed, true);
                return null;
            }
        }
        catch (e) {
            await ch.alertErrorAsync(Localizer.errorImageUploadFailed, true);
            return null;
        }

        return {
            fileName: preSignedUrl.fileName,
            reference: preSignedUrl.reference,
            src: (shrunkImage.src ?? fileSrc),
        };
    }

    public async deleteImageAsync(imageReference: string): Promise<boolean> {
        const preSignedUrl: PreSignedUrlModel = await this.createPreSignedUrlForDeleteAsync(imageReference);

        try {
            const requestOptions = {
                method: 'DELETE',
            };

            // Upload image to S3
            let response: Response = await fetch(preSignedUrl.url, requestOptions);
            if (!response.ok && response.status !== 404) {
                await ch.alertErrorAsync(Localizer.errorImageDeletionFailed, true);
                return false;
            }
        }
        catch (e) {
            await ch.alertErrorAsync(Localizer.errorImageDeletionFailed, true);
            return false;
        }

        return true;
    }

    public async uploadPdfAsync(fileSrc: string, request: CreatePreSignedUrlRequest): Promise<ImageUploadResultModel | null> {
        const preSignedUrl: PreSignedUrlModel = await this.createPreSignedUrlForUploadAsync(request);

        try {

            // Pdf data to blob
            let fileBlob: Blob = await fetch(fileSrc).then(r => r.blob());
            const requestOptions = {
                method: 'PUT',
                body: fileBlob,
            };

            // Upload pdf to S3
            let response: Response = await fetch(preSignedUrl.url, requestOptions);
            if (!response.ok) {
                await ch.alertErrorAsync(Localizer.errorImageUploadFailed, true);
                return null;
            }
        }
        catch (e) {
            await ch.alertErrorAsync(Localizer.errorImageUploadFailed, true);
            return null;
        }

        return {
            fileName: preSignedUrl.fileName,
            reference: preSignedUrl.reference,
            src: (fileSrc),
        };
    }

    public loadOptionalScripts(acceptedType: CookieConsentType): void {
        if (acceptedType === CookieConsentType.All) {
            this.loadGoogleTagManagerScriptAsync();
            this.loadSite24X7ScriptAsync();
        }
    }

    public get isRedirectOutsideOfRegistrationFormPossible(): boolean {
        if (!this.isRegistrationContext) {
            return true;
        }
        return this.isAllObligatoryRegistrationFieldsFilled;
    }

    public isRegistrationCompleted(nextPage: PageRoute): boolean | null {
        const applicationContext: ApplicationContext | null = ch.findContext();
        if (applicationContext === null || applicationContext.currentPage === null) {
            return null;
        }

        if (nextPage.name === PageDefinitions.register.pageName) {
            return true;
        }

        if (applicationContext!.currentPage!.name !== PageDefinitions.register.pageName && nextPage.name === PageDefinitions.register.pageName) {
            return null;
        }

        if (applicationContext!.currentPage!.name === PageDefinitions.register.pageName && nextPage.name !== PageDefinitions.register.pageName) {
            return this.isAllObligatoryRegistrationFieldsFilled;
        }

        return null;
    }

    private get userContext(): UserContext | null {
        const userContext: ApplicationContext | null = ch.findContext();
        if (userContext) {
            return userContext as UserContext;
        }
        return null;
    }

    public get isRegistrationContext(): boolean {
        const applicationContext: ApplicationContext | null = ch.findContext();
        if (applicationContext && applicationContext.currentPage) {
            return applicationContext.currentPage.name === PageDefinitions.register.pageName;
        }
        return true;
    }

    public get isAllObligatoryRegistrationFieldsFilled(): boolean {
        const userContext = this.userContext;

        if (userContext && userContext.user) {
            return userContext.user.hasPassword &&
                userContext.user.informationFilled &&
                userContext.user.phoneNumber !== null && userContext.user.phoneNumber.length > 0 &&
                userContext.user.favoriteDepotId !== null && userContext.user.favoriteDepotId.length > 0 &&
                userContext.user.agreementAccepted &&
                userContext.user.registerAccepted;
        }

        return true;
    }

    // Soon to be legacy way of loading google tag manager
    // Loaded when we are not in development and featureFlagCookieBot is not enabled
    private async loadGoogleTagManagerScriptAsync(): Promise<any> {
        if (!ch.isDevelopment && !UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCookiebot)) {
            let applicationContext: ApplicationContext = (ch.getContext() as ApplicationContext);
            if (applicationContext.settings.googleManagerTag) {
                const tagManagerArgs = {
                    gtmId: applicationContext.settings.googleManagerTag
                };

                TagManager.initialize(tagManagerArgs);
            }
        }
    }

    // New way of loading google tag manager
    // Loaded when featureFlagCookieBot is enabled
    public async loadNewGoogleTagManagerScriptAsync(): Promise<any> {
        let applicationContext: ApplicationContext = (ch.getContext() as ApplicationContext);
        if (applicationContext.settings?.newGoogleManagerTag ?? false) {
            const tagManagerArgs = {
                gtmId: applicationContext.settings.newGoogleManagerTag
            };
            TagManager.initialize(tagManagerArgs);
        }

    }


    private async loadSite24X7ScriptAsync(): Promise<any> {
        let applicationContext: ApplicationContext = (ch.getContext() as ApplicationContext);
        if (applicationContext.settings.site24X7RumKey) {

            const site24X7: HTMLScriptElement = document.createElement("script");
            site24X7.async = false;

            // :D
            let functionPart: string = "(function(w,d,s,r,k,h,m){ \n";
            functionPart += "if(w.performance && w.performance.timing && w.performance.navigation) { \n";
            functionPart += "w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)}; \n";
            functionPart += "h=d.createElement('script');h.async=true;h.setAttribute('src',s+k); \n";
            functionPart += "d.getElementsByTagName('head')[0].appendChild(h); \n";
            functionPart += "(m = window.onerror),(window.onerror = function (b, c, d, f, g) { \n";
            functionPart += "m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push([\"captureException\",g]);}) \n";
            functionPart += "} \n";
            functionPart += `})(window,document,'\/\/static.site24x7rum.eu/beacon/site24x7rum-min.js?appKey=','s247r','${applicationContext.settings.site24X7RumKey}');`;

            site24X7.innerHTML = functionPart;

            window.document.body.appendChild(site24X7);

        }
    }
}

//Singleton
export default new RentaEasyController();