import Localizer from "../../../localization/Localizer";
import { executeWithIntercept, pageData, RouteData } from "@/helpers/CypressHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

context("Desktop - Authenticated Invite User tests", () => {

    const randomHash = Date.now().getHashCode().toString();
    const firstName = 'Tom';
    const lastName = 'Thompson';
    const email = `${randomHash}@renta.com`;
    const phone = '+4420123456';

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        executeWithIntercept(() => cy.visit(`${Localizer.pageRoutesInviteUser}?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409`),
            [pageData().inviteUser.routes.getInvitationPageData as RouteData]
        );
    });

    it('As admin go to invite user page, click the add button without any data', () => {

        addUserToListAndAssert(0);

        cy.get('.alert-warning')
            .should('contain', Localizer.inviteUserInfoMissing);
    });

    it('As admin go to invite user page, try to invite a user with incorrect email address ', () => {

        fillInvitationData(firstName, lastName, 'tom@renta', phone);

        addUserToListAndAssert(0);
    });

    it('As admin go to invite user page, try to invite a user with incorrect phone number ', () => {

        fillInvitationData(firstName, lastName, email, '446324141232');

        addUserToListAndAssert(0);
    });

    it('As admin go to invite user page, try to invite a user with correct data ', () => {

        fillInvitationData(firstName, lastName, email, phone);

        addUserToListAndAssert(1);

        pageData().inviteUser.userList.rows()
            .find('div')
            .contains(`${firstName} ${lastName}`)

        pageData().inviteUser.userList.rows()
            .find('div')
            .contains(email)

        pageData().inviteUser.userList.rows()
            .find('i.fa-times-circle')
            .should('exist');

        pageData().inviteUser.buttons.addUserButton()
            .should('exist')
            .trigger('click');

        cy.get('.alert-success')
            .should('contain', Localizer.get(Localizer.inviteUserInvitationSent, email));
    });

    it('As admin go to invite user page, select existing user from dropdown', () => {
        DeviceGridHelper.getDropdown("select-user-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.inviteUserSelectUser);
        DeviceGridHelper.getDropdown("select-user-dropdown").should("be.visible");
        DeviceGridHelper.checkDropdownValue("select-user-dropdown", Localizer.inviteUserNewUser);
        DeviceGridHelper.getDropdown("select-user-dropdown").click();
        DeviceGridHelper.typeDropdownInput("select-user-dropdown", "construction");
        DeviceGridHelper.clickDropdownListItem("select-user-dropdown", 0);
        DeviceGridHelper.checkDropdownValue("select-user-dropdown", "ConstructionSite Main");

        pageData().inviteUser.inputs.firstname().should('have.value', 'ConstructionSite');
        pageData().inviteUser.inputs.lastname().should('have.value', 'Main');
        pageData().inviteUser.inputs.email().should('have.value', 'cs.main@domain.fi');
        pageData().inviteUser.inputs.telephone().should('have.value', '+358000000000');

        addUserToListAndAssert(1);
    });

    function addUserToListAndAssert(expectedUsers: number) {

        pageData().inviteUser.buttons.addUserToListButton()
            .should('exist')
            .trigger('click');

        pageData().inviteUser.userList.rows()
            .should('have.length', expectedUsers);
    }

    function fillInvitationData(firstName: string, lastName: string, emailAddress: string, phoneNumber: string) {

        pageData().inviteUser.inputs.firstname()
            .type(firstName);

        pageData().inviteUser.inputs.lastname()
            .type(lastName);

        pageData().inviteUser.inputs.email()
            .type(emailAddress);

        pageData().inviteUser.inputs.telephone()
            .clear()
            .type(phoneNumber);
    }

});