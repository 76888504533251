import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Create a pricing plan', () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.visit('salestools/pricingtool');
    });

    it('Open Template Catalog view and check the template list', () => {

        cy.get('#pricingToolTemplates').click();

        cy.get('#SelectTemplateDD').click();
        cy.get('#SelectTemplateDD .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').should('have.length', 2);

        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').should('contain.text', 'Test template 2');
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) small').should('contain.text', 'Draft');
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(2) span').should('contain.text', 'Test template 1');
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(2) small').should('contain.text', 'Active');

    });

    it('Open Template Catalog view, set new discount for selected category, check new discounts for child categories/products', () => {

        openCatalogDiscounts();

        // check if template catalog view is opened
        cy.get('#category-item-0 #editDiscount div:nth-child(2)').should('contain.text', '6.66');
        cy.get('#category-item-1 #editDiscount div:nth-child(2)').should('contain.text', '6.66');
        cy.get('#category-item-2 #editDiscount div:nth-child(2)').should('contain.text', '6.66');
        cy.get('#category-item-3 #editDiscount div:nth-child(2)').should('contain.text', '6.66');

        // set new discount for selected category
        cy.get('#category-item-0 #editDiscount').click();
        cy.get('input#input_discountPercentage').click();
        cy.get('input#input_discountPercentage').clear();
        cy.get('input#input_discountPercentage').type('12');
        cy.get('input#input_discountPercentage').blur();

        cy.get('#overallSaveButton').trigger('click')
        executeWithIntercept(() => cy.get('form')
                .eq(0)
                .submit(),
            [pageData().pricingTool.routes.updateCategoryDiscount as RouteData]);

        // check if new discount was set for selected category
        cy.get('#category-item-0 #editDiscount div:nth-child(2)').should('contain.text', '12 %');
        cy.get('#category-item-1 #editDiscount div:nth-child(2)').should('contain.text', '6.66 %');
        cy.get('#category-item-2 #editDiscount div:nth-child(2)').should('contain.text', '6.66 %');
        cy.get('#category-item-3 #editDiscount div:nth-child(2)').should('contain.text', '6.66 %');

        // check if new discount was set for child categories/products
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        cy.get('#category-item-0 #editDiscount div:nth-child(2)').should('contain.text', '12 %');
        cy.get('#category-item-1 #editDiscount div:nth-child(2)').should('contain.text', '12 %');
        cy.get('#category-item-2 #editDiscount div:nth-child(2)').should('contain.text', '12 %');
        cy.get('#category-item-3 #editDiscount div:nth-child(2)').should('contain.text', '12 %');

        // open list of products for selected category
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        cy.get('.items-list > div:nth-child(1) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(2) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(3) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(4) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');

    });

    it('Should set new discount for selected product - input percent value directly', () => {

        openCatalogDiscounts();

        // open list of child categories
        cy.get('#category-item-0').should('not.contain.text', 'Loading...');
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        // open list of products for selected category
        cy.get('#category-item-0').should('not.contain.text', 'Loading...');
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        // set new discount for selected product - input percent value directly
        cy.get('.items-list > div:nth-child(1) #editProductDiscount').click();
        cy.get('#changeDiscountModal input#input_discountPercentage').click();
        cy.get('#changeDiscountModal input#input_discountPercentage').type('{selectall}, {backspace}');
        cy.get('#changeDiscountModal input#input_discountPercentage').type('15');
        cy.get('#changeDiscountModal #overallSaveButton').click();

        // check if new discount was set for selected product
        cy.get('.items-list > div:nth-child(1) #editProductDiscount > div:nth-child(2)').should('contain.text', '15 %');
        cy.get('.items-list > div:nth-child(2) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(3) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(4) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');

    });

    it('Should set new discount for selected product - calculate percent value from price', () => {

        openCatalogDiscounts();

        // open list of child categories
        cy.get('#category-item-0').should('not.contain.text', 'Loading...');
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        // open list of products for selected category
        cy.get('#category-item-0').should('not.contain.text', 'Loading...');
        executeWithIntercept(() => cy.get('#category-item-0').click(),
            [pageData().rent.routes.categoriesData as RouteData]);

        // set new discount for selected product - calculate percent value from price
        cy.get('.items-list > div:nth-child(2) #editProductDiscount').click();
        cy.get('#changeDiscountModal #calculateDiscountCheckbox .athenaeum-checkbox-checkbox').click();
        cy.get('#changeDiscountModal input#input_discountExpectedPrice').click();
        cy.get('#changeDiscountModal input#input_discountExpectedPrice').type('{selectall}, {backspace}');
        cy.get('#changeDiscountModal input#input_discountExpectedPrice').type('450');
        cy.get('#changeDiscountModal #overallSaveButton').click();

        // check if new discount was set for selected product
        cy.get('.items-list > div:nth-child(1) #editProductDiscount > div:nth-child(2)').should('contain.text', '15 %');
        cy.get('.items-list > div:nth-child(2) #editProductDiscount > div:nth-child(2)').should('contain.text', '10 %');
        cy.get('.items-list > div:nth-child(3) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');
        cy.get('.items-list > div:nth-child(4) #editProductDiscount > div:nth-child(2)').should('contain.text', '12 %');

    });

    function openCatalogDiscounts() {

        cy.get('#pricingToolTemplates').click();

        // select template
        cy.get('#SelectTemplateDD').click();
        cy.get('#SelectTemplateDD .athenaeum-dropdown-item:nth-child(1) span').click();

        // go to template edit view
        cy.get('#EditTemplateBtn').click();

        // check if template was selected
        cy.get('#discountName > div:nth-child(2)').should('contain.text', 'Test template 2');
        cy.get('#overallDiscuntPercentage > div:nth-child(2)').should('contain.text', '6.66');

        // go to template catalog view

        onRedirect(() =>
            executeWithIntercept(() => cy.get('#TemplateProductsBtn').click(),
                [
                    pageData().rent.routes.categoriesData as RouteData
                ]
            ));
    }

});