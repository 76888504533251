import React, {ReactNode} from 'react';
import styles from './ReturnGrid.module.scss';

import {Box, SimpleTable, SimpleTableRowData} from "@renta-apps/renta-react-components";
import {formatDate, formatTime} from '@/helpers/DateHelper';
import {handleCompanyLinkClick, handleConstructionSiteLinkClick, handleDeviceDetailsLinkClick, handleProductLinkClick} from '@/helpers/NavigationHelper';
import {capitalizeWords} from "@/helpers/StringHelper";
import Localizer from '@/localization/Localizer';
import {OrderStatus} from '@/models/Enums';
import {ReturnRequestModel} from '@/models/server/ReturnRequestModel';
import {ReturnDetailsModel} from '@/models/server/ReturnDetailsModel';
import RentaEasyConstants from '@/helpers/RentaEasyConstants';

const DeviceGridRowValue = (props: { label: string, children: ReactNode, extraClass?: string | null }) => {
    return (
        <div className={props.extraClass || ''}>
            <span className={`${styles.fontMedium} ${styles.ellipsisText} ${styles.width100Percent}`}>{props.label}</span>
            {props.children}
        </div>
    );
};

function ReturnGridRow(props: {
    index: number,
    item: ReturnRequestModel,
    expanded: boolean,
    returnRequestDetails: ReturnDetailsModel | null,
    onAccordionButtonClick: () => Promise<void>,
}) {

    const formatMultiLineText = (lines: string[]): ReactNode => {
        if (!lines.length || lines.every(line => !line)) {
            return <span>-</span>;
        }
        return (
            <>
                {lines.map((line, index) => {
                    return (
                        <span key={index} className={`${styles.width100Percent}`}>
                            {line}
                        </span>
                    );
                })}
            </>
        );
    };

    const getMappedStatus = (status: OrderStatus): string => {
        if ([OrderStatus.Rejected].includes(status)) {
            return RentaEasyConstants.FleetMonitoringConstants.statusRejected;
        }
        if ([OrderStatus.Unconfirmed, OrderStatus.Ordered].includes(status)) {
            return RentaEasyConstants.FleetMonitoringConstants.statusRequested;
        }
        if ([OrderStatus.InProgress, OrderStatus.Processing, OrderStatus.Partially].includes(status)) {
            return RentaEasyConstants.FleetMonitoringConstants.statusProcessing;
        }
        if ([OrderStatus.Delivered].includes(status)) {
            return RentaEasyConstants.FleetMonitoringConstants.statusDone;
        }
        return '';
    }

    const getReturnStatusClass = (status: OrderStatus): string => {
        const mappedStatus = getMappedStatus(status);
        switch (mappedStatus) {
            case RentaEasyConstants.FleetMonitoringConstants.statusRejected:
                return styles.rejected;
            case RentaEasyConstants.FleetMonitoringConstants.statusRequested:
                return styles.requested;
            case RentaEasyConstants.FleetMonitoringConstants.statusProcessing:
                return styles.processing;
            case RentaEasyConstants.FleetMonitoringConstants.statusDone:
                return styles.done;
            default:
                return '';
        }
    }

    const getReturnStatusText = (status: OrderStatus): string => {
        const mappedStatus = getMappedStatus(status);
        switch (mappedStatus) {
            case RentaEasyConstants.FleetMonitoringConstants.statusRejected:
                return Localizer.fleetMonitoringPageGridStatusRejected;
            case RentaEasyConstants.FleetMonitoringConstants.statusRequested:
                return Localizer.fleetMonitoringPageGridStatusRequested;
            case RentaEasyConstants.FleetMonitoringConstants.statusProcessing:
                return Localizer.fleetMonitoringPageGridStatusProcessing;
            case RentaEasyConstants.FleetMonitoringConstants.statusDone:
                return Localizer.fleetMonitoringPageGridStatusDone;
            default:
                return Localizer.genericNone;
        }
    }

    const tableHeaders = [
        {
            key: "name",
            label: Localizer.fleetMonitoringPageGridDevice,
            render: (rowData: SimpleTableRowData) => (
                rowData.isMassProduct ? (
                    <span
                        className={`${styles.ellipsisText} ${styles.link}`}
                        onClick={() => handleProductLinkClick(rowData.url)}
                        data-cy="productLink"
                    >
                        {capitalizeWords(rowData.name)}
                    </span>
                ) : (
                    <span
                        className={`${styles.ellipsisText} ${styles.link}`}
                        onClick={() => handleDeviceDetailsLinkClick(rowData.rentaId)}
                        data-cy="deviceLink"
                    >
                        {capitalizeWords(rowData.name ?? rowData.rentaId)}
                    </span>
                )
            )
        },
        {
            key: "rentaId",
            label: Localizer.fleetMonitoringPageGridId,
            render: (rowData: SimpleTableRowData) => (
                <div className={styles.simpleTableSubCell}>
                    <div className={`${styles.fontMedium} ${styles.label}`}>
                        {Localizer.fleetMonitoringPageGridId}:
                    </div>
                    <div className={styles.xyz}>
                        {rowData.rentaId}
                    </div>
                </div>
            )
        },
        {
            key: "amount",
            label: Localizer.fleetMonitoringPageGridAmount,
            render: (rowData: SimpleTableRowData) => (
                <div className={styles.simpleTableSubCell}>
                    <div className={`${styles.fontMedium} ${styles.label}`}>
                        {Localizer.fleetMonitoringPageGridAmount}:
                    </div>
                    <div className={styles.xyz}>
                        {rowData.amount}
                    </div>
                </div>
            )
        },
    ];

    return <div className={`${styles.dataRow} ${props.index % 2 === 0 ? "" : styles.odd}`}>
        <div className={styles.gridRow}>
            <div className={styles.constructionSite}>
                <div className={`${styles.gridChild} ${styles.fontRegular}`}>
                    <span
                        data-cy={"constructionSiteLink"}
                        className={`${styles.link} ${styles.ellipsisText}`}
                        onClick={() => handleConstructionSiteLinkClick(props.item.constructionSiteId)}
                    >
                        {capitalizeWords(props.item.constructionSiteName)}
                    </span>
                </div>
            </div>
            <div className={styles.company}>
                <div className={`${styles.gridChild} ${styles.fontRegular}`}>
                    <span
                        data-cy={"customerLink"}
                        className={`${styles.link} ${styles.ellipsisText}`}
                        onClick={() => handleCompanyLinkClick(props.item.customerId)}
                    >
                        {capitalizeWords(props.item.customerName)}
                    </span>
                </div>
            </div>
            <div className={styles.pickupDate}>{formatDate(props.item.pickupTime)}</div>
            <div className={styles.pickupTime}>{formatTime(props.item.pickupTime)}</div>
            <div className={`${styles.pickupDateAndTime} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridPickUpTime}:
                </div>
                <div className={`${styles.dateAndTime} ${styles.value}`}>
                    <span>{formatDate(props.item.pickupTime)}</span>
                    <span>{formatTime(props.item.pickupTime)}</span>
                </div>
            </div>
            <div className={styles.bulkItems}>{props.item.amount}</div>
            <div className={styles.devices}>{props.item.devices}</div>
            <div className={`${styles.depot} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridDepot}:
                </div>
                <div className={`${styles.value} ${styles.ellipsisText}`}>
                    {props.item.depot}
                </div>
            </div>
            <div className={`${styles.status} ${styles.valueRow}`}>
                <div className={styles.label}>
                    {Localizer.fleetMonitoringPageGridStatus}:
                </div>
                <div className={styles.value}>
                    <span className={`${styles.circle} ${getReturnStatusClass(props.item.status)}`}></span>
                    <span className={styles.statusText} >{getReturnStatusText(props.item.status)}</span>
                </div>
            </div>
            <div className={styles.actions}>
                <div className={styles.accordionButton}
                     onClick={props.onAccordionButtonClick}
                >
                    <i className={`fas fa-angle-down ${props.expanded ? styles.rotated : ""}`}></i>
                </div>
            </div>
        </div>
        <div data-cy="row-details" className={`${styles.gridRow} ${styles.expandableGridRow} ${props.expanded ? styles.expandedRow : ""}`}>
            {props.returnRequestDetails ? (
                <>
                    {(props.returnRequestDetails?.returnDetails) && (
                        <div className={styles.generalData}>
                            <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridReturnAddress} extraClass={styles.infoField}>
                                {formatMultiLineText([props.returnRequestDetails.returnDetails.address, props.returnRequestDetails.returnDetails.postalCode, props.returnRequestDetails.returnDetails.city])}
                            </DeviceGridRowValue>
                            <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridPickUpRequestedBy} extraClass={styles.infoField}>
                                {formatMultiLineText([props.returnRequestDetails.returnDetails.requestedBy, props.returnRequestDetails.returnDetails.requestedByEmail, props.returnRequestDetails.returnDetails.requestedByPhone])}
                            </DeviceGridRowValue>
                            <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridPickUpContactInformation} extraClass={styles.infoField}>
                                {formatMultiLineText([
                                    // props.returnRequestDetails.returnDetails.contactEmail, non-destructive change
                                    props.returnRequestDetails.returnDetails.contactPhone])}
                            </DeviceGridRowValue>
                            <DeviceGridRowValue label={Localizer.fleetMonitoringPageGridPickUpRequestedAt} extraClass={styles.infoField}>
                                <span>{formatDate(props.returnRequestDetails.returnDetails.requestedAt, false, false) ?? "-"}</span>
                            </DeviceGridRowValue>
                        </div>
                    )}
                    {(props.returnRequestDetails?.devices) && (
                        <div className={styles.devicesData}>
                            <Box className={styles.moduleContainer}>
                                <Box className={styles.moduleContentContainer} display="flex" flexDirection="column" data-cy={"module-content-devices"}>
                                    <SimpleTable headers={tableHeaders}
                                                 data={props.returnRequestDetails?.devices}
                                                 rowGridClassName={styles.simpleTableRow}
                                                 noDataText={Localizer.genericNoData}
                                    />
                                </Box>
                            </Box>
                        </div>
                    )}
                </>
            ) : (
                <div>{Localizer.componentListLoading}</div>
            )}
        </div>
    </div>;
}

export default ReturnGridRow;