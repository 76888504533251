import { pageData } from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe("Desktop - admin invite user tests", () => {


    beforeEach(() => {
        cy.useUniqueAdminSession();
    });

    it("End user must complete the registration process to use explore the application", () => {
        Cypress.on('uncaught:exception', (err, runnable) => {
            return false
        })


        // visit the invite user page
        cy.visit("/invite-user?contractId=31eaa6c0-42fa-40c2-a891-bcd16c367409");

        // populate the invitation form
        cy.get("#firstName").type("Test");
        cy.get("#lastName").type("User");
        cy.get("#email").type(`test${new Date().getTime()}@weare.fi`);
        cy.get("#input_phone").type("1234567");

        // send invitation
        cy.intercept("POST", "/api/Users/InviteUser").as("inviteUser");
        cy.get("#addUserButton").click();

        cy.wait("@inviteUser").then(({ response }) => {
            if (response?.body?.value?.tokenUrls && response?.body?.value?.tokenUrls.length > 0) {
                const tokenUrl = response?.body?.value?.tokenUrls[0];

                // logout admin user
                cy.visit("/Logout");

                // navigate to captured tokenUrl
                // waiting for the applicationContext
                cy.intercept(pageData().common.routes.tokenLogin.method, pageData().common.routes.tokenLogin.path).as('tokenLogin');
                cy.visit(tokenUrl);
                cy.waitSuccess('@tokenLogin')


                // verify that the actions are blocked
                // logo icon does not redirect to the main page
                pageData().common.topNavLogoIcon().click();

                // shopping cart does not redirect to the shopping cart page
                pageData().common.topNavShoppingCartIcon().click();

                // search icon is not visible
                pageData().common.globalSearch.searchButton().should("not.exist");

                // visit shoppingCart page
                cy.visit(Localizer.pageRoutesLogin);

                // verify whether the end user will be redirected to the registration page once the registration process is not completed
                cy.location().should((loc) => {
                    expect(loc.pathname).to.eq(`/${Localizer.pageRoutesRegister}`);
                });
            }
        });
    });

})