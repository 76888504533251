import Localizer from "../../../localization/Localizer";

describe('Desktop - Service preview private person tests', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session('private_user_session', () => cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1"), {});
        cy.visit('frontpage');
        cy.visit(Localizer.pageRoutesServices);
    });

    it('opens list of service and see enabled only', () => {

        // Two elements come from the DB, ensure that test passes if other Cypress tests add elements.
        cy.get('#service_list > span').should('have.length', 2);
        cy.get('span[data-test-id="service_Unit test"]').should('exist');
        cy.get('span[data-test-id="service_Mandatory fields test"]').should('exist');
    });

    it('Opens service preview, test URL change, do not submit', () => {

        cy.contains("Mandatory fields test").click();

        cy.url().should('include', 'serviceRequestId=937989b3-9a2e-4813-b2b7-8c906435e6a0');
        cy.get('h2').should('contain.text', 'Mandatory fields test');

        cy.contains('button', `${Localizer.formCancel}`).click();

        cy.url().should('not.include', 'serviceRequestId=937989b3-9a2e-4813-b2b7-8c906435e6a0');

        cy.get('#service_list > span').should('have.length', 2);
    });

    it('opens a service definition by URL and submit it', () => {

        cy.visit(`${Localizer.pageRoutesServices}?serviceRequestId=1a0d54b6-2ee6-424d-8008-5ebef6467cfe`);

        cy.get('h2').should('contain.text', 'Unit test');

        cy.intercept("POST", "/api/Services/CreateCustomServiceRequest", (req) => {
            // This example does not contain any field in the definition.
            expect(req.body.inputGroups.length).to.equal(0);
            expect(req.body.inputs.length).to.equal(0);
            // Within this user story, we allow organizationContractId to be null.
            expect(req.body.organizationContractId).to.equal(null);
            expect(req.body.serviceRequestDefinitionId).to.equal('1a0d54b6-2ee6-424d-8008-5ebef6467cfe');
            expect(req.body.type).to.equal(3);
        }).as("saveServiceRequest");

        cy.contains('button', `${Localizer.genericSend}`).click();

        cy.url().should('not.include', 'serviceRequestId=1a0d54b6-2ee6-424d-8008-5ebef6467cfe');

        cy.wait("@saveServiceRequest").its('response.statusCode').should('equal', 200);

        cy.get('#service_list > span').should('have.length', 2);
    });

});