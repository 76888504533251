import React from "react";
import {ch} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";
import {Button} from "@renta-apps/renta-react-components";

interface EasyPlusLinkProps  {
    children: React.ReactNode;
    mode?: "anchor" | "button";
}

const EasyPlusLink: React.FC<EasyPlusLinkProps> = ({children, mode = "anchor"}) => {

    const easyPlusLink = React.useMemo(() => {
        return Localizer.getValue(ch.country, Localizer.easyPlusInfoLinkLanguageItemName);
    }, []);

    const isValidUrl = (url: string) => {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    };

    if (!easyPlusLink || !isValidUrl(easyPlusLink)) {
        return null;
    }

    const handleRedirect = () => {
        window.location.href = easyPlusLink;
    };

    if (mode === "button") {
        return (
            <Button
                onClick={handleRedirect}
                data-cy="easy_plus_button"
            >
                {children}
            </Button>
        );
    }

    return (
        <a href={easyPlusLink} data-cy="easy_plus_link">
            {children}
        </a>
    );
};

export default EasyPlusLink;