import {onRedirect, pageData} from "@/helpers/CypressHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Admin user construction site details', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.visit('frontpage');
    });

    it('Should display page with construction site users', () => {
        cy.intercept('POST', '/api/Users/GetConstructionSiteUsersPaged').as('postUsersRequest');

        cy.intercept('POST', '/api/Users/GetPagedPendingInvitations').as('postInvitationsRequest');

        onRedirect(() => cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=20c46273-6c09-4c7d-98bf-300071dfc1e2`));

        cy.get("[data-cy=construction-site-company]")
            .should('contain', 'Unit test6 Oy');

        cy.get("[data-cy=construction-site-name]")
            .should('contain', 'ConstructionSite1');

        cy.get("[data-cy=construction-site-additional-reference]")
            .should('contain', 'TestSite1ExternalReference');

        cy.get('#tab_usersTab').click();

        cy.wait('@postUsersRequest').then(({ request, response }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('notProcessedUsers', false);

            assertResponseHasExpectedData(response, 2);
        });

        cy.wait('@postInvitationsRequest').then(({ request, response }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('invitationSent', true);
            expect(request.body).to.have.property('linkOpened', true);
            expect(request.body).to.have.property('registered', true);

            assertResponseHasExpectedData(response, 0);
        });

        // User cs.user@domain.fi is present in the grid.
        cy.get('#table_users_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should('have.length', 2);

        cy.get('#table_users_grid')
            .find('tbody')
            .find('tr')
            .contains('td', 'cs.user@domain.fi')

        // No users present, one row to to display this message.
        cy.get('#table_invitations_grid')
            .find('tbody')
            .not('[class*=athenaeum-grid-spinner]')
            .first()
            .find('tr')
            .not('[class*=athenaeum-grid-details]')
            .should('have.length', 1);

        cy.get('#table_invitations_grid')
            .find('tbody')
            .find('tr')
            .contains('td', Localizer.componentDropdownNoData)

        pageData().admin.users.filters.showOnlyUnregisteredUsersCheckBox().click();

        cy.wait('@postUsersRequest').then(({ request, response }) => {
            // Assert that the request body contains the changed value
            expect(request.body).to.have.property('notProcessedUsers', true);

            assertResponseHasExpectedData(response, 0);
        });
    });

    function assertResponseHasExpectedData(response: any | undefined, expectedTotalItemCount: number) {
        expect(response?.statusCode, 'Response status code should be 200').to.equal(200);
        expect(response?.body.value, 'Response should have a "value" property').to.have.property('items');
        expect(response?.body.value.items, '"items" should be an array').to.be.an('array');
        expect(response?.body.value.pageCount, 'Page count should be 1').to.equal(1);
        expect(response?.body.value.pageNumber, 'Page number should be 1').to.equal(1);
        expect(response?.body.value.pageSize, 'Page size should be 10').to.equal(10);
        expect(response?.body.value.totalItemCount, 'Total item count should match expected').to.equal(expectedTotalItemCount);
    }
});