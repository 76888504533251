import {pageData} from "@/helpers/CypressHelper";
import {Method} from "cypress/types/net-stubbing";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";
import Localizer from "@/localization/Localizer";

describe('Desktop - Fleet Monitoring - Return modal tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('return icon should not exist when device not rented', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetailsEmpty.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(2).click();
        DeviceGridHelper.getDeviceReturnIcon(2).should('not.exist');
    });

    it('should open return request modal', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).should('exist');
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');
    });

    it('should open return request modal from bottom sheet', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridCheckbox(1).click();
        DeviceGridHelper.getDeviceBottomSheet().should('be.visible');
        DeviceGridHelper.getDeviceBottomSheetButton(0).should('be.enabled');

        DeviceGridHelper.getDeviceGridCheckbox(2).click();
        DeviceGridHelper.getDeviceBottomSheetButton(0).should('be.disabled');

        DeviceGridHelper.getDeviceGridCheckbox(2).click();
        DeviceGridHelper.getDeviceBottomSheetButton(0).should('be.enabled');

        DeviceGridHelper.getDeviceBottomSheetButton(0).click();
        returnRequestModal.modal().should('be.visible');
    });

    it('should show devices in a table in the correct order', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).should('exist').should("be.visible").click();
        DeviceGridHelper.getDeviceGridRowDetails(1).should('exist').should("be.visible")
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        // verify checkboxes state
        cy.wait(100); // the test fails sometimes without this wait, false instead of partial
        returnRequestModal.tableRowCheckbox(0).verifyDataValue('true');
        returnRequestModal.tableRowCheckbox(1).verifyDataValue('false');
        returnRequestModal.tableHeaderCheckbox().verifyDataValue('partial');

        // verify order of devices
        returnRequestModal.tableRowRentaId(0).should('have.text', '93289');
        returnRequestModal.tableRowRentaId(1).should('have.text', '10-2082');
        returnRequestModal.tableRowRentaId(2).should('have.text', '4458');
        returnRequestModal.tableRowRentaId(3).should('have.text', '10-1670');
        returnRequestModal.tableRowRentaId(4).should('have.text', '20-035');
        returnRequestModal.tableRowRentaId(5).should('have.text', '40-260');
        returnRequestModal.tableRowRentaId(6).should('have.text', 'SE 0853');

        // verify amount
        returnRequestModal.tableRowAmount(0).should('contain.text', '3/3');
        returnRequestModal.tableRowAmount(1).should('contain.text', '0/2');
    });

    it('should edit amount of a selected device', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.tableRowAmount(0).find('button').should('not.be.disabled');
        returnRequestModal.tableRowAmount(1).find('button').should('be.disabled'); // disabled because not selected for return

        returnRequestModal.tableRowCheckbox(2).verifyDataValue('false');
        returnRequestModal.tableRowCheckbox(2).click();
        returnRequestModal.tableRowCheckbox(2).verifyDataValue('true');
        returnRequestModal.tableRowAmount(2).should('contain.text', '1/1');
        returnRequestModal.tableRowAmount(2).find('button').should('be.disabled'); // disabled because the rented amount is 1

        returnRequestModal.tableRowAmount(0).find('button').trigger('click');
        returnRequestModal.tableAmountOverlay().should('exist');
        returnRequestModal.tableAmountOverlay().find('input').should('have.value', '3');
        returnRequestModal.tableAmountOverlay().find('input').clear().type('2{enter}');
        returnRequestModal.tableAmountOverlay().should('not.exist');
        returnRequestModal.tableRowAmount(0).should('contain.text', '2/3');
    });

    it('should edit retrieval address', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.retrievalAddress().find('input').eq(0).should('have.value', 'Address 12, 4000 City');
        returnRequestModal.retrievalAddress().find('input').eq(0).clear().type('Address 12');
        returnRequestModal.retrievalAddress().find('input').eq(1).type('4000');
        returnRequestModal.retrievalAddress().find('input').eq(2).type('City');
        returnRequestModal.retrievalAddress().find('input').eq(0).should('have.value', 'Address 12');
        returnRequestModal.retrievalAddress().find('input').eq(1).should('have.value', '4000');
        returnRequestModal.retrievalAddress().find('input').eq(2).should('have.value', 'City');
    });

    it('should edit customer information', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'}).as("usersPagedList");

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        cy.waitSuccess("@usersPagedList")
        returnRequestModal.modal().should('be.visible');

        // Default case. Existing easy user selected from dropdown and values shown in contact information inputs
        returnRequestModal.existingEasyUserRadio().verifyDataValue('true');
        returnRequestModal.notAnEasyUserRadio().verifyDataValue('false');
        returnRequestModal.contactInformation().find('input').eq(0).should('be.disabled');

        // Assert empty values when no customer selected
        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', '');
        returnRequestModal.contactInformation().find('input').eq(0).should('be.disabled');
        returnRequestModal.contactInformation().find('input').eq(1).should('be.disabled');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '');

        //Select customer from dropdown
        returnRequestModal.customerContactDropdown().click();
        returnRequestModal.customerContactDropdown().parent().parent().find('div[class*="dropdownOption"]').eq(0).click();

        returnRequestModal.contactMeRadio().verifyDataValue('true');
        returnRequestModal.fillContactInfoRadio().verifyDataValue('false');
        returnRequestModal.contactInformation().find('input').eq(0).should('be.disabled');
        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', 'john@example.com');
        returnRequestModal.contactInformation().find('input').eq(1).should('be.disabled');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '12345678');

        //Click on non easy user and verify data
        returnRequestModal.notAnEasyUserRadio().click();

        returnRequestModal.contactMeRadio().verifyDataValue('false');
        returnRequestModal.contactMeRadio().find('div').eq(0).should('have.attr', 'disabled');

        returnRequestModal.fillContactInfoRadio().find('div').eq(0).should('have.attr', 'disabled');
        returnRequestModal.fillContactInfoRadio().verifyDataValue('true');
        returnRequestModal.contactInformation().find('input').eq(0).should('not.be.disabled');

        cy.get("#customer_info_free_text_first_name").type("name");
        cy.get("#customer_info_free_text_last_name").type("last name");
        cy.get("#customer_info_free_text_email").type("email@example.com");
        cy.get("#customer_info_free_text_phone").type("+351234567890");

        returnRequestModal.contactMeRadio().find('div').eq(0).should('not.have.attr', 'disabled');
        returnRequestModal.fillContactInfoRadio().find('div').eq(0).should('not.have.attr', 'disabled');

        returnRequestModal.contactMeRadio().click();

        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', 'email@example.com');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '+351234567890');

    });

    it('should edit contact information', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'}).as("usersPagedList");

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        cy.waitSuccess("@usersPagedList")

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.contactMeRadio().click();
        returnRequestModal.contactMeRadio().verifyDataValue('true');
        returnRequestModal.contactMeRadio().verifyLabelText(Localizer.returnRequestModalUseCustomerContactInfo);
        returnRequestModal.fillContactInfoRadio().verifyDataValue('false');
        returnRequestModal.contactInformation().find('input').eq(0).should('be.disabled');

        // Assert empty values when no customer selected
        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', '');
        returnRequestModal.contactInformation().find('input').eq(1).should('be.disabled');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '');
        returnRequestModal.fillContactInfoRadio().click();
        returnRequestModal.contactMeRadio().verifyDataValue('false');
        returnRequestModal.fillContactInfoRadio().verifyDataValue('true');
        returnRequestModal.contactInformation().find('input').eq(0).should('not.be.disabled');
        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', '');
        returnRequestModal.contactInformation().find('input').eq(1).should('not.be.disabled');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '');
        returnRequestModal.contactInformation().find('input').eq(0).type('test').then($el => ($el[0] as HTMLInputElement).checkValidity()).should('be.false');
        returnRequestModal.contactInformation().find('input').eq(1).type('test').then($el => ($el[0] as HTMLInputElement).checkValidity()).should('be.false');
        returnRequestModal.contactInformation().find('input').eq(0).clear().type('test@test.com')
            .then($el => ($el[0] as HTMLInputElement).checkValidity()).should('be.true');
        returnRequestModal.contactInformation().find('input').eq(1).clear().type('+351234567890')
            .then($el => ($el[0] as HTMLInputElement).checkValidity()).should('be.true');
        returnRequestModal.contactInformation().find('input').eq(0).should('have.value', 'test@test.com');
        returnRequestModal.contactInformation().find('input').eq(1).should('have.value', '+351234567890');
    });

    it('should edit fetch time', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        cy.wait(10);

        returnRequestModal.fetchTimeNowRadio().verifyDataValue('true');
        returnRequestModal.fetchTimeInput().click();
        cy.get('.react-datepicker__navigation--next').click(); // go to next month
        cy.get('.react-datepicker__day').contains('15').click(); // select 15th day
        cy.get('.react-datepicker__time-list-item').contains('11.30').click(); // select 11:30 AM
        returnRequestModal.fetchTimeNowRadio().verifyDataValue('false');
        returnRequestModal.fetchTimeAfterRadio().verifyDataValue('true');
        const date = new Date();
        const selectedMonth = (date.getMonth() + 1) % 12;
        const expectedYear = (selectedMonth === 0 ? date.getFullYear() + 1 : date.getFullYear()).toString();
        const expectedMonth = (selectedMonth + 1).toString().padStart(2, '0');
        const expectedDay = '15';
        const expectedTime = '11:30';
        returnRequestModal.fetchTimeInput().invoke('val').should('contain', expectedYear).should('contain', expectedMonth)
            .should('contain', expectedDay).should('contain', expectedTime);
        returnRequestModal.fetchTimeNowRadio().click();
        returnRequestModal.fetchTimeNowRadio().verifyDataValue('true');
        const expectedNowYear = date.getFullYear().toString();
        const expectedNowMonth = (date.getMonth() + 1).toString().padStart(2, '0');
        const expectedNowDay = date.getDate().toString().padStart(2, '0');
        returnRequestModal.fetchTimeInput().invoke('val').should('contain', expectedNowYear).should('contain', expectedNowMonth)
            .should('contain', expectedNowDay);
    });

    it('should edit additional information', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.additionalInformationInput().should('be.empty');
        returnRequestModal.additionalInformationInput().click();
        returnRequestModal.additionalInformationInput().type('Comment').blur();
        returnRequestModal.additionalInformationInput().should('have.value', 'Comment');
    });

    it('should edit internal notes', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment, getConstructionSiteUsersPaged}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept(getConstructionSiteUsersPaged.method as Method, getConstructionSiteUsersPaged.path, {fixture: 'getConstructionSiteUsersPagedList.json'});

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).click();
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.internalNotesInput().should('be.empty');
        returnRequestModal.internalNotesInput().click();
        returnRequestModal.internalNotesInput().type('Notes').blur();
        returnRequestModal.internalNotesInput().should('have.value', 'Notes');
    });
});

describe('Desktop - Fleet Monitoring - Return modal tests - company user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should send return request', () => {
        const {routes: {getDevicesPagedList, getDeviceDetails, getConstructionSiteEquipment}, modals: {returnRequest: returnRequestModal}} = pageData().fleetMonitoring;
        cy.intercept(getDevicesPagedList.method as Method, getDevicesPagedList.path, {fixture: 'fleetGetDevicesPagedList.json'});
        cy.intercept(getDeviceDetails.method as Method, getDeviceDetails.path, {fixture: 'getDeviceDetails.json'});
        cy.intercept(getConstructionSiteEquipment.method as Method, getConstructionSiteEquipment.path, {fixture: 'getConstructionSiteEquipment.json'});
        cy.intercept("POST", "/api/ConstructionSites/ReturnProduct", {fixture: 'createConstructionSiteServiceRequest.json'}).as('requestReturn');

        cy.visit('/fleet-monitoring');

        DeviceGridHelper.getDeviceGridAngleIcon(1).should('exist').should("be.visible").click();
        DeviceGridHelper.getDeviceGridRowDetails(1).should('exist').should("be.visible")
        DeviceGridHelper.getDeviceReturnIcon(1).click();

        returnRequestModal.modal().should('be.visible');

        returnRequestModal.retrievalAddress().find('input').eq(0).clear().type('Address 12');
        returnRequestModal.retrievalAddress().find('input').eq(1).type('4000');
        returnRequestModal.retrievalAddress().find('input').eq(2).type('City');
        returnRequestModal.contactMeRadio().click();

        returnRequestModal.sendButton().click();

        cy.wait('@requestReturn').then(({ request }) => {
            expect(request.body).to.have.property('constructionSiteId', '9727f2e0-74ef-4267-b505-ceb4ed8f8591');
            expect(request.body).to.have.property('organizationId', '9410d0d2-a86e-43c5-85b4-7cebf3ba0562');
            expect(request.body).to.have.property('address', 'Address 12');
            expect(request.body).to.have.property('city', 'City');
            expect(request.body).to.have.property('postalCode', '4000');
            expect(request.body).to.have.property('contactEmail', 'Urho.Kekkonen@weare.fi');

            cy.get('.alert-success')
                .should('contain', 'OK')
                .find('.athenaeum-alert-close')
                .first()
                .click();
        });
    });
});