import React, {useEffect, useMemo, useState } from "react";
import {ch} from "@renta-apps/athenaeum-react-common";
import {Icon, IconSize} from "@renta-apps/athenaeum-react-components";
import UserContext from "@/models/server/UserContext";
import ProductModel from "@/models/server/ProductModel";
import ProductAvailabilityRequest from "@/models/server/ProductAvailabilityRequest";
import {getProductDepotAvailability} from "@/services/ProductService";
import styles from "@/components/Product/ProductDepotAvailabilityIcon.module.scss";
import { LoadingDecorator } from "@renta-apps/renta-react-components";

interface IProductTileAvailabilityIconProps {
    product: ProductModel;
    depotExternalId: string | null;

    onIconClick(): Promise<void>;
}

const ProductDepotAvailabilityIcon: React.FC<IProductTileAvailabilityIconProps> = ({product, depotExternalId, onIconClick}) => {
    const [isAvailable, setIsAvailable] = useState(false);
    const [loading, setLoading] = useState(true);

    const isAdmin = useMemo(() => {
        return (ch.getContext() as UserContext)?.isAdmin ?? false;
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            let isAvailable = false;
            
            if (!isAdmin && product.showAsAvailable) {
                isAvailable = true;
            } else {
                const request: ProductAvailabilityRequest = {
                    rentalObjectId: product.rentalObjectId!,
                    rentalObjectNumber: product.rentalObjectNumber!,
                    depotExternalId: depotExternalId,
                    productType: product.productType
                };
                isAvailable = await getProductDepotAvailability(request);
            }
            setIsAvailable(isAvailable);
            setLoading(false);
        };

        fetchData();
    }, [product, depotExternalId]);

    return (
        <LoadingDecorator className={styles.loadingContainer} hideConentOnLoading={true} isLoading={loading}>
            <Icon name="circle" size={IconSize.X2}
                  customStyle={{color: isAvailable ? "#65BA01" : "rgb(255, 206, 14)"}}
                  onClick={async () => await onIconClick()}
            />
        </LoadingDecorator>
    );
};

export default ProductDepotAvailabilityIcon;