import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    ButtonType,
    Checkbox,
    Dropdown,
    DropdownSelectType,
    EmailInput,
    EmailValidator,
    Form,
    TextInput,
} from "@renta-apps/athenaeum-react-components";
import ApplyForCompanyAccountRequest from "@/models/server/ApplyForCompanyAccountRequest";
import Localizer from "@/localization/Localizer";
import DepotModel from "@/models/server/DepotModel";
import styles from "./ApplyCompanyAccount.module.scss";
import UserExternalVerificationInfoModal from "@/components/ApplyCompanyAccount/UserExternalVerificationInfoModal";
import {ApiProvider, ch, PageRouteProvider, UserInteractionDataStorage} from "@renta-apps/athenaeum-react-common";
import PageDefinitions from "@/providers/PageDefinitions";
import {getRentalOffices} from "@/services/DepotsService";
import UserContext from "@/models/server/UserContext";
import {needToCompleteStrongAuthentication} from "@/services/AccountService";
import UnleashHelper from "@/helpers/UnleashHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {invokeSignicatSsoAsync} from "@/services/AuthenticationService";

const APPLY_COMPANY_ACCESS_FORM_KEY = "apply_company_access_form";

const ApplyCompanyAccountForm: React.FC = () => {
    const [requestObject, setRequestObject] = useState(new ApplyForCompanyAccountRequest());
    const [selectedRentalOffice, setSelectedRentalOffice] = useState<DepotModel | null>(null);
    const [rentalOfficeDropdown, setRentalOfficeDropdown] = useState<DepotModel[]>([]);
    const [isUserExternalVerificationInfoModalOpen, setIsUserExternalVerificationInfoModalOpen] = useState(false);
    const [constructionSitesInput, setConstructionSitesInput] = useState("");
    const [needStrongAuthentication, setNeedStrongAuthentication] = useState(false);



    const {userFavoriteLocation, hasStronglyAuthenticated} = useMemo(() => {
        const userContext = ch.getContext() as UserContext;
        return {
            userFavoriteLocation: userContext.user!.favoriteDepotId,
            hasStronglyAuthenticated: userContext.user!.strongAuthBoundToUser
        };
    }, []);

    const requiredStrongAuthIfCompanyMissing = useMemo(() => {
        return UnleashHelper.isEnabled(RentaEasyConstants.featureFlagCompanyRequestStrongAuth);
    }, []);

    const isFormValid = useMemo(() => {
        const isEmailAddressValid =
            requestObject.managerEmail &&
            EmailValidator.instance.validate(requestObject.managerEmail) === null;

        return (
            requestObject.vatId &&
            requestObject.customerNumber &&
            requestObject.managerName &&
            isEmailAddressValid &&
            requestObject.selectedRentalOfficeId
        );
    }, [requestObject]);

    useEffect(() => {
        const initializeAsync = async () => {
            const depots = await getRentalOffices();

            //In case user completed strong authentication from this page
            //we will have data stored here
            const requestFromLocalStorage: ApplyForCompanyAccountRequest = UserInteractionDataStorage.get(APPLY_COMPANY_ACCESS_FORM_KEY) as ApplyForCompanyAccountRequest;

            if (requestFromLocalStorage) {
                setRequestObject(requestFromLocalStorage);
                UserInteractionDataStorage.set(APPLY_COMPANY_ACCESS_FORM_KEY, null)
            }

            const selectedOffice = userFavoriteLocation
                ? depots.find((office) => office.id === userFavoriteLocation)
                : null;

            if (selectedOffice) {
                setRequestObject((prev) => ({
                    ...prev,
                    selectedRentalOfficeId: selectedOffice.id,
                }));
                setSelectedRentalOffice(selectedOffice);
            }
            setRentalOfficeDropdown(depots);
        };

        initializeAsync();
    }, [userFavoriteLocation]);

    useEffect(() => {
        const checkStrongAuthentication = async () => {
            if (
                requiredStrongAuthIfCompanyMissing &&
                requestObject.vatId &&
                requestObject.customerNumber &&
                requestObject.managerEmail &&
                !needStrongAuthentication
            ) {
                const isEmailAddressValid =
                    EmailValidator.instance.validate(requestObject.managerEmail) === null;

                if (isEmailAddressValid) {
                    const response = await needToCompleteStrongAuthentication(requestObject);
                    if (response) {
                        setNeedStrongAuthentication(true);
                    }
                }
            }
        };

        checkStrongAuthentication().catch();
    }, [requestObject, needStrongAuthentication]);

    const sendApplyRequestAsync = async () => {
        const isAdditionalExternalValidationIsRequired: boolean = await ApiProvider.postAsync("/api/Account/ApplyCompanyAccount", requestObject);

        if (isAdditionalExternalValidationIsRequired) {
            setIsUserExternalVerificationInfoModalOpen(true);
        }
    };

    const saveFormAndOpenStrongAuth = async () => {
        UserInteractionDataStorage.set(APPLY_COMPANY_ACCESS_FORM_KEY, requestObject);

        await invokeSignicatSsoAsync();
    }

    const onUserExternalVerificationInfoModalClose = async (needsAdditionalExternalValidation: boolean) => {
        if (needsAdditionalExternalValidation) {
            window.open(Localizer.applyCompanyAccountUserExternalVerificationModalLink, "_blank");
            await PageRouteProvider.redirectAsync(PageDefinitions.frontPage.route());
            await ch.alertMessageAsync(Localizer.applyCompanyAccountUserExternalVerificationConfirmationMessage)
        }
        setIsUserExternalVerificationInfoModalOpen(false);
    };

    const handleInputChange = (key: keyof ApplyForCompanyAccountRequest, value: string) => {
        setRequestObject((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleDropdownChange = async (item: DepotModel | null) => {
        if (item) {
            setSelectedRentalOffice(item);
            handleInputChange("selectedRentalOfficeId", item.id);
        }
    };

    const handleConstructionSiteFreeTextChange = (text: string) => {
        setConstructionSitesInput(text)
        setRequestObject((prev) => ({
            ...prev,
            constructionSites: text,
        }));
    };

    const handleCheckboxChange = (checked: boolean) => {
        const newConstructionSites = checked ? Localizer.genericAll : constructionSitesInput.trim();
        setRequestObject((prev) => ({
            ...prev,
            isAllConstructionSitesRequested: checked,
            constructionSites: newConstructionSites,
        }));
    };

    return (
        <>
            <Form onSubmit={sendApplyRequestAsync}>
                <TextInput
                    required
                    id="vat_id"
                    label={Localizer.genericVatId}
                    value={requestObject.vatId || ""}
                    onChange={async (_, value) => handleInputChange("vatId", value)}
                />
                <TextInput
                    required
                    id="company_name"
                    label={Localizer.applyCompanyAccountDistrictCompany}
                    value={requestObject.customerNumber || ""}
                    onChange={async (_, value) => handleInputChange("customerNumber", value)}
                />
                <TextInput
                    required
                    id="manager_name"
                    label={Localizer.genericManagerName}
                    value={requestObject.managerName || ""}
                    onChange={async (_, value) => handleInputChange("managerName", value)}
                />
                <EmailInput
                    required
                    id="manager_email"
                    label={Localizer.genericManagerEmail}
                    value={requestObject.managerEmail || ""}
                    onChange={async (_, value) => handleInputChange("managerEmail", value)}
                />
                <Dropdown
                    required
                    id="select_depot"
                    label={Localizer.myAccountClosestRentaOffice}
                    selectType={DropdownSelectType.Background}
                    selectedItem={selectedRentalOffice}
                    items={rentalOfficeDropdown}
                    onChange={(_, item) => handleDropdownChange(item)}
                />
                <span>{Localizer.constructionsites} *</span>
                <Checkbox
                    id="all_construction_sites"
                    className={styles.allSitesColumn}
                    value={requestObject.isAllConstructionSitesRequested || false}
                    label={Localizer.genericAll}
                    onChange={async (_, checked) => handleCheckboxChange(checked)}
                />
                <TextInput
                    required
                    id="construction_sites"
                    className={styles.constructionSitesColumn}
                    label={Localizer.genericProjects}
                    value={requestObject.constructionSites || ""}
                    readonly={requestObject.isAllConstructionSitesRequested || false}
                    onChange={async (_, value) => handleConstructionSiteFreeTextChange(value)}
                    onBlur={async () =>
                        handleInputChange("constructionSites", constructionSitesInput.trim())
                    }
                />
                {
                    (!needStrongAuthentication || hasStronglyAuthenticated) && (
                        <Button
                            submit
                            id="form_submit"
                            type={ButtonType.Orange}
                            label={Localizer.genericSave}
                            disabled={!isFormValid}
                        />
                    )
                }
                {
                    (needStrongAuthentication && !hasStronglyAuthenticated) && (

                        <Button id="form_strong_authenticate"
                                type={ButtonType.Orange}
                                onClick={async () => saveFormAndOpenStrongAuth()}
                                label={Localizer.applyCompanyAccountContinueToStrongAuthentication}
                        />
                    )
                }

            </Form>

            <UserExternalVerificationInfoModal
                isOpen={isUserExternalVerificationInfoModalOpen}
                onClose={onUserExternalVerificationInfoModalClose}
            />
        </>
    );
};

export default ApplyCompanyAccountForm;