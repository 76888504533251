import Localizer from "../../../localization/Localizer";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";

describe('Desktop - Authenticated admin user catalog tests', () => {
    const randomHash: string = Date.now().getHashCode().toString();
    const categoryName: string = 'test-category_' + randomHash;

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    function openSelectedCategory(category: string): void {
        let categoryOpenInput: string = `#open_category_button_${category}`;
        cy.get(categoryOpenInput)
            .click();
    }

    function openNewProductModal(category: string): void {
        onRedirect(() => pageData().rent.visitWithIntercept());

        // Wait until first category is rendered before clicking edit
        pageData().rent.firstCategoryItem();

        pageData().rent.editButton().click();
        openSelectedCategory(category);
        pageData().rent.product.addNewProductButton().click();
    }

    function assertDuplicatedTagValidationMessageExists() {
        pageData().rent.product.tag.addNewTagInputGroup().should('contain', Localizer.newProductModalDuplicatedTagValidationMessageText);
    }

    function addNewTag(tagValue: string, showTagInput = true) {
        if (showTagInput) {
            pageData().rent.product.tag.addNewTagButton().click()
        }
        pageData().rent.product.tag.addNewTagInput().clear();
        pageData().rent.product.tag.addNewTagInput().type(tagValue);
        pageData().rent.product.tag.addNewTagSubmitButton().click();
    }

    it('Opens catalog page, creates new category, creates new invalid product and validates that product could not be created', () => {

        pageData().rent.visitWithIntercept();



        // Wait until first category is rendered before clicking edit
        pageData().rent.firstCategoryItem();
        pageData().rent.editButton().click();
        pageData().rent.addCategoryButton().click();

        pageData().rent.editProducts.newCategoryInput().type(categoryName);
        pageData().rent.editProducts.newCategorySubmit().click();

        cy.get('.alert-success')
            .should('contain', categoryName)
            .find('.athenaeum-alert-close')
            .first()
            .click();

        let categoryInput: string = `#input_category_name_${categoryName}`;
        cy.get(categoryInput);

        openSelectedCategory(categoryName);

        pageData().rent.editProducts.newProductButton().click();

        let testProductName: string = 'test_product_' + randomHash;

        pageData().rent.editProducts.newProductNameInput().type(testProductName);
        pageData().rent.editProducts.newProductExternalIdInput().type(randomHash);
        pageData().rent.editProducts.newProductRentalBasis().type('3');


        executeWithIntercept(() => pageData().rent.editProducts.newProductSubmit().click(),
            [pageData().rent.routes.newProduct as RouteData]);

        cy.get('.alert-warning').should('contain', `${Localizer.get(Localizer.productManagementFailedAlertInvalidRentalObjectNumber, randomHash)}`);
    });

    it('Blocks new product submition when the newProductName input and the newProductExternalId input are empty', () => {
        openNewProductModal(categoryName);

        pageData().rent.product.submitNewProductButton().should('be.disabled');
    });

    it('Unblocks new product submition when the newProductName input and the newProductExternalId input are not empty', () => {
        openNewProductModal(categoryName);

        pageData().rent.product.newProductNameInput().type('Product name');
        pageData().rent.product.newProductExternalIdInput().type('123-456');

        pageData().rent.product.submitNewProductButton().should('not.be.disabled');
    });

    it('Blocks new tag submition when the tag is duplicated', () => {
        openNewProductModal(categoryName);

        const duplicatedTagName = 'Tag A';

        addNewTag(duplicatedTagName);
        addNewTag(duplicatedTagName);

        assertDuplicatedTagValidationMessageExists();
    });

    it('Unblocks new tag submition when the duplicated tag is removed', () => {
        openNewProductModal(categoryName);

        const firstTag = 'Tag B';
        const secondTag = firstTag.toUpperCase();

        addNewTag(firstTag);
        addNewTag(secondTag);

        assertDuplicatedTagValidationMessageExists();

        pageData().rent.product.tag.firstTagDeleteButton().click();
        pageData().rent.product.tag.addNewTagSubmitButton().click();

        // verify whether tag has been added
        pageData().rent.product.tag.firstTag().should('contain', secondTag);
    });

    it('Validates tags in case-sensitive manner, once tags are being populated', () => {
        openNewProductModal(categoryName);

        const firstTag = 'Tag A';
        const secondTag = firstTag.toLowerCase();
        const thirdTag = firstTag.toUpperCase();

        addNewTag(firstTag);
        addNewTag(secondTag);

        assertDuplicatedTagValidationMessageExists();

        addNewTag(thirdTag, false);

        assertDuplicatedTagValidationMessageExists();
    });
});