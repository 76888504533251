// @ts-ignore
import Localizer from "@/localization/Localizer";
import {executeWithIntercept, onRedirect, pageData, RouteData} from "@/helpers/CypressHelper";

context("(Desktop) Construction site page: Emissions tab", () => {

    beforeEach(() => {
        cy.useSharedAdminUserSession();

        cy.intercept('POST', pageData().constructionSite.routes.getEquipmentEmissionData.path, { fixture: 'constructionSiteDetailsEmissions.json' });

        onRedirect(() => {
            executeWithIntercept(() =>
                    cy.visit(`${Localizer.pageRoutesConstructionSiteDetails}?id=f7422461-9c46-416d-b500-7ab6ae1ee591`),
                [pageData().constructionSite.routes.getConstructionSiteDetails as RouteData]
            );
        });

    });

    it("Open emissions tab and verify chart renders", () => {

        executeWithIntercept(() =>
            pageData().constructionSite.emissions.tab().click(),
            [pageData().constructionSite.routes.getEquipmentEmissionData as RouteData]
        );

        pageData().constructionSite.emissions.infoBox()
            .should('be.visible')
            .children('div')
            .should('have.length', 3)

        pageData().constructionSite.emissions.infoBox()
            .children('div')
            .eq(0)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 4)

        pageData().constructionSite.emissions.infoBox()
            .children('div')
            .eq(1)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 0)


        pageData().constructionSite.emissions.infoBox()
            .children('div')
            .eq(2)
            .children('div')
            .eq(1)
            .children('div')
            .eq(0)
            .should('contain', 187.9)
    });

});
