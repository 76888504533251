import React from 'react';
import styles from './DeviceBottomSheet.module.scss';
import Localizer from "@/localization/Localizer";
import {Button, ButtonType} from "@renta-apps/renta-react-components";
import EasyPlusFeatureButton from "@/components/EasyPlus/EasyPlusFeatureButton/EasyPlusFeatureButton";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {FeatureSwitch} from "@/providers/FeatureSwitch";

interface IDeviceBottomSheetProps {
    isVisible: boolean;
    isReturnRequestButtonEnabled: boolean;
    onAdjustAlertsClick: () => void;
    onRequestReturnClick: () => void;
}

const DeviceBottomSheet: React.FC<IDeviceBottomSheetProps> = ({onAdjustAlertsClick, onRequestReturnClick, isVisible, isReturnRequestButtonEnabled}) => {
    const buttonSheetContainerStyle = {
        transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.3s ease-out',
    };

    return (
        <div id="deviceBottomSheet" className={styles.deviceBottomSheetContainer} style={buttonSheetContainerStyle}>
            <div>{Localizer.fleetMonitoringPageBottomSheetForSelected}</div>
            <div className={styles.buttons}>
                <FeatureSwitch flagName={RentaEasyConstants.featureFlagRentalReturnRequest}>
                    <Button type={ButtonType.Primary} onClick={() => onRequestReturnClick()} disabled={!isReturnRequestButtonEnabled}>
                        {Localizer.fleetMonitoringPageBottomSheetRequestReturn}
                    </Button>
                </FeatureSwitch>
                <EasyPlusFeatureButton type={ButtonType.Primary} onClick={() => onAdjustAlertsClick()}>
                    {Localizer.fleetMonitoringPageBottomSheetAdjustAlerts}
                </EasyPlusFeatureButton>
            </div>
        </div>
    );
};

export default DeviceBottomSheet;