import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./NoDevicesInfoPanel.module.scss";
import {Text} from "@renta-apps/renta-react-components";

const NoDevicesInfoPanel: React.FC = () => {
    return (
        <div className={styles.container} id={"power-no-device-panel-container"}>
            <div className={styles.backgroundElement}>
                <div className={styles.textContent}  data-cy="text-content">
                    <Text size="XL" weight="bold" className={`${styles.centerText}`}>
                        {Localizer.constructionSiteDetailsPowerNoDevicesInfoPanelLine1}
                    </Text>
                    <Text className={styles.centerText}>
                        {Localizer.constructionSiteDetailsPowerNoDevicesInfoPanelLine2}
                    </Text>
                </div>
                <div className={styles.images} data-cy="images">
                    <img className={styles.chartImage}
                        src={"/images/power_consumption_chart.png"}
                        alt={""}
                    />
                    <img className={styles.chartImage}
                        src={"/images/max_current_chart.png"}
                        alt={""}
                    />
                </div>
            </div>
        </div>
    );
};

export default NoDevicesInfoPanel;