import React from "react";
import Localizer from "@/localization/Localizer";
import styles from "./EasyPlusInfoPanelPower.module.scss";
import EasyPlusLink from "@/components/EasyPlus/EasyPlusLink/EasyPlusLink";
import {Text} from "@renta-apps/renta-react-components";

const EasyPlusInfoPanelPower: React.FC = () => {
    return (
        <div className={styles.container} id={"easy-plus-panel-power-container"}>
            <div className={styles.backgroundElement}>
                <div className={styles.textContent}  data-cy="text-content">
                    <Text size="XL" weight="bold" className={`${styles.centerText}`}>
                        {Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine1}
                    </Text>
                    <Text className={styles.centerText}>
                        {Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine2}
                    </Text>
                    <Text className={styles.centerText}>
                        {Localizer.constructionSiteDetailsPowerEasyPlusInfoPanelLine3}
                    </Text>
                </div>
                <div className={styles.images} data-cy="images">
                    <img className={styles.chartImage}
                        src={"/images/power_consumption_chart.png"}
                        alt={""}
                    />
                    <img className={styles.chartImage}
                        src={"/images/max_current_chart.png"}
                        alt={""}
                    />
                </div>
                <EasyPlusLink mode="button">
                    {Localizer.easyPlusNewSubscriptionInfoButton}
                </EasyPlusLink>
            </div>
        </div>
    );
};

export default EasyPlusInfoPanelPower;