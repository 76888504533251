import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe('Desktop - Fleet Monitoring - Filters statuses tests - admin user', () => {
    before(() => {
        cy.clearAllLocalStorage()
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('should display status dropdown for admin', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.statuses.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetReturnsPagedList.json'});
        });

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', { fixture: 'constructionSiteInfos.json' });

        cy.visit('/fleet-monitoring?selectedTab=palautuspyynn%C3%B6t');

        DeviceGridHelper.getDropdown("filter-by-status-dropdown")
            .get('[data-cy="dropdownLabel"]')
            .should('contain.text', Localizer.fleetMonitoringPageFiltersLabelStatus);

        DeviceGridHelper.getDropdown("filter-by-status-dropdown").should("be.visible");

        DeviceGridHelper.getDropdown("filter-by-status-dropdown").click();

        DeviceGridHelper.clickDropdownListItem("filter-by-status-dropdown", 2);

        DeviceGridHelper.checkDropdownValue("filter-by-status-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

        DeviceGridHelper.checkTags([Localizer.fleetMonitoringPageGridStatusDone]);

        cy.wait('@postRequestFiltered').then(({ request }) => {
            expect(request.body).to.have.nested.property('statuses')
            expect(request.body.statuses).to.have.length(1);
            expect(request.body.statuses[0].name === Localizer.fleetMonitoringPageGridStatusDone);
            expect(request.body.statuses[0].id === RentaEasyConstants.FleetMonitoringConstants.statusDone);
        });
    });

    it('should set status dropdown with the value obtain from URL', () => {
        cy.intercept('POST', '/api/Fleet/GetReturnsPagedList', req => {
            if (req.body.statuses.length) {
                req.alias = 'postRequestFiltered';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({ fixture: 'fleetGetReturnsPagedList.json' });
        });

        cy.visit(`/fleet-monitoring?statuses=${JSON.stringify([{name:Localizer.fleetMonitoringPageGridStatusDone}])}&selectedTab=palautuspyynn%C3%B6t`);

        DeviceGridHelper.checkTags([Localizer.fleetMonitoringPageGridStatusDone]);

        cy.wait('@postRequestFiltered').then(() => {
            DeviceGridHelper.checkDropdownValue("filter-by-status-dropdown", Localizer.get(Localizer.componentDropdownMultipleSelectedLanguageItemName, 1));

            DeviceGridHelper.checkTags([Localizer.fleetMonitoringPageGridStatusDone]);
        });
    });
});