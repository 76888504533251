import React, {FC, useEffect} from "react";
import styles from "@/pages/ShoppingCart/ShoppingCart.module.scss";
import Localizer from "@/localization/Localizer";
import OrganizationContractDiscounts from "@/models/server/OrganizationContractDiscounts";
import {
    Accordion,
} from "@renta-apps/athenaeum-react-components";import ProductCard, {ProductCardAvailability, ProductCardStyle} from "@/components/ProductCard/ProductCard";
import ProductModel from "@/models/server/ProductModel";
import ProductOrganizationContractPriceModel from "@/models/server/ProductOrganizationContractPriceModel";
import {RequestState} from "@/models/Enums";

type ShoppingCartProductsProps = {
    isSpinning: boolean;
    rentalProducts: any[];
    salesProducts: any[];
    attachedRentalProducts: any[];
    attachedSalesProducts: any[];
    hasProducts: boolean;
    hasRentalProductsExcludingAttachedRentalProducts: boolean;
    hasRentalProducts: boolean;
    hasSalesProducts: boolean;
    contractDiscounts: OrganizationContractDiscounts | null;
    unavailableProductsLoaded: RequestState;
    unavailableProductIds: string[];
    vat: number;
    startDate: Date | null;
    estimatedEndDate: Date | null;
    setProductEstimatedReturnDateAsync: (productId: string, estimatedReturnDate: Date) => Promise<void>;
    onUpdateProductCount: (productId: string, newCount: number, productName: string | null) => Promise<void>;
    showProductReturnDate: boolean;
}

const ShoppingCartProducts: FC<ShoppingCartProductsProps> = (props: ShoppingCartProductsProps) => {

    const _rentalAccordionRef: React.RefObject<Accordion> = React.createRef();
    const _salesAccordionRef: React.RefObject<Accordion> = React.createRef();

    useEffect(() => {
        recalculateProductContainerHeights();
    }, [props.rentalProducts, props.salesProducts, props.attachedRentalProducts, props.attachedSalesProducts, props.unavailableProductIds]);
    
    const recalculateProductContainerHeights = (): void => {
        if (_rentalAccordionRef.current) {
            _rentalAccordionRef.current.recalculateContentHeight();
        }
    
        if (_salesAccordionRef.current) {
            _salesAccordionRef.current.recalculateContentHeight();
        }
    }
    
    const getProductsDiscount = (product: ProductModel): ProductOrganizationContractPriceModel | undefined => {
        return props.contractDiscounts?.productSpecificPrices?.find(
            productPrice =>
                productPrice.externalId === product.externalId);
    }
    
    const getProductAvailability = (product: ProductModel): ProductCardAvailability => {
        return (props.unavailableProductsLoaded === RequestState.Sending)
            ? ProductCardAvailability.Loading
            : (props.unavailableProductIds.find(unavailableProductId => product.id === unavailableProductId))
                ? ProductCardAvailability.Unavailable
                : ProductCardAvailability.Available;
    }

    return (
        <>
            {(props.hasRentalProductsExcludingAttachedRentalProducts) && (
                <Accordion expanded
                           ref={_rentalAccordionRef}
                           className="new-accordion-style-v2"
                           autoCollapse={false}
                           header={Localizer.shoppingCartPageRentalProducts}
                >
                    {props.rentalProducts.map(
                        rentalShoppingCartProduct =>
                            <ProductCard displayAddToCartButton displayDiscountText displayRemoveFromCartButton inlinePriceRows
                                         key={rentalShoppingCartProduct.product!.id}
                                         className={styles.productCard}
                                         style={ProductCardStyle.TwoColumns}
                                         product={rentalShoppingCartProduct.product!}
                                         count={rentalShoppingCartProduct.count}
                                         readonly={props.isSpinning}
                                         availability={getProductAvailability(rentalShoppingCartProduct.product!)}
                                         vat={props.vat}
                                         discount={getProductsDiscount(rentalShoppingCartProduct.product!)}
                                         onUpdateProductCount={(productId, newCount, productName) => props.onUpdateProductCount(productId, newCount, productName)}
                                         estimatedReturnDate={rentalShoppingCartProduct.endDate ?? undefined}
                                         estimatedReturnDateMin={props.startDate ?? new Date(Date.now())}
                                         estimatedReturnDateMax={props.estimatedEndDate ?? undefined}
                                         onUpdateEstimatedReturnDate={async (productId, estimatedReturnDate) => await props.setProductEstimatedReturnDateAsync(productId, estimatedReturnDate)}
                                         displayEstimatedReturnDateInput={props.showProductReturnDate}
                                         displayAttachedProducts
                            />
                    )}
                </Accordion>
            )}

            {(props.hasSalesProducts) && (
                <Accordion expanded
                           ref={_salesAccordionRef}
                           classNames={{
                               content: styles.accordionContent
                           }}
                           className="new-accordion-style-v2"
                           autoCollapse={false}
                           header={Localizer.shoppingCartPageSalesProducts}
                >
                    {props.salesProducts.map((salesShoppingCartProduct) =>
                        <ProductCard displayAddToCartButton displayDiscountText displayRemoveFromCartButton inlinePriceRows
                                     key={salesShoppingCartProduct.product?.id}
                                     className={styles.productCard}
                                     style={ProductCardStyle.TwoColumns}
                                     product={salesShoppingCartProduct.product!}
                                     count={salesShoppingCartProduct.count}
                                     readonly={props.isSpinning}
                                     availability={getProductAvailability(salesShoppingCartProduct.product!)}
                                     vat={props.vat}
                                     discount={getProductsDiscount(salesShoppingCartProduct.product!)}
                                     onUpdateProductCount={(productId, newCount, productName) => props.onUpdateProductCount(productId, newCount, productName)}
                                     displayAttachedProducts
                        />
                    )}
                </Accordion>
            )}
        </>
    );
}

export default ShoppingCartProducts