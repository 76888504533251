import Localizer from "@/localization/Localizer";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";
import { executeWithIntercept, onRedirect, pageData, RouteData } from "@/helpers/CypressHelper";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe('Desktop - Shopping Cart - Elements tests', () => {

    before(() => {
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });

    });

    it('should render the shopping cart with related products', () => {

        cy.intercept('POST', '/api/ShoppingCart/GetShoppingCart', { fixture: 'getShoppingCart.json' });

        redirectToShoppingCart();

        cy.get('h3')
            .should('contain', Localizer.productDetailsAccessories);

        cy.get('.swiper-slide')
            .should('have.length', 7);

    });

    it('should change price for a selected construction site', () => {

        cy.intercept('POST', '/api/ShoppingCart/GetShoppingCart', { fixture: 'getShoppingCartOneItem.json' });

        redirectToShoppingCart();

        // Default price.
        pageData().rent.product.price()
            .should('contain.text', '485,63');

        pageData().rent.shoppingCart.companyDropDown().should("exist");

        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteDiscounts', { fixture: 'getConstructionSiteDiscounts.json' });

        // Select different Construction Site.
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#construction-site-selection", 2);

        // Price for a different construction site.
        pageData().rent.product.price()
            .should('contain.text', '664,40');

        fillOrder();
        sendOrder();

        cy.intercept('POST', '/api/Product/OrderDetails', { fixture: 'orderDetails.json' });

        pageData().rent.product.price()
            .should('contain.text', '664,40');

    });

    it('should render the shopping cart with invoice reference, delivery contact, construction site external reference, and construction sites dropdown including external id', () => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagShoppingCartInvoiceReference);
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagShoppingCartDeliveryContact);
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagShoppingCartConstructionSiteExternalReference);
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagShoppingCartConstructionSitesDropdownIncludingExternalId);
        
        cy.intercept('POST', '/api/ShoppingCart/GetShoppingCart', { fixture: 'getShoppingCartOneItem.json' });

        redirectToShoppingCart();
        
        // invoice reference, delivery contact, and construction site external reference inputs are visible
        pageData().rent.shoppingCart.invoiceReferenceInput().should('exist');
        pageData().rent.shoppingCart.deliveryContactInput().should('exist');
        pageData().rent.shoppingCart.constructionSiteExternalReferenceInput().should('exist');
        
        // subtext should contain external reference and external id
        cy.get('#construction-site-selection .athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-item').find('small').should('contain.text', 'TestSite7ExternalReference TestSite7ExternalId');

        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagShoppingCartInvoiceReference);
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagShoppingCartDeliveryContact);
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagShoppingCartConstructionSiteExternalReference);
        CypressFeatureFlagService.disable(RentaEasyConstants.featureFlagShoppingCartConstructionSitesDropdownIncludingExternalId);
    });

    function fillOrder() {
        // Select the first available delivery type
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#shopping_cart_dropdown_delivery_type", 0);

        // Select the first available depot
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#shopping_cart_dropdown_depot", 0);

        // Select first available day
        pageData().shoppingCart.dateRangeInput()
            .click()
            .find(".athenaeum-date-range-input-isToday")
            .then(($btn: any) => {
                if ($btn.hasClass('athenaeum-date-range-input-gridDayDisabled')) {
                    pageData().shoppingCart.dateRangeInput()
                        .find(".athenaeum-date-range-input-monthViewGridDay")
                        .not(".athenaeum-date-range-input-isToday")
                        .not(".athenaeum-date-range-input-gridDayDisabled")
                        .not(".athenaeum-date-range-input-isOutOfRange")
                        .first()
                        .click();
                }
                else {
                    $btn.click();
                }
            });

        //Select next month if current month has only single not disabled day left.
        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .children()
            .then((children) => {
                if (children.length === 1) {
                    cy.get(".athenaeum-date-range-input-monthAction")
                        .last()
                        .click();
                }
            });

        cy.get(".athenaeum-date-range-input-disabledDayHint").should('not.exist');

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay.athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        cy.get(".athenaeum-date-range-input-disabledDayHint")
            .find("label")
            .should("contain.text", Localizer.shoppingCartPageCannotRentOrReturnOnAWeekend);

        pageData().shoppingCart.dateRangeInput()
            .find(".athenaeum-date-range-input-monthViewGridDay")
            .not(".athenaeum-date-range-input-isToday")
            .not(".athenaeum-date-range-input-gridDayDisabled")
            .not(".athenaeum-date-range-input-isOutOfRange")
            .first()
            .click();

        cy.get("#shopping_cart_submit").trigger('click');
    }

    function redirectToShoppingCart(): void {
        onRedirect(() => executeWithIntercept(() => cy.visit("/ShoppingCart"),
            [
                pageData().common.routes.depots as RouteData,
                pageData().constructionSites.routes.sitesForContract as RouteData
            ]));
    }

    function sendOrder() {
        executeWithIntercept(() =>
            pageData().shoppingCart.confirmationDialog()
                .click(),
            [pageData().shoppingCart.routes.sendOrder as RouteData]);
    }
});