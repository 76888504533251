import {pageData} from "@/helpers/CypressHelper";

describe('Desktop - Authenticated admin user categories priority tests', () => {
    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    function addNewCategory(categoryName: string, index: number) {
        pageData().rent.addCategoryButton().click();

        pageData().rent.editProducts.newCategoryInput().type(categoryName);
        pageData().rent.editProducts.newCategorySubmit().click();

        cy.get('.alert-success')
            .should('contain', categoryName)
            .find('.athenaeum-alert-close')
            .first()
            .click();

        pageData().rent.nthCategoryItem(index).find('.category-priority').first().should('have.text', index.toString())
    }

    function deleteCategory(index: number, categoryName: string) {
        pageData().rent.nthCategoryItem(index).find('.category-delete').first().click();

        cy.get('.alert-success').should('contain', categoryName);
        cy.get('.alert-success').find('.athenaeum-alert-close').first().click();
    }

    function checkCategoryAtIndex(index: number, categoryName: string) {
        pageData().rent.nthCategoryItem(index).find('.category-priority').first().should('have.text', index.toString());
        pageData().rent.nthCategoryItem(index).find('input[type="text"]').first().should('have.value', categoryName);
    }

    it('Opens catalog page, creates new categories, changes priority, deletes', () => {
        pageData().rent.visitWithIntercept('emptycategory');



        pageData().rent.editButton().click();

        for (let i = 0; i < 3; i++) {
            addNewCategory(`test_category_${i}`, i);
        }

        pageData().rent.nthCategoryItem(2).find('.category-priority-up').first().click();

        checkCategoryAtIndex(1, 'test_category_2');
        checkCategoryAtIndex(2, 'test_category_1');

        pageData().rent.nthCategoryItem(0).find('.category-priority-down').first().click();

        checkCategoryAtIndex(0, 'test_category_2');
        checkCategoryAtIndex(1, 'test_category_0');

        deleteCategory(1, 'test_category_0');

        checkCategoryAtIndex(1, 'test_category_1');
        pageData().rent.nthCategoryItem(2).should('not.exist');

        deleteCategory(0, 'test_category_2');
        deleteCategory(0, 'test_category_1');
    });
});