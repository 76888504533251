import Localizer from "@/localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";
import DropdownHelper from "@/helpers/CypressDropdownHelper";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";

describe('Desktop - Fleet Monitoring - Devices pagination tests', () => {
    before(() => {
        cy.clearAllLocalStorage();
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should change URL when fleet monitoring button is clicked and render empty grid with pagination', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedListEmpty.json'});

        cy.visit('frontpage');

        pageData().frontPage.widgets.fleetMonitoring()
            .should('exist')
            .trigger('click');



        cy.url()
            .should('include', Localizer.pageRoutesFleetMonitoring);

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        DeviceGridHelper.getPagination()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationPageSelection()
            .children('div')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationArrowItem(0)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationPageItem(1)
            .should('contain', 1);

        DeviceGridHelper.getPaginationArrowItem(2)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownInput"]')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(1)
            .should('contain', '0 / 0');
    });

    it('should render pagination correctly when there are devices', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'});

        cy.visit('/fleet-monitoring');



        DeviceGridHelper.getPagination()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationPageSelection()
            .children('div')
            .should('have.length', 7);

        DeviceGridHelper.getPaginationArrowItem(0)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationPageItem(1)
            .should('contain', 1)
            .should(($div) => {
                expect($div.attr('class')).to.include('active');
            });

        DeviceGridHelper.getPaginationPageItem(2)
            .should('contain', 2)
            .should(($div) => {
                expect($div.attr('class')).to.include('clickable');
            });

        DeviceGridHelper.getPaginationPageItem(3)
            .should('contain', 3);

        DeviceGridHelper.getPaginationPageItem(4)
            .should('contain', '...');

        DeviceGridHelper.getPaginationPageItem(5)
            .should('contain', 102);

        DeviceGridHelper.getPaginationArrowItem(6)
            .children('svg')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .should('have.length', 2);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownInput"]')
            .should('exist');

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(0)
            .find('[data-cy="dropdownItem"]')
            .should('have.length', 3);

        DeviceGridHelper.getPaginationItems()
            .children('div')
            .eq(1)
            .should('contain', '25 / 2540');
    });

    it('should call API when the page size value is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.pageSize > 25) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedList.json'});
        });

        cy.visit('/fleet-monitoring');



        cy.wait('@postRequest').then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('pageSize', 25);
            expect(request.body).to.have.property('pageNumber', 1);

            // Select "50" option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.pagination.dropdown(), 1);

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('pageSize', 50);
                expect(request.body).to.have.property('pageNumber', 1);

                cy.url().should('include', `pageNumber=1&pageSize=50&sortBy=IdleDays&sortOrder=Desc`);

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 50);
            });
        });
    });

    it('should call API when the page number is changed', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', req => {
            if (req.body.pageNumber > 1) {
                req.alias = 'postRequestChanged';
            } else {
                req.alias = 'postRequest';
            }
            req.reply({fixture: 'fleetGetDevicesPagedList.json'});
        });

        cy.visit('/fleet-monitoring');



        cy.wait('@postRequest').then(({request}) => {
            // Assert the initial request body
            expect(request.body).to.have.property('pageSize', 25);
            expect(request.body).to.have.property('pageNumber', 1);

            DeviceGridHelper.getPaginationPageItem(3)
                .trigger('click');

            // Wait for the second intercepted POST request to the API endpoint
            cy.wait('@postRequestChanged').then(({request}) => {
                // Assert that the request body contains the changed value
                expect(request.body).to.have.property('pageSize', 25);
                expect(request.body).to.have.property('pageNumber', 3);

                cy.url().should('include', `pageNumber=3&pageSize=25&sortBy=IdleDays&sortOrder=Desc`);

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 25);
            });
        });
    });

    // for some reason first execution of this test fails, when run after 'should set input fields with the default values correctly'
    it('should remember user\'s previous selections when the user returns to fleet monitoring', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'}).as('postRequest');
        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', {fixture: 'constructionSiteInfos.json'});

        cy.visit('/fleet-monitoring');



        cy.wait('@postRequest').then(() => {
            // Select "50" option from the dropdown
            DropdownHelper.selectDropdownOption(pageData().fleetMonitoring.pagination.dropdown(), 1);
            // Select the second page of results
            DeviceGridHelper.getPaginationPageItem(2)
                .should(($div) => {
                    expect($div.attr('class')).to.include('clickable');
                })
                .trigger('click');

            cy.wait('@postRequest').then(() => {
                // Double check the options are set
                DeviceGridHelper.getPaginationPageItem(2)
                    .should('contain', 2)
                    .should(($div) => {
                        expect($div.attr('class')).to.include('active');
                    });

                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 50);

                // Leave the page
                cy.visit('/rent');

                // Go back to the page
                cy.visit('/fleet-monitoring');

                cy.wait('@postRequest').then(({request}) => {
                    expect(request.body).to.have.property('pageNumber', 2);

                    // Options are remembered
                    DeviceGridHelper.getPaginationPageItem(2)
                        .should('contain', 2)
                        .should(($div) => {
                            expect($div.attr('class')).to.include('active');
                        });

                    DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                        .should('contain.text', 50);
                });
            });
        });
    });

    it('should set input fields with the default values correctly', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', {fixture: 'fleetGetDevicesPagedList.json'}).as('postRequest');
        cy.intercept('POST', '/api/ConstructionSites/GetConstructionSiteInfos', {fixture: 'constructionSiteInfos.json'});

        cy.visit('/fleet-monitoring?pageNumber=3&pageSize=100');



        cy.wait('@postRequest').then(() => {
            DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                .should('contain.text', 100);

            DeviceGridHelper.getPaginationPageItem(3)
                .should('contain', 3)
                .should(($div) => {
                    expect($div.attr('class')).to.include('active');
                });

            cy.visit('/fleet-monitoring?constructionSiteId=9f376401-eb20-4bf9-a8e9-59db6c6ea7e5&pageNumber=4&pageSize=50&sortBy=IdleDays&sortOrder=Desc');

            cy.wait('@postRequest').then(() => {
                DropdownHelper.getSelectedItem(pageData().fleetMonitoring.pagination.dropdown())
                    .should('contain.text', 50);

                DeviceGridHelper.getPaginationPageItem(4)
                    .should('contain', 4)
                    .should(($div) => {
                        expect($div.attr('class')).to.include('active');
                    });
            });
        });
    });
});