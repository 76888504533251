import RentaEasyController from "@/pages/RentaEasyController";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import {ch} from "@renta-apps/athenaeum-react-common";

function redirectToIdentityProvider(httpResponse: Response): void {
    if (httpResponse.headers.get("location")) {
        window.location.href = httpResponse.headers.get("location")!;
    }
}

async function getChallengeUrlAsync(ssoProvider: string): Promise<Response> {
    return await RentaEasyController.get(`/api/Application/${ssoProvider}`);
}

export const invokeSignicatSsoAsync = async (): Promise<void> => {
    const httpResponse = await getChallengeUrlAsync("GetSignicatSsoLoginUrl");
    redirectToIdentityProvider(httpResponse);
};

export const invokeGoogleSsoAsync = async (): Promise<void> => {
    const httpResponse = await getChallengeUrlAsync("GetGoogleSsoLogin");
    redirectToIdentityProvider(httpResponse);
};

export const invokeMicrosoftSsoAsync = async (): Promise<void> => {
    const httpResponse = await getChallengeUrlAsync("GetAzureSsoLogin");
    redirectToIdentityProvider(httpResponse);
};