import {executeWithIntercept, pageData, RouteData} from "@/helpers/CypressHelper";

describe('Test Global Search in TopNav', () => {

    beforeEach(() => {
        cy.session(['admin_role_session_english'], cy.loginAndSelectAdminRole, {});

        cy.visit('frontpage')
    });


    it('Test Global Search in TopNav', () => {

        // change language to english
        pageData().common.topNavLanguage.menu().click();

        executeWithIntercept(() => pageData().common.topNavLanguage.english().click(),
            [pageData().common.routes.getTopNavItems as RouteData])

        // Click search icon in top nav
        pageData().common.globalSearch.container().click();

        // Enter search term
        pageData().common.globalSearch.searchInput().click();
        pageData().common.globalSearch.searchInput().type('test');

        // Click on  "Search"
        executeWithIntercept(() => pageData().common.globalSearch.searchButton().click(),
            [pageData().common.globalSearch.routes.search as RouteData])

        // Check if results are correct
        pageData().common.globalSearch.results.providerResults('Locations')?.children('.athenaeum-global-search-results-item-globaSearchResultsItem')
            .should('have.length', 3);

        pageData().common.globalSearch.results.provider('Organization contracts')?.find('.athenaeum-global-search-results-providerResults')
            .children('.athenaeum-global-search-results-item-globaSearchResultsItem')
            .should('have.length', 3);

        pageData().common.globalSearch.results.provider('Construction sites')?.find('.athenaeum-global-search-results-providerResults')
            .children('.athenaeum-global-search-results-item-globaSearchResultsItem')
            .should('have.length', 3);

        // close results - go back to search form
        pageData().common.globalSearch.results.backButton().click();
        cy.wait(500)

        // Click on "LOCATIONS"
        pageData().common.globalSearch.providerOption('Locations').click();

        // Click on  "Search"
        executeWithIntercept(() => pageData().common.globalSearch.searchButton().click(),
            [pageData().common.globalSearch.routes.search as RouteData])

        pageData().common.globalSearch.results.providers().children('.athenaeum-global-search-results-globalSearchResultsProvider').should('have.length', 1);

        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(0).should('contain.text', 'Depot1');
        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(1).should('contain.text', 'Test depot with no attributes');
        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(2).should('contain.text', 'Unit test depo');

        // Click on <div> "Depot1"
        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(0).click();
        cy.get('[data-cy=office_title]').should('contain.text', 'Depot1')

        // Click on <div> "Unit test depo"
        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(1).click();
        cy.get('[data-cy=office_title]').should('contain.text', 'Test depot with no attributes')

        // Click on <div> "Unit test depo"
        pageData().common.globalSearch.results.providerResultsChildren('Locations').eq(2).click();
        cy.get('[data-cy=office_title]').should('contain.text', 'Unit test depo')

        // Click on <button> "More" - go to GlobalSearch page
        pageData().common.globalSearch.results.moreButton().click();

        pageData().common.globalSearch.page.form().find('input').should('have.value', 'test');
        pageData().common.globalSearch.page.form().find('.athenaeum-dropdown-itemsList').children('.athenaeum-dropdown-selectedItem').should('have.length', 1)
        pageData().common.globalSearch.page.form().find('.athenaeum-dropdown-selectedItem').should('contain.text', 'Locations')

        pageData().common.globalSearch.page.results().find('.athenaeum-global-search-results-providerHeader').should('have.length', 1)
        pageData().common.globalSearch.page.results().find('.athenaeum-global-search-results-providerHeader').should('contain.text', 'Locations')
        pageData().common.globalSearch.page.results().find('.athenaeum-global-search-results-item-globaSearchResultsItem').should('have.length', 3)
    })
})