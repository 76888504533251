export default class ApplyForCompanyAccountRequest {

    public vatId: string | null = null;

    public customerNumber: string | null = null;

    public managerName: string | null = null;

    public managerEmail: string | null = null;

    public selectedRentalOfficeId: string = "";

    public constructionSites: string | null = null;

    public isAllConstructionSitesRequested: boolean = false;
}