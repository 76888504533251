import React, {useState} from 'react';
import {Button, Icon, IconSize, IDropdownItem} from '@renta-apps/renta-react-components';
import styles from './ViewControl.module.scss';
import FiltersModal from "@/pages/FleetMonitoring/Modals/FiltersModal";
import FiltersForm from "@/pages/FleetMonitoring/FiltersForm/FiltersForm";
import SelectedFilter from "@/models/server/Requests/SelectedFilter";
import {DropdownFilterFieldName} from "@/pages/FleetMonitoring/Hooks/useFiltersState";

export interface ViewControlProps {
    filters: FleetMonitoringFiltersSortAndPagination;
    userRoleConstructionSiteId: string | null;
    userRoleContractId: string | null;
    userRoleIsAdmin: boolean;
    sortByItems: IDropdownItem[];
    userCompaniesList: IDropdownItem[];
    mode: "devices" | "returns";
    onFiltersChanged: (filtersChanges: Partial<FleetMonitoringFiltersSortAndPagination>) => void;
    onDropdownFieldChanged?: (property: DropdownFilterFieldName, value: IDropdownItem[]) => void;
    onFiltersReset?: () => void;
}

export interface FleetMonitoringFiltersSortAndPagination {
    deviceNames: string[];
    companies: SelectedFilter[];
    constructionSites: SelectedFilter[];
    depots: SelectedFilter[];
    productGroups: SelectedFilter[];
    statuses: SelectedFilter[];
    sortBy: string;
    sortOrder: string;
    pageNumber: number;
    pageSize: number;
}

const ViewControl: React.FC<ViewControlProps> = ({
    filters,
    userRoleConstructionSiteId,
    userRoleContractId,
    userRoleIsAdmin,
    sortByItems,
    userCompaniesList,
    mode,
    onDropdownFieldChanged,
    onFiltersChanged,
    onFiltersReset,
}) => {
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    return (
        <>
            <FiltersForm filters={filters}
                         className={styles.viewControl}
                         userRoleContractId={userRoleContractId}
                         userRoleConstructionSiteId={userRoleConstructionSiteId}
                         userRoleIsAdmin={userRoleIsAdmin}
                         sortByItems={sortByItems}
                         userCompaniesList={userCompaniesList}
                         mode={mode}
                         onDropdownFieldChanged={onDropdownFieldChanged}
                         onFiltersChanged={onFiltersChanged}
                         onFiltersReset={onFiltersReset}
            >
                <Button id="open-filters-modal-btn" className={styles.filterButton} onClick={() => setIsFiltersModalOpen(true)}>
                    <Icon name="filter" size={IconSize.Medium} color="white"/>
                </Button>
            </FiltersForm>
            <FiltersModal isOpen={isFiltersModalOpen}
                          onClose={() => setIsFiltersModalOpen(false)}
                          filters={filters}
                          userRoleContractId={userRoleContractId}
                          userRoleConstructionSiteId={userRoleConstructionSiteId}
                          userRoleIsAdmin={userRoleIsAdmin}
                          sortByItems={sortByItems}
                          userCompaniesList={userCompaniesList}
                          mode={mode}
                          onFiltersChanged={onFiltersChanged}
            />
        </>
    );
};

export default ViewControl;