import {ch} from "@renta-apps/athenaeum-react-common";
import Localizer from "@/localization/Localizer";

export const validatePhoneNumber = (phoneNumber: string | null, length: number = 10): string | null => {
  if (ch.isDenmark) {
    return validatePhoneNumberExactLength(phoneNumber, length);
  }

  return validatePhoneNumberMinLength(phoneNumber);
};

const validatePhoneNumberMinLength = (phoneNumber: string | null): string | null => {
  let phoneRegex = /^\+([0-9]{6,})$/;
  return (!phoneNumber || !phoneNumber.match(phoneRegex)) ? Localizer.genericValidationPhoneNumber : null;
}

const validatePhoneNumberExactLength = (phoneNumber: string | null, length: number = 10): string | null => {
  let phoneRegex = new RegExp(`^\\+[0-9]{${length}}$`);
  return (!phoneNumber || !phoneNumber.match(phoneRegex)) ? Localizer.get(Localizer.genericValidationPhoneNumberExactLength, length) : null;
};

export const validateMultipleEmails = (email: string | null): string | null => {
  let multipleEmailRegex = /^([\w+-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)(?:[,;]\s*([\w+-.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+))*$/;
  return (!email || !email.match(multipleEmailRegex)) ? Localizer.validatorsEmail : null;
}