// Add export to fix compilation error TS1208
import Localizer from "@/localization/Localizer";
import {pageData} from "@/helpers/CypressHelper";

export {};


describe('Desktop - My account tests - private user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.session('api_login', () => cy.login(), {});
    });

    it('Open my account and verify page data', () => {
        cy.visit(Localizer.pageRoutesMyAccount);

        cy.get('h1').should('contain', Localizer.myAccountTitle);
        cy.get('#adminDebug').should('not.exist');

    });

});

describe('Desktop - My account tests - admin user', () => {
    before(() => {
    });

    beforeEach(() => {
        cy.useSharedAdminUserSession();
    });

    it('Open my account and verify page data', () => {
        cy.visit(Localizer.pageRoutesMyAccount);



        cy.get('#adminDebug').should("exist");
        cy.get('#adminDebug').find('label').click();

        cy.intercept('POST', '/api/Users/SaveUserShowDebugInfo').as('saveUserShowDebugInfo');

        cy.get('#save_user_debug').click();

        cy.wait('@saveUserShowDebugInfo').then((interception) => {
            const response = interception.response?.body as {
                context: {
                    user: { showDebugInfo: boolean };
                };
            };

            expect(interception.response?.statusCode).to.equal(200);
            expect(response.context.user.showDebugInfo).to.equal(true);

        });
    });

});