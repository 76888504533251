import Localizer from "../../../localization/Localizer";
import CypressFeatureFlagService from "@/helpers/CypressFeatureFlagService";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";
import CypressDropdownHelper from "@/helpers/CypressDropdownHelper";
import {pageData} from "@/helpers/CypressHelper";

describe("Desktop - Request company access", () => {
    beforeEach(() => {
        cy.session('private_user_session', () => cy.loginSpecificUser("private.user@domain.fi", "Onet.xml1"), {});
    });

    it("Assert form validation works", () => {
        cy.visit(Localizer.pageRoutesApplyCompanyAccount);


        assertInputError("#vat_id");
        assertInputError("#company_name");
        assertInputError("#manager_email");
        assertInputError("#manager_name");
        assertInputError("#construction_sites");
    });

    it("Assert strong auth process needed when company doesn't exist", () => {
        pageData().common.interceptFeatureFlags([{flagName: RentaEasyConstants.featureFlagCompanyRequestStrongAuth, enabled: true}])

        cy.visit(Localizer.pageRoutesApplyCompanyAccount);

        cy.intercept(pageData().applyCompanyAccount.routes.needToCompleteStrongAuthentication.method,
            pageData().applyCompanyAccount.routes.needToCompleteStrongAuthentication.path
        ).as("needToCompleteStrongAuthentication");

        fillForm();

        cy.waitSuccess('@needToCompleteStrongAuthentication');

        assertStrongAuthButton();
    });

    it("Assert valid form submit, when the VAT_ID_FOR_USERS and COMPANY_REQUEST_STRONG_AUTH feature flags are disabled", () => {
        pageData().common.interceptFeatureFlags([
            {flagName: RentaEasyConstants.featureFlagVatIdForUsers, enabled: false},
            {flagName: RentaEasyConstants.featureFlagCompanyRequestStrongAuth, enabled: false},
        ])
        cy.visit(Localizer.pageRoutesApplyCompanyAccount);


        assertConstructionSitesInputWorks("1, 2, 3, 4, 5");

        fillForm();

        selectProperDepot();

        assertSubmitSuccess();
    });

    it("Returns to the apply company access form, when the VAT_ID_FOR_USERS feature flag is enabled and the user resigns (cancel button is clicked or modal is closed by the x icon)", () => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagVatIdForUsers);
        cy.visit(Localizer.pageRoutesApplyCompanyAccount);


        fillForm();
        selectAllConstructionSites();

        selectProperDepot();

        triggerApplyCompanyAccountSubmitionAndWaitForResponse();

        assertModalVisibility(true);

        pageData().applyCompanyAccount.cancelButton()
            .click();

        assertModalVisibility(false);

        triggerApplyCompanyAccountSubmitionAndWaitForResponse();

        pageData().applyCompanyAccount.closeModalIcon()
            .click();

        assertModalVisibility(false);
    });

    it("Redirects the user to the front page and opens the user external validation, when the VAT_ID_FOR_USERS feature flag is enabled and the user confirms the user external validation", () => {
        CypressFeatureFlagService.enable(RentaEasyConstants.featureFlagVatIdForUsers);

        cy.visit(Localizer.pageRoutesApplyCompanyAccount);


        fillForm();
        selectAllConstructionSites();

        selectProperDepot();

        triggerApplyCompanyAccountSubmitionAndWaitForResponse();

        assertModalVisibility(true);

        pageData().applyCompanyAccount.confirmButton()
            .click();

        assertModalVisibility(false);

        cy.get(".athenaeum-alert-message")
            .should("contain", Localizer.applyCompanyAccountUserExternalVerificationConfirmationMessage);

        // verify frontpage redirection
        pageData().frontPage.widgets.rent().should("exist");
    });

    function assertInputError(input: string): void {
        cy.get("#form_submit").should('be.disabled')

        cy.get('form').submit();

        cy.get(input)
            .children()
            .first()
            .should("have.class", "athenaeum-form-validationError");

    }

    function assertSubmitSuccess(): void {
        triggerApplyCompanyAccountSubmitionAndWaitForResponse()

        cy.get(".athenaeum-alert-message")
            .should("contain", Localizer.applyCompanyAccountSent);
    }

    function assertStrongAuthButton(): void {
        cy.get("#form_submit").should('not.exist');
        cy.get("#form_strong_authenticate").should('exist');
    }

    function fillForm(): void {
        cy.get("#vat_id")
            .type("12345");

        cy.get("#company_name")
            .type("Test Company Ltd");

        cy.get("#manager_name")
            .type("Manager Person");

        cy.get("#manager_email")
            .type("test@weare.fi");
    }

    function assertConstructionSitesInputWorks(sites: string): void {
        cy.get("#construction_sites").type(sites);

        selectAllConstructionSites();

        cy.get("#input_construction_sites")
            .should("have.value", Localizer.genericAll);

        selectAllConstructionSites();

        cy.get("#input_construction_sites")
            .should("have.value", sites);
    }

    function assertModalVisibility(isVisible: boolean) {
        cy.get(".modal-dialog")
            .should(isVisible ? "be.visible" : "not.be.visible");
    }

    function triggerApplyCompanyAccountSubmitionAndWaitForResponse() {
        cy.intercept("POST", "api/Account/ApplyCompanyAccount").as("applyCompanyAccount");

        cy.get("#form_submit")
            .click();

        cy.waitSuccess("@applyCompanyAccount");
    }

    function selectProperDepot() {
        CypressDropdownHelper.openAndSelectDropdownItemByIndex("#select_depot", 1);
    }

    function selectAllConstructionSites() {
        cy.get("#all_construction_sites")
            .children()
            .eq(1)
            .click();
    }
});

export default {};