import React, {useEffect, useMemo, useState} from 'react';
import styles from './PowerPageContainer.module.scss';
import Localizer from "@/localization/Localizer";
import {ch} from '@renta-apps/athenaeum-react-common';
import {LoadingDecorator} from '@renta-apps/renta-react-components';
import SitePowerInfoModel from "@/models/server/SitePowerInfoModel";
import UserContext from '@/models/server/UserContext';
import EasyPlusInfoPanelPower from '@/components/EasyPlus/EasyPlusInfoPanelPower/EasyPlusInfoPanelPower';
import DailyConsumption from "@/pages/ConstructionSiteDetails/Power/Charts/DailyConsumption";
import MaxCurrent from "@/pages/ConstructionSiteDetails/Power/Charts/MaxCurrent";
import NoDevicesInfoPanel from '@/pages/ConstructionSiteDetails/Power/NoDevicesInfoPanel/NoDevicesInfoPanel';
import {getConstructionSitesPowerInfoByExternalId} from "@/services/ConstructionSiteService";

interface StatusPageContainerProps {
    constructionSiteId: string;
}

const PowerPageContainer: React.FC<StatusPageContainerProps> = ({constructionSiteId}) => {

    const [sitePowerInfo, setSitePowerInfo] = useState<SitePowerInfoModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: SitePowerInfoModel | null = await getConstructionSitesPowerInfoByExternalId(constructionSiteId);
                setSitePowerInfo(result);
            } catch (error) {
                console.error('Error fetching power consumption data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [constructionSiteId]);

    const easyPlusEnabled = useMemo(() => {
        return (ch.getContext() as UserContext)?.isEasyPlusUser ?? false;
    }, []);

    return easyPlusEnabled ? (
        <LoadingDecorator className={styles.loadingDecorator} hideConentOnLoading={true} isLoading={isLoading}>
        {
            sitePowerInfo === null ? (
                <NoDevicesInfoPanel />
            ) : (
                <div className={styles.chartsContainer}>
                    <div className={styles.chart} id={"power-consumption-chart-container"}>
                        <h2>{Localizer.constructionSiteDetailsPowerConsumptionTitle}</h2>
                        <DailyConsumption constructionSiteId={constructionSiteId}/>
                    </div>
                    <div className={styles.chart}>
                        {
                            //placeholder if we have another chart for the first row. This also forces max current charts to the next row
                        }
                    </div>
                    {

                        sitePowerInfo?.powerSupplies && (
                            sitePowerInfo?.powerSupplies
                                .filter(item => item.powerSupplyDeviceId)
                                .map((powerSupply, index) => (
                                    <div className={styles.chart} data-cy={"max-current-chart-container"}>
                                        {
                                            (index === 0 || index === 1) && (
                                            <div className={styles.chartTitleContainer}>
                                                {index === 0 && (
                                                    <h2>{Localizer.constructionSiteDetailsPowerMaxCurrentTitle}</h2>
                                                )}
                                            </div>
                                            )
                                        }
                                        <MaxCurrent maxPower={powerSupply.powerSupplyFuse}
                                                    key={powerSupply.powerSupplyId}
                                                    constructionSiteId={constructionSiteId}
                                                    powerSupplyId={powerSupply.powerSupplyId}
                                                    powerSupplyName={powerSupply.powerSupplyName}
                                        />
                                    </div>

                                ))
                        )

                    }
                </div>
            )
        }
        </LoadingDecorator>
    ) : (
        <EasyPlusInfoPanelPower />
    );
};

export default PowerPageContainer;