import Localizer from "@/localization/Localizer";
import DeviceGridHelper from "@/helpers/DeviceGridHelper";
import {pageData} from "@/helpers/CypressHelper";
import RentaEasyConstants from "@/helpers/RentaEasyConstants";

describe('Desktop - Fleet Monitoring - Tabs tests', () => {
    before(() => {
        cy.clearAllLocalStorage();
    });

    beforeEach(() => {
        cy.session(['company_role_session'], () => {
            cy.loginAndSelectCompanyRole("Urho.Kekkonen@weare.fi", "tmi jukka wilska");
        });
    });

    it('should display three tabs', () => {
        cy.intercept('POST', '/api/Fleet/GetDevicesPagedList', { fixture: 'fleetGetDevicesPagedListEmpty.json' });

        cy.visit('/fleet-monitoring');

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        pageData().fleetMonitoring.tabs.tab()
            .eq(0)
            .should('contain', Localizer.fleetMonitoringPageTabDevices);

        pageData().fleetMonitoring.tabs.tab()
            .eq(1)
            .should('contain', Localizer.fleetMonitoringPageTabReturnRequests);

        pageData().fleetMonitoring.tabs.tab()
            .eq(2)
            .should('contain', Localizer.fleetMonitoringPageTabAlertSources);

        pageData().fleetMonitoring.tabs.tab()
            .eq(1)
            .click();

        cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?selectedTab=palautuspyynn%C3%B6t`);

        pageData().fleetMonitoring.tabs.tab()
            .eq(2)
            .click();

        // To be defined when translations are ready.
        // cy.url().should('include', `${Localizer.pageRoutesFleetMonitoring}?selectedTab=alert-sources`);
    });

    it('should display only Fleet Monitoring tab', () => {

        pageData().common.interceptFeatureFlags([
            { flagName: RentaEasyConstants.featureFlagReturnRequestsList, enabled: false },
            { flagName: RentaEasyConstants.featureFlagAlertSourcesList, enabled: false },
        ]);

        cy.visit('/fleet-monitoring');

        cy.get('h1')
            .should('contain', Localizer.fleetMonitoringPageTitle);

        pageData().fleetMonitoring.tabs.tab()
            .should('not.exist');

        DeviceGridHelper.getDeviceGrid()
            .should('exist');
    });
});